import * as React from "react";
import {downloadPublishers, searchPublishers} from "./publishersService";
import Loading from "../../components/loading";
import {
    Alert, FormControl,
    FormControlLabel, FormGroup, IconButton, Input, InputAdornment, InputLabel, Menu, MenuItem,
    Paper, Select, SelectChangeEvent,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TableSortLabel, Tooltip
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../lib/UseDebounce";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import moment from "moment";
import theme from "../../lib/theme";
import PublisherToggleActive from "./publisherToggleActive";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Box from "@mui/material/Box";
import PublisherPixel from "./publisherPixel";
import PublisherUpsert from "./publisherUpsert";
import StripedTableRow from "../../components/StripedTableRow";
import TableHeaderRow from "../../components/TableHeaderRow";

export default function PublishersPage() {

    const [publishers, setPublishers] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [active, setActive] = React.useState(true);
    const [limit, setLimit] = React.useState(100);
    const [search, setSearch] = React.useState('');
    const [anchorEls, setAnchorEls] = React.useState<any>({});
    const [expanded, setExpanded] = React.useState<any>({});
    const [pubActive, setPubActive] = React.useState<any>(null as any);
    const [pubUpsert, setPubUpsert] = React.useState<any>(null as any);
    const [pubPixel, setPubPixel] = React.useState<any>(null as any);
    const [count, setCount] = React.useState(0);
    const [sort, setSort] = React.useState({column: 'id', sortAsc: false});

    React.useEffect(() => {
        document.title = "Publishers | DN";
    }, []);

    const reload = async (isActive: boolean, nLimit: number, sSearch: string, sort: any) => {
        setLoading(true);
        const p = await searchPublishers(isActive, nLimit, sSearch, sort.column, sort.sortAsc);
        setPublishers(p.publishers);
        setCount(p.count);
        setLoading(false);
    }

    React.useEffect(() => {
        reload(active, limit, search, sort).then(x=>{});
    }, []);

    const handleActive= async (event: React.ChangeEvent<HTMLInputElement>) => {
        setActive(event.target.checked);
        await reload(event.target.checked, limit, search, sort);
    };

    const handleLimit = async (event: SelectChangeEvent) => {
        const l = parseInt(event.target.value as string, 10);
        setLimit(l);
        await reload(active, l, search, sort);
    };

    useDebounce(() => {
        reload(active, limit, search, sort).then(x=>{});
        }, [search], 400
    );

    const doSort = async (column: string, defaultAsc: boolean = false) => {
        let s;
        if (sort.column === column) {
            s = {column: column, sortAsc: !sort.sortAsc};
        } else {
            s = {column: column, sortAsc: defaultAsc};
        }

        setSort(s);
        await reload(active, limit, search, s);
    }

    return (
        <>
            <h2>Publishers</h2>
            <FormGroup row={true}>
                <FormControlLabel control={<Switch checked={active} onChange={handleActive} />} label="Active" />
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="items-label">Items</InputLabel>
                    <Select
                        labelId="items-label"
                        value={limit.toString()}
                        label="Items"
                        onChange={handleLimit}
                        size={'small'}
                    >
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                        <MenuItem value={1000}>1000</MenuItem>
                        <MenuItem value={2000}>2000</MenuItem>
                        <MenuItem value={10000}>10000</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} size="small">
                    <Input
                        id="search"
                        type="text"
                        spellCheck="false"
                        placeholder="Search"
                        value={search || ''}
                        onChange={(e: any) => setSearch(e.target.value)}
                        startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                    />
                </FormControl>
                {!!publishers && !!publishers.length && !loading && <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="download" onClick={() => {downloadPublishers(active, search, sort.column, sort.sortAsc)}} variant={'contained'} color={'success'}>
                        <FileDownloadIcon />&nbsp;Export
                    </Button>
                </FormControl>}

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="Add" onClick={() => {setPubUpsert({})}} variant={'contained'} color={'warning'}>
                        <AddBoxOutlinedIcon />&nbsp;Create Publisher
                    </Button>
                </FormControl>
            </FormGroup>
            {loading ? <Loading/> :
                <>
                    {!publishers || publishers.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                            <Box sx={{margin: '5px 0', fontWeight: 'bold', fontSize: '13px'}}>
                                Showing 1 to {Math.min(publishers.length, count).toLocaleString(undefined, {maximumFractionDigits: 0})} of {count.toLocaleString(undefined, {maximumFractionDigits: 0})}.
                            </Box>
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'name'}
                                                direction={sort.column === 'name' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('name', true)}}
                                            >
                                                Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'id'}
                                                direction={sort.column === 'id' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('id', false)}}
                                            >
                                                Id
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'cpl'}
                                                direction={sort.column === 'cpl' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('cpl', false)}}
                                            >
                                                CPL
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'quality'}
                                                direction={sort.column === 'quality' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('quality', false)}}
                                            >
                                                Quality
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'qAll'}
                                                direction={sort.column === 'qAll' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('qAll', false)}}
                                            >
                                                qAll
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="center">
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'ignoreFraudLogix'}
                                                direction={sort.column === 'ignoreFraudLogix' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('ignoreFraudLogix', false)}}
                                            >
                                                FraudLogix
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="center">
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'hasPixel'}
                                                direction={sort.column === 'hasPixel' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('hasPixel', false)}}
                                            >
                                                Pixel
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="center">
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'marginLock'}
                                                direction={sort.column === 'marginLock' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('marginLock', false)}}
                                            >
                                                M%
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'minEpc'}
                                                direction={sort.column === 'minEpc' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('minEpc', false)}}
                                            >
                                                Min EPC
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'maxEpc'}
                                                direction={sort.column === 'maxEpc' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('maxEpc', false)}}
                                            >
                                                Max EPC
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'trafficType'}
                                                direction={sort.column === 'trafficType' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('trafficType', true)}}
                                            >
                                                Traffic
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'lastLeadDate'}
                                                direction={sort.column === 'lastLeadDate' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('lastLeadDate', false)}}
                                            >
                                                Last Lead
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {publishers.map((row: any) => (
                                        <React.Fragment key={row.id}>
                                        <StripedTableRow>
                                            <TableCell sx={{width: '30px'}}>
                                                <IconButton
                                                    onClick={(event) => {
                                                        setExpanded({...expanded, [row.id]: !expanded[row.id]})
                                                    }}
                                                >
                                                    {!!expanded[row.id] && <IndeterminateCheckBoxOutlinedIcon sx={{fontSize: '20px'}} />}
                                                    {!expanded[row.id] && <AddBoxOutlinedIcon sx={{fontSize: '20px'}} />}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <a href={`/publishers/${row.id}`} target={"_blank"}>{row.name}</a> {row.blockPEC && <span style={{textDecoration: 'line-through', color: 'red'}}>P</span>} {row.blockZovio && <span style={{textDecoration: 'line-through', color: 'red'}}>U</span>}
                                            </TableCell>
                                            <TableCell>
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="right">
                                                ${row.cpl.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.quality}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.qAll.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.ignoreFraudLogix && <HighlightOffIcon sx={{color: 'red', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/>}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.pixel && row.pixel.type === 'PIGGYBACK' && <Tooltip title="Has a Piggyback pixel set up"><AdsClickIcon sx={{color: 'green', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/></Tooltip>}
                                                {row.pixel && row.pixel.type === 'AFTER_EMAIL' && <Tooltip title="Has a SnapChat pixel set up"><AdsClickIcon sx={{color: 'orange', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/></Tooltip>}
                                            </TableCell>
                                            <TableCell align="center">
                                                {!!row.marginLock && (row.marginLock.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits: 0}) + '%')}
                                            </TableCell>
                                            <TableCell align="right">
                                                {!!row.minEpc && <>${row.minEpc.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</>}
                                            </TableCell>
                                            <TableCell align="right">
                                                {!!row.maxEpc && <>${row.maxEpc.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</>}
                                            </TableCell>
                                            <TableCell>
                                                {row.trafficType}
                                            </TableCell>
                                            <TableCell>
                                                {row.lastLeadDate && <>{moment(row.lastLeadDate).format('MM/DD/YYYY')}</>}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    aria-haspopup="true"
                                                    onClick={(event) => {
                                                        setAnchorEls({...anchorEls, [row.id]: event.currentTarget});
                                                    }}
                                                >
                                                    <MoreVertIcon sx={{fontSize: '15px'}} />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEls[row.id]}
                                                    open={!!anchorEls[row.id]}
                                                    onClose={() => {
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }}
                                                >
                                                    <MenuItem onClick={() => {
                                                        setPubUpsert(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><EditIcon sx={{marginRight: 1}} /> Edit Publisher</MenuItem>
                                                    <MenuItem onClick={() => {
                                                        setPubPixel(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><AdsClickIcon  sx={{marginRight: 1}}/> Setup Pixel</MenuItem>

                                                    {!row.active && <MenuItem onClick={() => {
                                                        setPubActive(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><DoneOutlineIcon  sx={{marginRight: 1}}/> Activate</MenuItem>}

                                                    {row.active && <MenuItem onClick={() => {
                                                        setPubActive(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><HighlightOffIcon  sx={{marginRight: 1}}/> De-Activate</MenuItem>}
                                                </Menu>
                                            </TableCell>
                                        </StripedTableRow>
                                        {!!expanded[row.id] && <TableRow key={`${row.id}-exp`}>
                                            <TableCell colSpan={14}>
                                                {row.fIds && row.fIds.length ? <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 40px)'}}>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={{backgroundColor: theme.palette.common.black, color: theme.palette.common.white}}>
                                                                    FID
                                                                </TableCell>
                                                                <TableCell sx={{backgroundColor: theme.palette.common.black, color: theme.palette.common.white}}>
                                                                    Date
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {row.fIds.map((fid: any) => (
                                                            <TableRow key={`${row.id}-${fid.fId}`}>
                                                                <TableCell>
                                                                    {fid.fId}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {moment(fid.date).format('MM/DD/YYYY')}
                                                                </TableCell>
                                                            </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer> : <Alert severity="warning">No FIDs found</Alert>}
                                            </TableCell>
                                        </TableRow>}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </>
                    }
                </>
            }
            <PublisherToggleActive pub={pubActive} setPub={setPubActive} reload={() => {reload(active, limit, search, sort).then(() => {})}} />
            <PublisherPixel pub={pubPixel} setPub={setPubPixel} reload={() => {reload(active, limit, search, sort).then(() => {})}} />
            <PublisherUpsert pub={pubUpsert} setPub={setPubUpsert} reload={() => {reload(active, limit, search, sort).then(() => {})}} />
        </>
    )
}
