import * as React from 'react';
import DateRange, {RangeToTimes} from "../DateRange";
import Publishers from "../../pages/dashboard/publishers";
import Box from "@mui/material/Box";
import MultiSelect from "../multiSelect/multiSelect";
import {searchPubs} from "../../pages/blockedPublishers/blockedPublishersService";
import CachedIcon from "@mui/icons-material/Cached";

export default function PubRevenue(params: {pubId: number, revenue: any, setRevenue: any}) {
    const [reload, setReload] = React.useState(0);
    React.useEffect(() => {
        if (!params.revenue.dateRange.length) {
            updateDateRange(RangeToTimes("Today"));
        } else {
            setReload(Math.random());
        }
    }, []);

    const updateDateRange = async (value: any) => {
        params.setRevenue({...params.revenue, dateRange: value});
        setReload(Math.random());
    }

    const setSelectedPublishers = (items: any[]) => {
        params.setRevenue({...params.revenue, selectedPublishers: items});
    }

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', margin: 0, padding: 1, flexFlow: 'wrap'}}>
            <DateRange showTime={false} dateRange={params.revenue.dateRange} setDateRange={updateDateRange} style={{marginBottom: '15px'}}/>
                <Box sx={{width: "350px", marginLeft: 'auto', display: 'flex'}}>
                    <MultiSelect
                        searchFunction={searchPubs}
                        selectedItems={params.revenue.selectedPublishers}
                        setSelectedItems={setSelectedPublishers}
                        placeholder={"Select Publishers"}

                    />
                    <CachedIcon sx={{cursor: 'pointer', margin: '5px 0 0 5px'}} onClick={() => updateDateRange(params.revenue.dateRange)}  color={"success"}/>

                </Box>
            </Box>
            <Publishers dateRange={params.revenue.dateRange} selectedPublishers={params.revenue.selectedPublishers} reload={reload}/>
        </>

    )

}
