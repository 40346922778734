import {
    Card,
    CardContent,
    CardHeader, FormControl,
    Grid,
    IconButton, Input, InputAdornment,
    Paper, Table,
    TableBody,
    TableCell,
    TableContainer, TableFooter,
    TableHead, TableSortLabel, ToggleButton, ToggleButtonGroup, Tooltip
} from "@mui/material";
import theme2 from "../../../lib/theme";
import * as React from "react";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Loading from "../../../components/loading";
import TableHeaderRow from "../../../components/TableHeaderRow";
import StripedTableRow from "../../../components/StripedTableRow";
import HttpsIcon from "@mui/icons-material/Https";
import TableFooterRow from "../../../components/TableFooterRow";
import moment from "moment";

const defaultSummary = {leads_generated: 0, leads_sold: 0, cost: 0, cake_clicks: 0, cake_conversions: 0, epc: 0, revenue: 0, profit: 0, margin: 0, cost_per_lead: 0, revenue_per_lead: 0};
export default function Publishers(params: {dateRange: any, reload: any, verticals: string[]}) {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([] as any[]);
    const [search, setSearch] = React.useState('');
    const [filtered, setFiltered] = React.useState([] as any[]);
    const [summary, setSummary] = React.useState({...defaultSummary} as any);
    const [sort, setSort] = React.useState({column: 'leads_generated', sortAsc: false});

    const reload = async () => {
        setLoading(true);

        // do a post to https://membertool.banneredgemedia.com/integration/publishers

        const s = moment.tz(moment(params.dateRange[0]).format("YYYY-MM-DD"), "America/Los_Angeles").format();
        let e = moment(params.dateRange[1]).format();
        let dash = e.lastIndexOf("-");
        e = e.substring(0, dash);
        e = moment.tz(e, "America/Los_Angeles").format();

        const response = await fetch('https://membertool.banneredgemedia.com/integration/publishers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                startDate: s,
                endDate: e,
                verticals: params.verticals
            })
        });

        const json = await response.json();
        setData(json);
        calcFiltered(json, search, sort);

        setLoading(false);
    }


    React.useEffect(() => {
        if (params.reload) {
            reload().then(() => {});
        }
    }, [params.reload]);

    const calcFiltered = (_data: any[], _search: string, _sort: any) => {
        let dat = _data.filter((d: any) => !_search.trim() || d.publisher_name.toLowerCase().includes(_search.trim().toLowerCase()) || d.publisher_id.toString() === _search.trim());

        dat = dat.sort((a: any, b: any) => {
            if (_sort.sortAsc === false) {
                if (a[_sort.column] < b[_sort.column]) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a[_sort.column] > b[_sort.column]) {
                    return 1;
                } else {
                    return -1;
                }
            }
        });

        setFiltered(dat);

        let sum = {...defaultSummary};
        dat.forEach((d: any) => {
            sum.leads_generated += d.leads_generated;
            sum.leads_sold += d.leads_sold;
            sum.cost += d.cost;
            sum.cake_clicks += d.cake_clicks;
            sum.cake_conversions += d.cake_conversions;
            sum.epc += (d.epc * d.cake_clicks);
            sum.revenue += d.revenue;
            sum.profit += d.profit;
        });
        sum.epc = sum.cake_clicks ? sum.epc / sum.cake_clicks : 0;
        sum.margin = sum.revenue ? sum.profit / sum.revenue * 100 : (sum.profit ? -100 : 0);
        sum.cost_per_lead = sum.leads_generated ? sum.cost / sum.leads_generated : 0;
        sum.revenue_per_lead = sum.leads_sold ? sum.revenue / sum.leads_sold : 0;
        setSummary(sum);
    }

    const doSort = (column: string, defaultAsc: boolean = false) => {
        let s;
        if (sort.column === column) {
            s = {column: column, sortAsc: !sort.sortAsc};
        } else {
            s = {column: column, sortAsc: defaultAsc};
        }
        setSort(s);

        calcFiltered(data, search, s);
    }

    return (
        <Card sx={{height: '463px', maxWidth: 'calc(100vw - 10px)'}}>
            <CardHeader title={"Publishers"} sx={{backgroundColor: theme2.palette.primary.main, color: theme2.palette.primary.contrastText, padding: '5px'}}
                        titleTypographyProps={{sx:{fontSize: '18px'}}}
                        action={
                <>
                    <IconButton aria-label="refresh" sx={{color: theme2.palette.primary.contrastText}} onClick={reload}>
                        <CachedIcon />
                    </IconButton>
                </>
                        }
            />
            <CardContent>
                {loading ? <Box sx={{marginTop: '130px'}}><Loading/></Box> : <>
                    <FormControl variant="outlined" sx={{ m: 1, maxWidth: 190 }} size="small">
                        <Input
                            id="search"
                            type="text"
                            spellCheck="false"
                            placeholder="Search"
                            value={search || ''}
                            onChange={(e: any) => {setSearch(e.target.value); calcFiltered(data, e.target.value, sort)}}
                            startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                        />
                    </FormControl>

                    <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 40px)', overflowY: 'auto', maxHeight: '333px'}}>
                        <Table size="small"
                               sx={{
                                   "& .MuiTableRow-root:hover": {
                                       backgroundColor: "primary.light"
                                   }
                               }}
                               stickyHeader={true}
                        >
                            <TableHead>
                                <TableHeaderRow>
                                    <TableCell>
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'publisherName'}
                                            direction={sort.column === 'publisherName' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('publisherName', true)}}
                                        >
                                            Publisher
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'leads_generated'}
                                            direction={sort.column === 'leads_generated' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('leads_generated', false)}}
                                        >
                                            Generated
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'leads_sold'}
                                            direction={sort.column === 'leads_sold' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('leads_sold', false)}}
                                        >
                                            Sold
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'cost'}
                                            direction={sort.column === 'cost' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('cost', false)}}
                                        >
                                            Cost
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'revenue'}
                                            direction={sort.column === 'revenue' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('revenue', false)}}
                                        >
                                            Rev
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'profit'}
                                            direction={sort.column === 'profit' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('profit', false)}}
                                        >
                                            Profit
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'margin'}
                                            direction={sort.column === 'margin' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('margin', false)}}
                                        >
                                            Margin
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'cake_clicks'}
                                            direction={sort.column === 'cake_clicks' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('cake_clicks', false)}}
                                        >
                                            Clicks
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'cake_conversions'}
                                            direction={sort.column === 'cake_conversions' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('cake_conversions', false)}}
                                        >
                                            Cake
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'epc'}
                                            direction={sort.column === 'epc' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('epc', false)}}
                                        >
                                            EPC
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'cost_per_lead'}
                                            direction={sort.column === 'cost_per_lead' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('cost_per_lead', false)}}
                                        >
                                            CPL
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'revenue_per_lead'}
                                            direction={sort.column === 'revenue_per_lead' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('revenue_per_lead', false)}}
                                        >
                                            RPL
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'min_epc'}
                                            direction={sort.column === 'min_epc' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('min_epc', false)}}
                                        >
                                            Min
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'max_epc'}
                                            direction={sort.column === 'max_epc' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('max_epc', false)}}
                                        >
                                            Max
                                        </TableSortLabel>
                                    </TableCell>
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody>
                                {filtered.map((row: any) => (
                                    <StripedTableRow key={row.publisher_id}>
                                        <TableCell sx={{minWidth: '200px'}}>{row.publisher_name}</TableCell>
                                        <TableCell align="right">{row.leads_generated.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.leads_sold.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">${row.cost.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right">${row.revenue.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right" sx={{backgroundColor: getProfitColor(row.profit)}}>${row.profit.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right">{row.margin.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%
                                            {!!row.margin_lock && <Tooltip title={"Margin Lock: " + row.margin_lock + "%"}><HttpsIcon sx={{color: 'red', fontSize: '18px', marginLeft: '5px', verticalAlign: 'text-top'}}/></Tooltip>}
                                        </TableCell>
                                        <TableCell align="right">{row.cake_clicks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.cake_conversions.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right" sx={{backgroundColor: row.epc > 0 && row.epc <= 0.75 ? theme2.palette.warning.light : undefined}}>${row.epc.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right">${row.cost_per_lead.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right">${row.revenue_per_lead.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right">${(row.min_epc || 0).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right" sx={{backgroundColor: !row.max_epc ? theme2.palette.error.light : undefined}}>${(row.max_epc || 0).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>

                                    </StripedTableRow>
                                ))}
                                </TableBody>
                            <TableFooter>
                                <TableFooterRow>
                                    <TableCell>Totals</TableCell>
                                    <TableCell align="right">{summary.leads_generated.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right">{summary.leads_sold.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right">${summary.cost.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">${summary.revenue.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">${summary.profit.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">{summary.margin.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</TableCell>
                                    <TableCell align="right">{summary.cake_clicks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right">{summary.cake_conversions.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right">${summary.epc.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">${summary.cost_per_lead.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">${summary.revenue_per_lead.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableFooterRow>
                            </TableFooter>
                        </Table>

                    </TableContainer>
                </>}
            </CardContent>
        </Card>
    );
}

function getProfitColor(profit: number) {
    if (profit < -0) {
        return theme2.palette.error.light
    }
    return undefined;
}
