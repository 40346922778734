import * as React from "react";
import Loading from "../components/loading";
import moment from "moment-timezone";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody, TableCell,
    TableContainer, TableFooter,
    TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import {gql} from "@apollo/client";
import {query} from "../lib/apollo";
import theme2 from "../lib/theme";
import TableFooterRow from "../components/TableFooterRow";

const months= [{id: 1, name: "January"},{id: 2, name: "February"},{id: 3, name: "March"},{id: 4, name: "April"},{id: 5, name: "May"},{id: 6, name: "June"},{id: 7, name: "July"},{id: 8, name: "August"},{id: 9, name: "September"},{id: 10, name: "October"},{id: 11, name: "November"},{id: 12, name: "December"}];
const years: number[] = [];
for (let i = parseInt(moment().format("YYYY")); i >= 2015; i--) {
    years.push(i);
}

export default function RevenueCalendarPage() {
    const [loading, setLoading] = React.useState(false);
    const [month, setMonth] = React.useState(parseInt(moment().format("M")));
    const [year, setYear] = React.useState(parseInt(moment().format("YYYY")));
    const [grid, setGrid] = React.useState([] as any[]);
    const [totals, setTotals] = React.useState({rev: 0, cost: 0, profit: 0, margin: 0, q100: 0, uagc: 0, pec: 0, post: 0, label: ''});

    React.useEffect(() => {
        document.title = "Revenue Calendar | DN";

        reload(month, year).then(() => {});
    }, []);

    const reload = async (m: number, y: number) => {
        setMonth(m);
        setYear(y);
        setLoading(true);

        const strDate = y + "-" + ("0" + m).slice(-2) + "-01";

        const startDate = moment.tz(strDate, "America/Los_Angeles");
        let dow = parseInt(startDate.format("E"));
        startDate.subtract(dow-1, 'day');

        const endDate = moment.tz(strDate, "America/Los_Angeles").endOf("month");
        dow = parseInt(endDate.format("E"));
        endDate.add(7 - dow, 'day');

        const q = gql`
            query q($input: CostTimeSeriesInput!, $input2: SalesTimeSeriesInput!, $input3: SalesTimeSeriesInput!) {
                costTimeSeries: costTimeSeries(input: $input) {
                    value
                    timestamp
                    q100
                }

                salesTimeSeries: salesTimeSeries(input: $input2) {
                    value
                    timestamp
                }

                salesTimeSeries2: salesTimeSeries(input: $input3) {
                    value
                    timestamp
                    brokerId
                }
            }
        `;

        const variables = {
            input: {
                startDate: startDate.format("YYYY-MM-DD HH:mm:ss"),
                endDate: endDate.format("YYYY-MM-DD HH:mm:ss"),
            },
            input2: {
                startDate: startDate.format("YYYY-MM-DD HH:mm:ss"),
                endDate: endDate.format("YYYY-MM-DD HH:mm:ss"),
            },
            input3: {
                startDate: startDate.format("YYYY-MM-DD HH:mm:ss"),
                endDate: endDate.format("YYYY-MM-DD HH:mm:ss"),
                brokerIds: [172, 199, 283]
            },
        }

        const response = await query(q, variables);

        let g = [] as any[];
        let week = [] as any[];
        let d1 = startDate.clone();
        let d: any;
        let t = {rev: 0, cost: 0, profit: 0, margin: 0, q100: 0, uagc: 0, pec: 0, post: 0, label: moment(strDate).format("MMMM YYYY")};
        while (d1 < endDate) {
            d = {
                month: d1.format("M"),
                day: d1.format("D"),
                year: d1.format("YYYY"),
                dow: d1.format("dddd"),
                isGood: d1 < moment(),
                rev: 0,
                q100: 0,
                cost: 0,
                profit: 0,
                margin: 0,
                pec: 0,
                uagc: 0,
                post: 0
            };

            const rev = response.salesTimeSeries.find((x: any) => x.timestamp === d1.toDate().getTime());
            if (rev) {
                d.rev = rev.value;
            }

            const cost = response.costTimeSeries.find((x: any) => x.timestamp === d1.toDate().getTime());
            if (cost) {
                d.cost = cost.value;
                d.profit = d.rev - d.cost;
                d.margin = d.rev ? (d.profit / d.rev) * 100 : 0;
                d.q100 = cost.q100;
            }

            const uagc = response.salesTimeSeries2.find((x: any) => x.timestamp === d1.toDate().getTime() && x.brokerId === 172);
            if (uagc) {
                d.uagc = uagc.value;
            }

            const pec = response.salesTimeSeries2.find((x: any) => x.timestamp === d1.toDate().getTime() && x.brokerId === 199);
            if (pec) {
                d.pec = pec.value;
            }

            const post = response.salesTimeSeries2.find((x: any) => x.timestamp === d1.toDate().getTime() && x.brokerId === 283);
            if (post) {
                d.post = post.value;
            }

            if (parseInt(d1.format("M")) === m && parseInt(d1.format("YYYY")) === y) {
                t.rev += d.rev;
                t.cost += d.cost;
                t.q100 += d.q100;
                t.uagc += d.uagc;
                t.pec += d.pec;
                t.post += d.post;
            }
            week.push(d);
            d1.add(1, "day");

            if (d.dow === 'Sunday' || d1 >= endDate) {
                d = {day: -1, rev: 0, cost: 0, isGood: false, q100: 0, profit: 0, margin: 0, pec: 0, uagc: 0, post: 0};
                week.forEach((y) => {
                    d.rev += y.rev;
                    d.q100 += y.q100;
                    d.cost += y.cost;
                    d.uagc += y.uagc;
                    d.post += y.post;
                    d.pec += y.pec;
                });

                if (d.rev || d.cost) {
                    d.isGood = true;
                    d.profit = d.rev - d.cost;
                    d.margin = d.rev ? (d.profit / d.rev) * 100 : 0;
                }

                week.push(d);
                g.push(week);
                week = [];
            }
        }

        t.profit = t.rev - t.cost;
        t.margin = t.rev ? (t.profit / t.rev) * 100 : 0;

        setTotals(t);
        setGrid(g);
        setLoading(false);
    };

    const rows: any[] = [];
    months.forEach((m) => {
        rows.push(<MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>);
    });

    const rows2: any[] = [];
    years.forEach((y) => {
        rows2.push(<MenuItem key={y} value={y}>{y}</MenuItem>);
    });

    return (
        <>
            <h2>Revenue Calendar</h2>

            {loading ? <Loading/> :
                <>
                    <Grid container spacing={2} sx={{padding: '10px'}}>
                        <Grid item xs={6}>
                            <FormControl fullWidth size={"small"}>
                                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={month}
                                    label="Month"
                                    onChange={(event) => {reload(event.target.value as number, year).then(() => {})}}
                                >{rows}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size={"small"}>
                                <InputLabel id="demo-simple-select-label2">Year</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    value={year}
                                    label="Year"
                                    onChange={(event) => {reload(month, event.target.value as number).then(() => {})}}
                                >{rows2}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)'}}>
                        <Table size="small">
                            <TableBody>
                                    {grid.map((row) => (
                                        <TableRow key={'w' + row[0].day + '' + + row[0].month}>
                                            {row.map((cell: any) => (
                                                <TableCell key={cell.day} sx={{whiteSpace: 'nowrap', verticalAlign: 'top'}}>
                                                    <Box sx={{fontWeight: 'bold'}}>
                                                    {cell.day > 0 ? `${cell.dow}: ${cell.month}/${cell.day}/${cell.year}` : ""}
                                                    {cell.day === -1 ? "Week Summary" : ""}
                                                    </Box>

                                                    {cell.isGood ? <Block cell={cell}/> : <> </>}

                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                <TableRow>
                                    <TableCell sx={{whiteSpace: 'nowrap'}}>
                                        <Box sx={{fontWeight: 'bold'}}>{totals.label} Totals</Box>
                                        <Block cell={totals}/>
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        </>
    )
}

function Block(props: { cell : any}) {
    return <Box>
        <Box sx={{color: theme2.palette.info.light}}>Revenue: ${props.cell.rev.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Box>
        <Box sx={{color: theme2.palette.common.black}}>Cost: ${props.cell.cost.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Box>
        <Box sx={{color: theme2.palette.success.light}}>Profit: ${props.cell.profit.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Box>
        <Box sx={{color: theme2.palette.warning.light}}>Margin: {props.cell.margin.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</Box>
        <Box sx={{color: '#777'}}>100s: {(props.cell.q100 || 0).toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Box>
        <Box sx={{color: "#738ABA"}}>UAGC: ${props.cell.uagc.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Box>
        <Box sx={{color: "#CB6EF9"}}>PEC: ${props.cell.pec.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Box>
        <Box sx={{color: "#69DEC9"}}>POST: ${props.cell.post.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Box>
    </Box>
}
