import {Card, CardContent, CardHeader, Grid, IconButton, ToggleButton, ToggleButtonGroup} from "@mui/material";
import theme2 from "../../lib/theme";
import * as React from "react";
import {gql} from "@apollo/client";
import moment from "moment/moment";
import {query} from "../../lib/apollo";
import Loading from "../../components/loading";
import Box from "@mui/material/Box";
import CachedIcon from "@mui/icons-material/Cached";
import Timeseries from "../../components/timeseries";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";


export default function RevenueTimeseries(params: {dateRange: any, selectedPublishers: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [series, setSeries] = React.useState([] as any[]);
    const [totals, setTotals] = React.useState({revenue: 0, cost: 0, profit: 0, margin: 0});
    const [yAxisRight, setYAxisRight] = React.useState(null as any);
    const [filterLock, setFilterLock] = React.useState('center');
    const [firstLoad, setFirstLoad] = React.useState(true);

    const reload = async () => {
        setLoading(true);

        const q = gql`
            query q($input: CostTimeSeriesInput!) {
                costTimeSeries: costTimeSeries(input: $input) {
                    value
                    timestamp
                }
            }
        `;

        const q2 = gql`
            query q($input2: SalesTimeSeriesInput!) {
                salesTimeSeries: salesTimeSeries(input: $input2) {
                    value
                    timestamp
                }
            }
        `;

        const q3 = gql`
            query q($input3: SalesTimeSeriesInput!) {
                salesTimeSeries2: salesTimeSeries(input: $input3) {
                    value
                    timestamp
                    brokerId
                }
            }
        `;

        const variables = {
            input: {
                startDate: moment(params.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(params.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
                publisherIds: params.selectedPublishers.map((p: any) => p.id),
                isAffiliate: filterLock === 'left' ? 'YES' : (filterLock === 'right' ? 'NO' : 'ALL')
            }
        }

        const variables2 = {
            input2: {
                startDate: moment(params.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(params.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
                publisherIds: params.selectedPublishers.map((p: any) => p.id),
                isAffiliate: filterLock === 'left' ? 'YES' : (filterLock === 'right' ? 'NO' : 'ALL')
            }
        }

        const variables3 = {
            input3: {
                startDate: moment(params.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(params.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
                publisherIds: params.selectedPublishers.map((p: any) => p.id),
                brokerIds: [172, 329, 340, 90, 283],
                isAffiliate: filterLock === 'left' ? 'YES' : (filterLock === 'right' ? 'NO' : 'ALL')
            }
        }

        const response = await query(q, variables);
        const response2 = await query(q2, variables2);
        const response3 = await query(q3, variables3);
        const series = [] as any[];
        let cost: any = {};
        let sales: any = {};
        let profit: any = {};
        let margin: any = {};
        let national: any = {};
        let studio: any = {};
        let u2: any = {};
        let uagc: any = {};
        let post: any = {};

        if (response2 && response2.salesTimeSeries) {
            const s = response2.salesTimeSeries;

            sales = {name: "Revenue", data: [] as any, color: theme2.palette.info.light};

            totals.revenue = 0;
            s.forEach((item: any) => {
                sales.data.push([item.timestamp, item.value]);
                totals.revenue += item.value;
            });
            series.push(sales);

        }

        if (response && response.costTimeSeries) {
            const s = response.costTimeSeries;

            cost = {name: "Cost", data: [] as any, color: theme2.palette.common.black};

            totals.cost = 0;
            s.forEach((item: any) => {
                cost.data.push([item.timestamp, item.value]);
                totals.cost += item.value;
            });

            series.push(cost);

        }

        if (cost.data && cost.data.length) {
            profit = {name: "Profit", data: [] as any, color: theme2.palette.success.light};
            margin = {name: "Margin", data: [] as any, color: theme2.palette.error.light, yAxis: 1};

            let min = 0;
            let max = 0;

            cost.data.forEach((item: any) => {
                let s = 0;
                const revenue = sales.data.find((s: any) => s[0] === item[0]);
                if (revenue) {
                    s = revenue[1];
                }

                profit.data.push([item[0], s - item[1]]);

                let m = 0;
                if (s === 0 && item[1] === 0) {
                    m = 0;
                } else if (s === 0) {
                    m = -100;
                } else if (item[1] === 0) {
                    m = 100;
                } else {
                    m = ((s - item[1]) / s) * 100;
                }

                if (m < -100) {
                    m = -100;
                }

                if (m < min) {
                    min = m;
                }

                if (m > max) {
                    max = m;
                }

                margin.data.push([item[0], m]);
            });

            setYAxisRight({
                title: {
                    text: 'Margin'
                },
                min: min,
                max: max,
                opposite: true
            });

            series.push(profit);
            series.push(margin);
        }

        if (response3 && response3.salesTimeSeries2) {
            const s = response3.salesTimeSeries2;
            uagc = {name: "UAGC", data: [] as any, color: "#738ABA"};
            national = {name: "National", data: [] as any, color: "#CB6EF9"};
            studio = {name: "Studio", data: [] as any, color: "#69DEC9"};
            u2 = {name: "2U", data: [] as any, color: "#F9C469"};
            post = {name: "Post", data: [] as any, color: "#ff9a00"};

            s.forEach((item: any) => {
                if (item.brokerId === 340) {
                    national.data.push([item.timestamp, item.value]);
                } else if (item.brokerId === 329) {
                    studio.data.push([item.timestamp, item.value]);
                } else if (item.brokerId === 90) {
                    u2.data.push([item.timestamp, item.value]);
                } else if (item.brokerId === 172) {
                    uagc.data.push([item.timestamp, item.value]);
                } else if (item.brokerId === 283) {
                    post.data.push([item.timestamp, item.value]);
                }
            });

            series.push(uagc);
            series.push(national);
            series.push(studio);
            series.push(u2);
            series.push(post);
        }

        totals.profit = totals.revenue - totals.cost;
        if (totals.revenue === 0 && totals.cost === 0) {
            totals.margin = 0;
        } else if (totals.revenue === 0) {
            totals.margin = -100;
        } else if (totals.cost === 0) {
            totals.margin = 100;
        } else {
            totals.margin = (totals.profit / totals.revenue) * 100;
        }

        if (totals.margin < -100) {
            totals.margin = -100;
        }

        setSeries(series);
        setLoading(false);
    }

    React.useEffect(() => {
        if (params.reload) {
            reload().then(() => {});
        }
    }, [params.reload]);

    React.useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            return;
        }
        reload().then(() => {});
    }, [filterLock]);

    let dateFormat = "";

    if (params.dateRange && params.dateRange.length > 0) {
        const diffInDays = Math.floor((params.dateRange[1].getTime() - params.dateRange[0].getTime()) / (1000 * 3600 * 24));

        if (diffInDays < 3) {
            dateFormat = "ha";
        } else if (diffInDays < 60) {
            dateFormat = "MMM D";
        } else {
            dateFormat = "MMM 'YY";
        }
    }

    return (
        <Card sx={{height: '365px', maxWidth: 'calc(100vw - 10px)'}}>
            <CardHeader title={"Revenue"} sx={{backgroundColor: theme2.palette.primary.main, color: theme2.palette.primary.contrastText, padding: '5px'}}
                        titleTypographyProps={{sx:{fontSize: '18px'}}}
                        action={
                            <IconButton aria-label="refresh" sx={{color: theme2.palette.primary.contrastText}} onClick={reload}>
                                <CachedIcon />
                            </IconButton>
                        }
            />
            <CardContent>
                {loading ? <Box sx={{marginTop: '130px'}}><Loading/></Box> : <>
                    <Grid container spacing={0} sx={{fontSize: '14px'}}>
                        <Grid item xs={6} sm={3} md={2} color={theme2.palette.info.light}>
                            Revenue: ${totals.revenue.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} color={theme2.palette.common.black}>
                            Cost: ${totals.cost.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} color={theme2.palette.success.light}>
                            Profit: ${totals.profit.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} color={theme2.palette.warning.light}>
                            Margin: {totals.margin.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}%
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <ToggleButtonGroup
                                size={"small"}
                                value={filterLock}
                                exclusive
                                onChange={(e: any, value) => {setFilterLock(value)}}
                                aria-label="text alignment"
                            >
                                <ToggleButton value="left" aria-label="left aligned" sx={{color: 'red'}}>
                                    Aff
                                </ToggleButton>
                                <ToggleButton value="center" aria-label="centered">
                                    All
                                </ToggleButton>
                                <ToggleButton value="right" aria-label="right aligned"  sx={{color: 'green'}}>
                                    Goo
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <Timeseries height={260} series={series} title={""} prefix={"$"} suffix={""} dateFormat={dateFormat} decimalPlaces={0} yAxisRight={yAxisRight}/>

                </>}
            </CardContent>
        </Card>
    );
}
