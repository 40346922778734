import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Grid,
    InputLabel, MenuItem, Select,
} from "@mui/material";
import { getBrokerOptions, getRuleActionOptions } from "./rulesService";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import TextField from "@mui/material/TextField";
import SingleSelect from "../../components/SingleSelect";

export default function RulesUpsert (params: {rule: any, setRule: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [matchString, setMatchString] = React.useState('' as string);
    const [selectedBroker, setSelectedBroker] = React.useState({id: '', value: ''} as any);
    const [ruleActionOptions, setRuleActionOptions] = React.useState([] as any);
    const [selectedRuleAction, setSelectedRuleAction] = React.useState('' as string);

    React.useEffect(() => {
        getRuleActionOptions().then((res:any) => {
            setRuleActionOptions(res);
            setLoading(false);
        });
    }, []);

    React.useEffect(() => {
        if (params.rule) {
            if (params.rule.id) {
                // setSelectedBroker(params.rule.name || '');
                setMatchString(params.rule.matchString || '');
                setSelectedBroker({
                    "id": params.rule.brokerId,
                    "value": params.rule.brokerName + " (" + params.rule.brokerId + ")"
                });
                if (params.rule.action) {
                    setSelectedRuleAction(params.rule.action.id || '')
                } else {
                    setSelectedRuleAction('')
                }

            } else {
                setMatchString('');
                setSelectedBroker(undefined);
                setSelectedRuleAction('')
            }
        }
    }, [params.rule]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setRule(null);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $input: RuleUpdateInput!) {
                    ruleUpdate(id: $id, input: $input)
                }
            `;

            variables = {
                id: params.rule.id,
                input: {
                    brokerId: selectedBroker.id,
                    actionId: selectedRuleAction,
                    matchString
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.ruleUpdate;
        } else {
            if (selectedRuleAction != "") {
                q = gql`
                    mutation m($input: RuleCreateInput!) {
                        ruleCreate(input: $input) {
                            id
                        }
                    }
                `;

                variables = {
                    input: {
                        brokerId: selectedBroker.id,
                        actionId: selectedRuleAction,
                        matchString
                    }
                };

                setLoading(true);

                const res = await mutate(q, variables);
                good = res && res.ruleCreate && res.ruleCreate.id;
            } else {
                toast.error("Please select a valid rule action");
                good = false;
            }
        }

        if (good) {
            toast.success(isEdit ? 'Rule updated successfully' : 'Rule created successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }

    }

    if (!params.rule) return <> </>;

    const isEdit = !!params.rule.id;

    return (
        <>
                <Dialog
                    open={!!params.rule}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'sm'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {isEdit ? 'Update Rule' : 'Create Rule'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="matchString"
                                    label="matchString"
                                    type="text"
                                    fullWidth
                                    size={'small'}
                                    value={matchString}
                                    onChange={(e) => setMatchString(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SingleSelect searchFunction={getBrokerOptions} selectedItem={selectedBroker} setSelectedItem={setSelectedBroker} placeholder={"Select Broker"}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size={'small'}>
                                    <InputLabel id="iRuleAction">Rule Action</InputLabel>
                                    <Select
                                        labelId="ruleAction"
                                        id="ruleAction"
                                        value={selectedRuleAction}
                                        onChange={(e) => setSelectedRuleAction(e.target.value)}
                                        label="Rule Action"
                                    >
                                        {ruleActionOptions.options.map((ra: any) => <MenuItem key={ra.id} value={ra.id}>{ra.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
