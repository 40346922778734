import * as React from "react";
import {
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment, InputLabel, MenuItem, Select,
    Switch
} from "@mui/material";
import TextField from "@mui/material/TextField";
import LoadingDialog from "../../components/loadingDialog";
import Button from "@mui/material/Button";
import {DatePicker} from "rsuite";
import moment from "moment";
import SingleSelect from "../../components/SingleSelect";
import {searchPubs} from "../blockedPublishers/blockedPublishersService";
import {gql} from "@apollo/client";
import {mutate} from "../../lib/apollo";
import {toast} from "react-toastify";

export default function PublisherCreditCreate (params: {open: any, setOpen: any, reload: any}) {
    const [loading, setLoading] = React.useState(false);
    const [amount, setAmount] = React.useState(0);
    const [date, setDate] = React.useState(moment().add(-1, "day").toDate());
    const [publisher, setPublisher] = React.useState(null as any | null);

    React.useEffect(() => {
        setDate(moment().add(-1, "day").toDate());
        setPublisher(null);
        setAmount(0);
    }, []);
    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setOpen(false);
    };

    const handleSubmit = async (event: any) => {
        setLoading(true);
        const q = gql`
            mutation m($input: PurchaseCreditCreate!) {
                purchaseCreditCreate(input: $input) 
            }
        `;

        const variables = {
            input: {
                publisher_id: publisher.id,
                amount: amount,
                date: moment(date).format("YYYY-MM-DD")
            }
        };

        const response = await mutate(q, variables);
        if (response && response.purchaseCreditCreate) {
            toast.success("Credit has been added successfully");
            handleClose();
            params.reload();
        }
        setLoading(false);
    }

    if (!params.open) return <> </>;

    let j= 0;
    return (
        <>
            <Dialog
                open={params.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    Add Publisher Credit
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SingleSelect searchFunction={searchPubs} selectedItem={publisher} setSelectedItem={setPublisher} placeholder={"Select Publisher"}/>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                format={'MM-dd-yyyy'}
                                value={date}
                                oneTap
                                cleanable={false}
                                style={{marginTop: '11px', width: '100%'}}
                                onChange={(e) => setDate(e || moment().add(-1, "day").toDate())}
                                onFocus={(e: any) => {j++; if (j === 1) { e.target.blur(); }}}
                                onExit={(e: any) => {j = 0;}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="amount"
                                label="Amount"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={amount}
                                onChange={(e) => setAmount(parseFloat(e.target.value || "0"))}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                inputProps={{
                                    step: ".01"
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus disabled={!publisher || !amount}>Add</Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}
