import * as React from "react";
import {useParams} from "react-router";
import PubCard from "../components/pubCard/pubCard";
export default function PublisherPage() {
    const [pubCard, setPubCard] = React.useState(0);
    let { id } = useParams();

    React.useEffect(() => {
        if (id) {
            document.title = `Publisher ${id} | DN`;
            setPubCard(parseInt(id));
        }
    }, [id]);

    return (
        <>
            <PubCard pubId={pubCard} setPubId={setPubCard}/>
        </>
    )
}
