import Box from "@mui/material/Box";
import {Link} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as React from "react";
export default function TreeRow(props: { pub: any, setPubToDelete: any }) {
    const pub = props.pub;
    return (
        <Box sx={{fontSize: '15px'}}>
            <a href={`/publishers/${pub.publisherId}`} target={"_blank"}>{pub.publisherName } ({pub.publisherId})</a> ({pub.trafficType})
            Added: <Box component='span' sx={{fontWeight: 'bold'}}>{new Date(pub.date).toLocaleDateString()}</Box>,
            Last Lead: <Box component='span' sx={{fontWeight: 'bold'}}>{pub.lastLeadDate ? new Date(pub.lastLeadDate).toLocaleDateString() : 'N/A'}</Box>
            <HighlightOffIcon sx={{color: 'red', fontSize: 'medium', marginLeft: '5px', verticalAlign: 'text-top'}} onClick={() => props.setPubToDelete(props.pub)}/>
        </Box>
    )
}
