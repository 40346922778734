import * as React from "react";
import {toast, ToastContainer} from "react-toastify";
import {Outlet} from "react-router";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../providers/auth";
import Loading from "../components/loading";
import {gql} from "@apollo/client";
import {query} from "../lib/apollo";
const theme = createTheme();

export default function Layout() {
    let auth = useAuth();

    if (auth.loading) {

        return (<Loading/>);
    }

    // read querystring for client_id and redirect_url
    const urlParams = new URLSearchParams(window.location.search);
    const client_id = urlParams.get('client_id');
    const redirect_url = urlParams.get('redirect_url');
    if (client_id && redirect_url) {
        // set client_id and redirect_url in local storage
        localStorage.setItem('client_id', client_id);
        localStorage.setItem('redirect_url', redirect_url);
    }

    if (auth.user) {
        // get client_id and redirect_url from local storage
        const client_id = localStorage.getItem('client_id');
        const redirect_url = localStorage.getItem('redirect_url');

        if (client_id && redirect_url) {

            const q = gql`
                query Query($clientId: String!) {
                    ssoLogin(client_id: $clientId)
                }
                `;

            query(q, {clientId: client_id}).then(result => {
                if (result && result.ssoLogin) {
                    //unset client_id and redirect_url from local storage
                    localStorage.removeItem('client_id');
                    localStorage.removeItem('redirect_url');

                    let param = "?";
                    if (redirect_url.includes("?")) {
                        param = "&";
                    }
                    // redirect to redirect_url
                    window.location.href = `${redirect_url}${param}access_token=${result.ssoLogin}`;
                }
            });

        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Outlet/>
            <p></p>
            <ToastContainer position={toast.POSITION.TOP_CENTER} />
        </ThemeProvider>
    )
}
