import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LoadingDialog from "../../components/loadingDialog";

export default function UserLoginAs (params: {user: any, setUser: any, auth: any}) {
    const [loading, setLoading] = React.useState(false);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setUser(null);
    };

    const handleSignInAs = async () => {
        if (params && params.auth && params.user) {
            setLoading(true);
            await params.auth.signInAs(params.user.id);
            handleClose();
            setLoading(false);
        }
    }

    if (!params.user) return <> </>;

    return (
        <>

                <Dialog
                    open={!!params.user}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to login as
                            <Box component={"span"} sx={{fontWeight: "bold"}}> {params.user.name} ({params.user.id})</Box>
                            ?
                        </DialogContentText>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>No</Button>
                        <Button sx={{color: 'green'}} onClick={handleSignInAs} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
