import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import downloadCsv from "../../lib/downloadCsv";
import moment from "moment";

export async function getTrafficTypes() {
    const q = gql`
        query q {
            publisherTrafficTypes
        }
    `;

    const response = await query(q, null);

    if (!response || !response.publisherTrafficTypes) {
        return [];
    }

    return response.publisherTrafficTypes;
}
export async function searchPublishers(isActive: boolean, limit: number, search: string, sortColumn: string, sortAsc: boolean) {
    const q = gql`
      query q ($input: PublishersInput!) {
        publishers(input: $input) {
            count
            items {
                id
                name
                active
                blockPEC
                blockZovio
                minEpc
                maxEpc
                ignoreFraudLogix
                trafficType
                lastLeadDate
                cpl
                quality
                qAll
                marginLock
                fIds {
                    fId
                    date
                }
                pixel {
                    ... on PublisherPiggyBackPixelRead {
                        type
                        pixel
                    }
                    ... on PublisherAfterEmailPixelRead {
                        type
                        pixel
                        percentFire
                    }
                }
            }
        }
      } 
    `;

    const variables = {
        input: {
            active: isActive,
            limit,
            skip: 0,
            search,
            sort: [{column: sortColumn, isAsc: sortAsc}]
        }
    };

    const response = await query(q, variables);

    if (!response || !response.publishers) {
        return {count: 0, publishers: []};
    }

    return {count: response.publishers.count, publishers: response.publishers.items};
}

export function downloadPublishers(isActive: boolean, search: string, sortColumn: string, sortAsc: boolean) {

    searchPublishers(isActive, 10000, search, sortColumn, sortAsc).then((response) => {
        const content = [];
        const header = [];

        header.push('Name');
        header.push('Id');
        header.push('CPL');
        header.push('Quality');
        header.push('qAll');
        header.push('Ignore Fraud Logix');
        header.push('Margin Lock');
        header.push('Min EPC');
        header.push('Max EPC');
        header.push('Traffic Type');
        header.push('Last Lead');

        content.push(header);

        let row = [];
        response.publishers.forEach((r: any) => {
            row = [];
            row.push(r.name);
            row.push(r.id);
            row.push(r.cpl);
            row.push(r.quality);
            row.push(r.qAll);
            row.push(r.ignoreFraudLogix ? "Yes" : "");
            row.push(r.marginLock || "");
            row.push(r.minEpc || "");
            row.push(r.maxEpc || "");
            row.push(r.trafficType);
            row.push(r.lastLeadDate ? moment(r.lastLeadDate).format("MM/DD/YYYY") : "");
            content.push(row);
        });

        downloadCsv('publishers.csv', content).then(() => {});
    });
}
