import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LoadingDialog from "../../../components/loadingDialog";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import {mutate} from "../../../lib/apollo";
export default function BlockedPublisherDeleteComponent (params: {pubToDelete: any, setPubToDelete: any, pubs: any, setPubs: any}) {
    const [loading, setLoading] = React.useState(false);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setPubToDelete(null);
    };

    const handleDelete = async () => {
        setLoading(true);
        // run blockedPublisherDelete mutation
        const query = gql`
            mutation blockedPublisherDelete($brokerId: Int!, $publisherId: Int!) {
                blockedPublisherDelete(brokerId: $brokerId, publisherId: $publisherId)
            }
        `;

        const variables = {
            brokerId: params.pubToDelete.brokerId,
            publisherId: params.pubToDelete.publisherId

        }

        const response = await mutate(query, variables);
        if (response && response.blockedPublisherDelete) {
            const updatedPubs = params.pubs.filter((pub: any) => pub.brokerId !== params.pubToDelete.brokerId || pub.publisherId !== params.pubToDelete.publisherId);
            params.setPubs(updatedPubs);
            toast.success(<><Box component={"span"}
                                 sx={{fontWeight: "bold"}}>{params.pubToDelete.publisherName} ({params.pubToDelete.publisherId})</Box> unblocked
                from <Box
                    component={"span"}
                    sx={{fontWeight: "bold"}}>{params.pubToDelete.brokerName} ({params.pubToDelete.brokerId})</Box></>);
            handleClose();
        }
        setLoading(false);

    }

    if (!params.pubToDelete) return <> </>;

    return (
        <>

                <Dialog
                    open={!!params.pubToDelete}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to unblock <Box component={"span"}
                                                                  sx={{fontWeight: "bold"}}>{params.pubToDelete.publisherName} ({params.pubToDelete.publisherId})</Box> from <Box
                            component={"span"}
                            sx={{fontWeight: "bold"}}>{params.pubToDelete.brokerName} ({params.pubToDelete.brokerId})</Box>?
                        </DialogContentText>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>No</Button>
                        <Button sx={{color: 'green'}} onClick={handleDelete} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
