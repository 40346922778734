import React from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import moment from "moment";

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

const calcExtremes = (chart: Highcharts.Chart, name: string) => {
    let min = 0;
    let max = 0;
    let temp;
    let temp2
    let foundmin = false;
    let foundmax = false;
    chart.series.forEach((s: any) => {
        if ((s.visible && name !== s.name) || (!s.visible && name == s.name)) {
            temp = s.processedYData.filter((x: any) => x != null);

            if (temp.length > 0) {
                temp2 = temp.reduce((a: any, b: any) => Math.min(a, b));
                if (temp2 < min || !foundmin) {
                    min = temp2;
                    foundmin = true;
                }

                temp2 = temp.reduce((a: any, b: any) => Math.max(a, b));
                if (temp2 > max || !foundmax) {
                    max = temp2;
                    foundmax = true;
                }
            }
        }
    });

    chart.yAxis[0].setExtremes(min, max);
}


export default function LineGraph({height, categories, series, title, prefix, suffix, decimalPlaces, yAxisRight, yAxisLeft2}: {height: number, categories: any[], series: any[], title: string, prefix: string, suffix: string, decimalPlaces: number, yAxisRight?: any, yAxisLeft2?: any}) {

    const options = {
        chart: {
            type: 'spline',
            ignoreHiddenSeries : false
        },
        plotOptions: {
            series: {
                animation: true,
                events: {
                    legendItemClick: function (this: any) {
                        calcExtremes(this.chart, this.name);
                    }
                }
            }
        },
        title: {
            text: undefined,
            align: 'left'
        },
        xAxis: {
            categories,
            labels: {
                enabled: true,
            }
        },
        yAxis: [{
            title: {
                text: title
            }
        }],
        tooltip: {
            shared: true,
            useHtml: true,
            formatter: function (this: Highcharts.TooltipFormatterContextObject) {
                return (this.points || []).reduce(function (s, point) {
                    let pr: string = prefix;
                    let dec: number = decimalPlaces;
                    let suf: string = suffix;
                    if (point.series.name === 'EPC') {
                        pr = '$';
                        dec = 2;
                        suf = '';
                    } else if (point.series.name === 'Margin') {
                        pr = '';
                        dec = 1;
                        suf = '%';
                    } else if (point.series.name === '100/Cake Clk') {
                        pr = '';
                        dec = 1;
                        suf = '%';
                    } else if (point.series.name === 'Cake Conv Rate') {
                        pr = '';
                        dec = 1;
                        suf = '%';
                    }
                    return s + '<br/>' + `<span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${pr}${(point.y || "0").toLocaleString(undefined, {minimumFractionDigits: dec, maximumFractionDigits: dec})}${suf}</b>`
                }, '<b>' + this.x + '</b>');
            },
        },
        accessibility: {
            point: {
                valueSuffix: ''
            }
        },
        credits: {
            enabled: false
        },
        series
    }

    if (yAxisRight) {
        options.yAxis.push(yAxisRight);
    }

    if (yAxisLeft2) {
        options.yAxis.push(yAxisLeft2);
    }

    return (
        <HighchartsReact
            containerProps={{ style: { height: height + "px" } }}
            highcharts={Highcharts}
            options={options}
        />
    );
}
