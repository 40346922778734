import * as React from 'react';
import {gql} from "@apollo/client";
import {mutate, query} from "../../lib/apollo";
import Loading from "../loading";
import {Alert, Chip, FormControl, Grid, InputLabel, ListItem, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import TextField from "@mui/material/TextField";

export default function PubComments(params: {pubId: number}) {
    const [isAuto, setIsAuto] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [comments, setComments] = React.useState(null as any);
    const [showAddComment, setShowAddComment] = React.useState(false);
    const [newComment, setNewComment] = React.useState("");

    const reload = () => {
        const q = gql`
            query q ($publisherId: Int!, $isAuto: Boolean) {
                publisherComments(input: {publisherId: $publisherId, isAuto: $isAuto}) {
                    items {
                        id
                        date
                        userName
                        isAuto
                        comment
                    }
                }
            }
        `;

        const variables = {
            publisherId: params.pubId,
            isAuto : isAuto == 0 ? null : (isAuto === 1)
        }

        setLoading(true);
        query(q, variables).then(x => {
            setComments(x.publisherComments.items);
            setLoading(false);
        });
    }

    React.useEffect(() => {
        reload();
    }, [isAuto]);

    const submitNewComment = async () => {
        if (!newComment) return;

        setLoading(true);

        const q = gql`
            mutation m ($id: Int!, $comment: String!) {
                publisherAddComment(id: $id, comment: $comment)
            }
        `;

        const variables = {
            id: params.pubId,
            comment: newComment,
        }

        const response = await mutate(q, variables);

        if (response && response.publisherAddComment) {
            setShowAddComment(false);
            setNewComment("");
            reload();
        } else {
            setLoading(false);
        }
    }

    return (
        <>
            {loading ? <Box sx={{padding: 3}}><Loading/></Box> :
                <>
                    <Grid container spacing={2} sx={{padding: '10px'}}>
                        <Grid item xs={6}>
                            <FormControl fullWidth size={"small"}>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isAuto}
                                    label="Type"
                                    onChange={(event) => setIsAuto(event.target.value as number)}
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    <MenuItem value={1}>Auto</MenuItem>
                                    <MenuItem value={2}>Manual</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small">
                                <Button aria-label="Add Comment" onClick={() => {setShowAddComment(!showAddComment)}} variant={'contained'} color={'warning'}>
                                    <AddBoxOutlinedIcon />&nbsp;Add Comment
                                </Button>
                            </FormControl>
                        </Grid>
                        {showAddComment && <Grid item xs={12} sx={{borderBottom: '1px solid #ccc'}}>
                            <FormControl fullWidth size={"small"} sx={{marginTop: 2}}>
                                <TextField
                                    label="New Comment"
                                    multiline
                                    rows={4}
                                    placeholder={"Enter new comment here"}
                                    value={newComment}
                                    onChange={(e: any) => setNewComment(e.target.value)}
                                />
                            </FormControl>
                            <Box textAlign='center'>
                                <Button aria-label="Submit" onClick={submitNewComment} variant={'contained'} sx={{marginY: '10px'}}>
                                    Submit
                                </Button>
                            </Box>
                        </Grid>
                        }
                    </Grid>
                    {comments && !comments.length && <Alert severity="warning">No results found</Alert>}
                    {comments && comments.map((x: any) => {
                        return (
                            <ListItem key={x.id} sx={{borderBottom: '1px solid #ccc', display: 'block', fontSize: '14px'}}>
                                <Box sx={{width: '100%'}}><b>{x.userName}</b> - <i><span style={{color:"#999"}}>{new Date(x.date).toLocaleString()}</span></i></Box>
                                <Box sx={{paddingY: 1}}><div dangerouslySetInnerHTML={{__html: fixHtml(x.comment)}}/></Box>
                                <Box>{x.isAuto && <Chip size="small" color={"info"} label={"Auto Comment"}/>}</Box>
                            </ListItem>
                        )
                    })}
                </>}
        </>
    )

}

function fixHtml(s: string) {
 return s
     .replace(/</g, '&lt;')
     .replace(/>/g, '&gt')
    .replace(/\r?\n/g, '<br/>')
}
