import * as React from 'react';
import Button from "@mui/material/Button";
import {
    FormControl, IconButton,
    Input,
    InputAdornment,
    Menu,
} from "@mui/material";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useDebounce from "../../lib/UseDebounce";
import SearchIcon from "@mui/icons-material/Search";
import MultiSelectPanel from "./multiSelectPanel";
import LoadingDialog from "../loadingDialog";
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

export default function MultiSelect(props: {searchFunction: any, selectedItems: any[], setSelectedItems: any, placeholder: string}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [search, setSearch] = React.useState<string>('');
    const [items, setItems] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    let dropDownText: string;
    const a = props.selectedItems;
    if (a.length === 1) {
        dropDownText = a[0].value;
    } else if (a.length > 1) {
        dropDownText = `${a.filter((x) => !x.isGroup).length} selected`;
    } else {

        dropDownText = props.placeholder || 'None';
    }

    const searchItems = () => {
        props.searchFunction(search, props.selectedItems).then((x: any)=> {
            setItems(x);
            setLoading(false);
        });
    }

    useDebounce(() => {
        setLoading(true);
        searchItems();

        }, [search], 300
    );

    const sort = (items: any[]) => {
        items.sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
            return 0;
        });
    }
    const moveItem = (item: any, isAvailable: boolean, doSort: boolean = true) => {
        item.selected = false;
        if (isAvailable) {
            const index = items.indexOf(item);
            if (index > -1) {
                items.splice(index, 1);
                setItems([...items]);
                props.selectedItems.push(item);
                if (doSort) {
                    sort(props.selectedItems);
                }
                props.setSelectedItems([...props.selectedItems]);
            }
        } else {
            const index = props.selectedItems.indexOf(item);
            if (index > -1) {
                props.selectedItems.splice(index, 1);
                props.setSelectedItems([...props.selectedItems]);
                items.push(item);
                if (doSort) {
                    sort(items);
                }
                setItems([...items]);

            }
        }
    }

    const selectedItem = (event: any, item: any, isAvailable: boolean) => {
        event.stopPropagation();

        if (event.ctrlKey) {
            item.selected = !item.selected;

            if (isAvailable) {
                setItems([...items]);
            } else {
                props.setSelectedItems([...props.selectedItems]);
            }
        } else if(event.detail == 2){
            //  remove item for items

            moveItem(item, isAvailable);

        } else {
            if (isAvailable) {
                items.forEach(x => x.selected = false);
                item.selected = true;
                setItems([...items]);
            } else {
                props.selectedItems.forEach(x => x.selected = false);
                item.selected = true;
                props.setSelectedItems([...props.selectedItems]);
            }

        }
    }

    const moveAvailable = () => {
        items.forEach((x, index) => {
            if (x.selected) {
                props.selectedItems.push(items[index]);
            }
        });

        setItems(items.filter(x => !x.selected));
        sort(props.selectedItems);
    }

    const moveSelected = () => {
        props.selectedItems.forEach((x, index) => {
            if (x.selected) {
                items.push(props.selectedItems[index]);
            }
        });

        props.setSelectedItems(props.selectedItems.filter(x => !x.selected));
        sort(items);
    }

    const moveAvailableAll = () => {
        props.setSelectedItems(props.selectedItems.concat(items));
        setItems([]);
        sort(props.selectedItems);
    }

    const moveSelectedAll = () => {
        setItems(items.concat(props.selectedItems));
        props.setSelectedItems([]);
        sort(items);
    }

    return (
        <FormControl fullWidth>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{border: '1px solid #ced4da', borderRadius: '5px', padding: '5px', textAlign: 'left', alignItems: 'left', justifyContent: 'left', width: '100%', color: '#6c757d'
                    ,textTransform: 'none', fontWeight: 'normal', '&:hover': {backgroundColor: '#e9ecef', color: '#212529', borderColor: '#ced4da'}
                }}
            >
                <Box style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden'}}>{dropDownText}</Box>
                <Box style={{marginLeft: 'auto'}}>
                    {open ? <KeyboardArrowUpIcon sx={{verticalAlign: 'text-top', fontSize: 'medium'}}/> : <KeyboardArrowDownIcon sx={{verticalAlign: 'text-top', fontSize: 'medium'}}/>}
                </Box>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{width: '366px', padding: 1,
                    '@media (min-width: 850px)': {
                        width: '816px',
                    }
                }}>
                    <FormControl fullWidth variant="standard">
                        <Input
                            autoFocus={true}
                            id="search"
                            type="text"
                            spellCheck="false"
                            placeholder="Search"
                            value={search || ''}
                            onChange={(e: any) => setSearch(e.target.value)}
                            startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                            endAdornment={search && !loading && <IconButton onClick={() => setSearch("")}><CloseIcon sx={{fontSize: '15px'}}/></IconButton>}
                        />
                    </FormControl>
                    {loading ? <Box sx={{marginTop: '25px'}}>
                            <LoadingDialog/>
                        </Box>:
                        <Box style={{display: 'flex', flexDirection: 'row'}}>
                        <MultiSelectPanel items={items} isAvailable={true} selectedItem={selectedItem}/>
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <KeyboardArrowRight onClick={moveAvailable} sx={{marginY: "2px"}}/>
                                <KeyboardArrowLeft onClick={moveSelected} sx={{marginY: "2px"}}/>
                                <KeyboardDoubleArrowRight onClick={moveAvailableAll} sx={{marginY: "2px"}}/>
                                <KeyboardDoubleArrowLeft onClick={moveSelectedAll} sx={{marginY: "2px"}}/>
                            </Box>
                        <MultiSelectPanel items={props.selectedItems} isAvailable={false} selectedItem={selectedItem}/>
                    </Box>}
                </Box>

            </Menu>
        </FormControl>
    )
}
