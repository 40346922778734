import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, Grid, InputAdornment,
    InputLabel, MenuItem, Select, Switch
} from "@mui/material";
import { getBrokerOptions, getRuleActionOptions } from "./rulesService";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import TextField from "@mui/material/TextField";
import SingleSelect from "../../components/SingleSelect";

export default function RuleDelete (params: {rule: any, setRule: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {      
        setLoading(false);
    }, []);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setRule(null);        
    };

    const handleDelete = async (event: any) => {
        let q;
        let variables;
        let good = false;

        q = gql`
            mutation m($id: Int!) {
                ruleDelete(id: $id)
            }
        `; 
        
        variables = {
            id: params.rule.id
        };

        setLoading(true);

        const res = await mutate(q, variables);
        good = res && res.ruleDelete;

        if (good) {
            toast.success('Rule deleted successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }

    }

    if (!params.rule) return <> </>;

    return (
        <>
                <Dialog
                    open={!!params.rule}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'sm'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Are you sure you want to delete the rule "${params.rule.matchString}"?`}
                    </DialogTitle>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'green'}} onClick={handleClose}>Cancel</Button>
                        <Button sx={{color: 'red'}} onClick={handleDelete} autoFocus>Delete</Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
