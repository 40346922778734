import * as React from "react";

import Box from "@mui/material/Box";
import {Alert, FormControl, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead} from "@mui/material";

import Button from "@mui/material/Button";
import CachedIcon from "@mui/icons-material/Cached";
import DateRange, {RangeToTimes} from "../components/DateRange";
import {gql} from "@apollo/client";
import {query} from "../lib/apollo";
import Loading from "../components/loading";
import TableHeaderRow from "../components/TableHeaderRow";
import StripedTableRow from "../components/StripedTableRow";


export default function ApiUsagePage() {
    const [dateRange, setDateRange] = React.useState([] as any);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([] as any);

    React.useEffect(() => {
        document.title = "Api Usage | DN";
    }, []);

    React.useEffect(() => {
        updateDateRange(RangeToTimes("Today")).then(() => {
        });
    }, []);

    const updateDateRange = async (value: any) => {
        setDateRange(value);

        const g = gql`
            query ($startDate: String!, $endDate: String!) {
                userApiStats(startDate: $startDate, endDate: $endDate) {
                    rep {
                        id
                        name
                    }
                    name
                    count
                }
            }
        `

        const variables = {
            startDate: value[0],
            endDate: value[1]
        }

        setLoading(true)
        const result = await query(g, variables);

        if (result && result.userApiStats) {
            let data: any[] = [];
            result.userApiStats.forEach((x: any) => {
                const found = data.find((y: any) => y.rep.id === x.rep.id);
                if (found) {
                    found.total += x.count;
                    if (x.name === "Dupe Ping: Dupe") {
                        found.dupe += x.count;
                    } else if (x.name === "Dupe Ping: Not-Dupe") {
                        found.notDupe += x.count;
                    }
                } else {
                    let obj = {
                        rep: x.rep,
                        total: x.count,
                        dupe: x.name === "Dupe Ping: Dupe" ? x.count : 0,
                        notDupe: x.name === "Dupe Ping: Not-Dupe" ? x.count : 0,

                    };
                    data.push(obj);
                }
            });

            //sort data by total desc
            data.sort((a, b) => b.total - a.total);
            setData(data);
        }

        setLoading(false)
    }


    return (
        <>
            <h2>Api Usage</h2>
            <>
                <Box sx={{display: 'flex', marginBottom: 2}}>
                    <DateRange showTime={false} dateRange={dateRange} setDateRange={updateDateRange} style={{marginLeft: 'auto'}} placement={'bottomEnd'}/>

                    <Button aria-label="download" onClick={() => {updateDateRange(dateRange)}} variant={'contained'} color={'success'} sx={{marginLeft: '5px', minWidth: '40px'}} size={"small"}>
                        <CachedIcon sx={{fontSize: '20px'}} />
                    </Button>
                </Box>
                {loading ? <Loading/> :
                    <>
                        {!data || data.length === 0 ? <Alert severity="warning">No results found</Alert> :
                            <>
                                <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)', maxHeight: 'calc(100vh - 300px)'}}>
                                    <Table size="small">
                                    <TableHead>
                                        <TableHeaderRow>
                                            <TableCell>
                                                Publisher
                                            </TableCell>
                                            <TableCell sx={{align: 'right'}}>
                                                Total Pings
                                            </TableCell>
                                            <TableCell sx={{align: 'right'}}>
                                                Dupe Responses
                                            </TableCell>
                                            <TableCell sx={{align: 'right'}}>
                                                Not-Dupe Responses
                                            </TableCell>
                                        </TableHeaderRow>
                                    </TableHead>
                                        <TableBody>
                                            {data.map((row: any, index: number) => (
                                                <React.Fragment key={row.rep.id}>
                                                    <StripedTableRow>
                                                        <TableCell>
                                                            {row.rep.name}
                                                        </TableCell>
                                                        <TableCell sx={{align: 'right'}}>
                                                            {row.total.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                        </TableCell>
                                                        <TableCell sx={{align: 'right'}}>
                                                            {row.dupe.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                            &nbsp;({(row.dupe / row.total * 100).toFixed(0)}%)
                                                        </TableCell>
                                                        <TableCell sx={{align: 'right'}}>
                                                            {row.notDupe.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                            &nbsp;({(row.notDupe / row.total * 100).toFixed(0)}%)
                                                        </TableCell>
                                                    </StripedTableRow>
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                        </>
                }

            </>

        </>
    );
}
