import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import LoadingDialog from "../../components/loadingDialog";
import Button from "@mui/material/Button";
import moment from "moment";
import {gql} from "@apollo/client";
import {mutate} from "../../lib/apollo";
import {toast} from "react-toastify";

export default function PublisherCreditDelete (params: {credit: any, setCredit: any, reload: any}) {
    const [loading, setLoading] = React.useState(false);
    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setCredit(null);
    };

    const handleDelete = async () => {
        setLoading(true);
        const query = gql`
            mutation m($publisher_lead_hit_id: Int!) {
                purchaseCreditDelete(publisher_lead_hit_id: $publisher_lead_hit_id)
            }
        `;

        const variables = {
            publisher_lead_hit_id: params.credit.publisher_lead_hit_id,
        }

        const response = await mutate(query, variables);
        if (response && response.purchaseCreditDelete) {
            toast.success("Credit has been deleted successfully");
            handleClose();
            params.reload();
        }
        setLoading(false);
    }

    if (!params.credit) return <> </>;

    return (
        <>

            <Dialog
                open={!!params.credit}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the credit
                        for <Box component={"span"} sx={{fontWeight: "bold"}}>${params.credit.amount.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</Box> to <Box component={"span"} sx={{fontWeight: "bold"}}>{params.credit.publisher_name} ({params.credit.publisher_id})</Box> on <Box component={"span"} sx={{fontWeight: "bold"}}>{moment(params.credit.date).format("MM-DD-YYYY")}</Box>?
                    </DialogContentText>
                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>No</Button>
                        <Button sx={{color: 'green'}} onClick={handleDelete} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}
