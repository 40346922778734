import * as React from "react";
import {
    Dialog, DialogActions, DialogContent,
    DialogTitle, InputAdornment,
} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import LoadingDialog from "../../components/loadingDialog";
import TextField from "@mui/material/TextField";
import {gql} from "@apollo/client";
import {mutate} from "../../lib/apollo";

export default function SettingsUpdate (params: {settings: any, open: boolean, setOpen: any, reload: any}) {
    const [loading, setLoading] = React.useState(false);
    const [margin_lock_hours, setMarginLockHours] = React.useState(params.settings.margin_lock_hours);
    const [margin_target_percent, setMarginTargetPercent] = React.useState(params.settings.margin_target_percent);
    const [exclusive_percent, setExclusivePercent] = React.useState(params.settings.exclusive_percent);
    const [exclusive_tier1_percent, setExclusiveTier1Percent] = React.useState(params.settings.exclusive_tier1_percent);
    const [no_cf_after_pr_percent, setNoCfAfterPrPercent] = React.useState(params.settings.no_cf_after_pr_percent);
    const [no_min_margin_percent, setNoMinMarginPercent] = React.useState(params.settings.no_min_margin_percent);
    const [pec_wins_margin_percent, setPecWinsMarginPercent] = React.useState(params.settings.pec_wins_margin_percent);
    const [pec_bl_no_min_margin_percent, setPecBlNoMinMarginPercent] = React.useState(params.settings.pec_bl_no_min_margin_percent);

    React.useEffect(() => {
        setMarginLockHours(params.settings.margin_lock_hours);
        setMarginTargetPercent(params.settings.margin_target_percent);
        setExclusivePercent(params.settings.exclusive_percent);
        setExclusiveTier1Percent(params.settings.exclusive_tier1_percent);
        setNoCfAfterPrPercent(params.settings.no_cf_after_pr_percent);
        setNoMinMarginPercent(params.settings.no_min_margin_percent);
        setPecWinsMarginPercent(params.settings.pec_wins_margin_percent);
        setPecBlNoMinMarginPercent(params.settings.pec_bl_no_min_margin_percent);
    }, [params.open]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setOpen(false);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        setLoading(true);

        q = gql`
            mutation m($settings: SettingsUpdate!) {
                settingsUpdate(settings: $settings)
            }
        `;

        variables = {
            settings: {
                margin_lock_hours: parseInt(margin_lock_hours),
                margin_target_percent: parseInt(margin_target_percent),
                exclusive_percent: parseInt(exclusive_percent),
                exclusive_tier1_percent: parseInt(exclusive_tier1_percent),
                no_cf_after_pr_percent: parseInt(no_cf_after_pr_percent),
                no_min_margin_percent: parseInt(no_min_margin_percent),
                pec_wins_margin_percent: parseInt(pec_wins_margin_percent),
                pec_bl_no_min_margin_percent: parseInt(pec_bl_no_min_margin_percent),
            }
        }

        const res = await mutate(q, variables);
        good = res && res.settingsUpdate;

        if (good) {
            toast.success( 'Settings updated successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }

    }

    if (!params.open || (!margin_lock_hours && !margin_target_percent)) return <> </>;

    return (
        <>
                <Dialog
                    open={params.open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'lg'}
                    fullWidth={false}
                >
                    <DialogTitle id="alert-dialog-title">
                        Edit Settings
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoComplete={'off'}
                            margin="dense"
                            id="name"
                            label="Margin Lock"
                            type="number"
                            fullWidth
                            size={'small'}
                            value={margin_lock_hours}
                            onChange={(e) => setMarginLockHours(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Hours</InputAdornment>,
                            }}
                            inputProps={{
                                step: "1"
                            }}
                            helperText={'# of hours to look back at margin'}
                        />
                        <TextField
                            autoComplete={'off'}
                            margin="dense"
                            id="name"
                            label="Margin Target"
                            type="number"
                            fullWidth
                            size={'small'}
                            value={margin_target_percent}
                            onChange={(e) => setMarginTargetPercent(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                step: "1"
                            }}

                            helperText={'Teamwork kicks in (if margin is BELOW setting)'}
                        />

                        <TextField
                            autoComplete={'off'}
                            margin="dense"
                            id="name"
                            label="Exclusive"
                            type="number"
                            fullWidth
                            size={'small'}
                            value={exclusive_percent}
                            onChange={(e) => setExclusivePercent(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                step: "1"
                            }}
                            helperText={'Turns Exclusive Only On (if margin is ABOVE setting)'}
                        />

                        <TextField
                            autoComplete={'off'}
                            margin="dense"
                            id="name"
                            label="Tier 1 Exclusive"
                            type="number"
                            fullWidth
                            size={'small'}
                            value={exclusive_tier1_percent}
                            onChange={(e) => setExclusiveTier1Percent(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                step: "1"
                            }}
                            helperText={'Turns Exclusive Only On (if margin is ABOVE setting and below Exclusive setting)'}
                        />

                        <TextField
                            autoComplete={'off'}
                            margin="dense"
                            id="name"
                            label="No CF after PR"
                            type="number"
                            fullWidth
                            size={'small'}
                            value={no_cf_after_pr_percent}
                            onChange={(e) => setNoCfAfterPrPercent(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                step: "1"
                            }}

                            helperText={'No ClickFeed after Portal Redirect (if margin is ABOVE setting)'}
                        />

                        <TextField
                            autoComplete={'off'}
                            margin="dense"
                            id="name"
                            label="No Min EPC"
                            type="number"
                            fullWidth
                            size={'small'}
                            value={no_min_margin_percent}
                            onChange={(e) => setNoMinMarginPercent(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                step: "1"
                            }}
                            helperText={'Min EPCs will be ignored (if margin is BELOW setting)'}
                        />

                        <TextField
                            autoComplete={'off'}
                            margin="dense"
                            id="name"
                            label="Favor PEC"
                            type="number"
                            fullWidth
                            size={'small'}
                            value={pec_wins_margin_percent}
                            onChange={(e) => setPecWinsMarginPercent(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                step: "1"
                            }}
                            helperText={'PEC will be more likely to win SF bids against higher paying EPIs (if margin is ABOVE setting)'}
                        />

                        <TextField
                            autoComplete={'off'}
                            margin="dense"
                            id="name"
                            label="Operation Human Shield"
                            type="number"
                            fullWidth
                            size={'small'}
                            value={pec_bl_no_min_margin_percent}
                            onChange={(e) => setPecBlNoMinMarginPercent(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            inputProps={{
                                step: "1"
                            }}
                            helperText={'PEC Blocked Pubs will ignore Min EPC and Max EPC will be lowered by 25% (if margin is BELOW setting)'}
                        />

                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            Update
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
