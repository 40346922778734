import * as React from "react";
import Loading from "../../components/loading";
import {getCompanies} from "./companiesService";
import {
    Alert, FormControl,
    FormControlLabel, FormGroup, IconButton, Menu, MenuItem,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Button,
} from "@mui/material";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StripedTableRow from "../../components/StripedTableRow";
import TableHeaderRow from "../../components/TableHeaderRow";
import CompanyToggleActive from "./companyToggleActive";
import CompanyUpsert from "./companyUpsert";

export default function CompaniesPage() {
    //
    const [allCompanies, setAllCompanies] = React.useState([] as any[]);
    const [filteredCompanies, setFilteredCompanies] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [active, setActive] = React.useState(true);
    const [companiesActive, setCompaniesActive] = React.useState<any>(null as any);
    const [companyUpsert, setCompanyUpsert] = React.useState<any>(null as any);
    const [anchorEls, setAnchorEls] = React.useState<any>({});

    //
    React.useEffect(() => {
        document.title = "Companies | DN";
    }, []);

    const reload = async (isActive: boolean = true ) => {                
        setLoading(true);
        const c = await getCompanies();          
        setAllCompanies(c.companies);
        FilterCompaniesByActive(c.companies, active);  
        setLoading(false);
    }

    const handleActive = (event: React.ChangeEvent<HTMLInputElement>) => {
        FilterCompaniesByActive(allCompanies, event.target.checked);
        setActive(event.target.checked);  
    };

    const FilterCompaniesByActive = (companiesToFilter: any[], isActive: boolean = true ) => {
        //get only the active or inactive based on isActive from allCompanies
        setFilteredCompanies(companiesToFilter.filter((company) => {
            if (isActive) {
                return company.active;
            } else {
                return !company.active;
            }

        }));
    }

    React.useEffect(() => {
        reload().then(x=>{});
    }, []);

    //
    return (
        <>
            <h2>Companies</h2>
            <FormGroup row={true}>
            <FormControlLabel control={<Switch checked={active} onChange={handleActive} />} label="Active" />
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Button aria-label="Add" onClick={() => { setCompanyUpsert({}) }} variant={'contained'} color={'warning'}>
                    <AddBoxOutlinedIcon />&nbsp;Create Company
                </Button>
            </FormControl>
            </FormGroup> 
            {loading ? <Loading/> :
                <>
                    {!filteredCompanies || filteredCompanies.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell>
                                            Id
                                        </TableCell>
                                        <TableCell>
                                            Name
                                        </TableCell>                                        
                                        <TableCell>
                                            Active
                                        </TableCell>                                     
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {filteredCompanies.map((row: any) => (
                                        <React.Fragment key={row.id}>
                                            <StripedTableRow>
                                            <TableCell>
                                                {row.id}
                                            </TableCell>
                                            <TableCell>
                                                {row.name}
                                            </TableCell>                                            
                                            <TableCell sx={{paddingLeft:3}}>
                                                {row.active && <CheckCircleOutlineIcon sx={{color: 'green', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/>}
                                                {!row.active && <HighlightOffIcon sx={{color: 'red', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/>}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    aria-haspopup="true"
                                                    onClick={(event) => {
                                                        setAnchorEls({...anchorEls, [row.id]: event.currentTarget});
                                                    }}
                                                >
                                                    <MoreVertIcon sx={{fontSize: '15px'}} />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEls[row.id]}
                                                    open={!!anchorEls[row.id]}
                                                    onClose={() => {
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }}
                                                >
                                                    <MenuItem onClick={() => {
                                                        setCompanyUpsert(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><EditIcon sx={{marginRight: 1}} /> Edit Company</MenuItem>
                                                    {!row.active && <MenuItem onClick={() => {
                                                        setCompaniesActive(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><DoneOutlineIcon  sx={{marginRight: 1}}/> Activate</MenuItem>}

                                                    {row.active && <MenuItem onClick={() => {
                                                        setCompaniesActive(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><HighlightOffIcon  sx={{marginRight: 1}}/> De-Activate</MenuItem>}
                                                </Menu>
                                            </TableCell>
                                        </StripedTableRow>                                        
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </>
                    }
                </>
            }            
            <CompanyUpsert company={companyUpsert} setCompany={setCompanyUpsert} reload={() => {reload(active).then(() => {})}} />
            <CompanyToggleActive company={companiesActive} setCompany={setCompaniesActive} reload={() => {reload(active).then(() => {})}} />
        </>
    )
}