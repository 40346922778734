import * as React from 'react';
import {
    Alert, Box,
    Button,
    FormControl,
    FormGroup, IconButton,
    Input,
    InputAdornment,
    InputLabel, Link, Menu,
    MenuItem, Pagination, Paper,
    Select,
    SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableSortLabel
} from "@mui/material";
import useDebounce from "../../lib/UseDebounce";
import SearchIcon from "@mui/icons-material/Search";
import Loading from "../../components/loading";
import {gql} from "@apollo/client";
import {mutate, query} from "../../lib/apollo";
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import SeoSchoolUpsert from "./seoSchoolUpsert";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import {useAuth} from "../../providers/auth";
import {PermissionType} from "../../objects/UserRead";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import downloadCsv from "../../lib/downloadCsv";

export default function SeoSchoolsPage() {
    const inputFileRef = React.useRef([] as any[]);
    const [schools, setSchools] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [limit, setLimit] = React.useState(100);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [maxPage, setMaxPage] = React.useState(1);
    const [anchorEls, setAnchorEls] = React.useState<any>({});
    const [schoolUpsert, setSchoolUpsert] = React.useState<any>(null as any);
    const [years, setYears] = React.useState([] as any[]);
    let auth = useAuth();

    React.useEffect(() => {
        document.title = "Seo Schools | DN";
    }, []);

    const getData = async (nLimit: number, sSearch: string, nPage: number) => {
        const q = gql`
            query q ($input: SeoSchoolsInput!) {
                seoSchools(input: $input) {
                    count
                    items {
                        id
                        college_name
                        state
                        payscale_url
                        other_salary_url
                        nces_url
                        website_url
                        description
                        profile_url
                        logo_url
                    }
                }
                seoYears {
                    year
                }
            }
        `;

        const variables = {
            input: {
                limit: nLimit,
                skip: nPage * limit,
                search: sSearch
            }
        };

        return await query(q, variables);
    };
    const reload = async (nLimit: number, sSearch: string, nPage: number) => {
        setLoading(true);

        const response = await getData(nLimit, sSearch, nPage);
        if (!response || !response.seoSchools) {
            setSchools([]);
            setCount(0);
            setMaxPage(0)
        } else {
            setSchools(response.seoSchools.items);
            setCount(response.seoSchools.count);
            setMaxPage(Math.ceil(response.seoSchools.count / nLimit));
        }

        if (response && response.seoYears) {
            let years = response.seoYears.map((x: any) => x.year);
            if (!auth.user?.permissions.includes(PermissionType.SEO)) {
                years = years.filter((x: any) => x === new Date().getFullYear());
            }
            setYears(years);
        } else {
            setYears([]);
        }

        setLoading(false);
    }

    const handleLimit = async (event: SelectChangeEvent) => {
        const l = parseInt(event.target.value as string, 10);
        setLimit(l);
        setPage(0)
        await reload(l, search, 0);
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
        await reload(limit, search, value - 1);
    };

    useDebounce(() => {
            setPage(0);
            reload(limit, search, 0).then(x=>{});
        }, [search], 400
    );

    const elipses = (str: string, len: number) => {
        if (str.length > len) {
            return str.substring(0, len) + '...';
        }
        return str;
    }

    const handleFile = async (id: number, event: any, ref: any) => {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64 = reader.result as string;
            const name = file.name;
            const type = file.type;

            const q = gql`
                mutation m($id: Int!, $file: FileUpload!) {
                    seoSchoolLogoUpload(id: $id, file: $file)
                }
            `;

            const variables = {
                id,
                file: {
                    filename: name,
                    mimetype: type,
                    base64: base64,
                }
            };

            mutate(q, variables).then((response: any) => {
                reload(limit, search, page).then(x=>{});
            });
        }
    }

    const download = async () => {
        const response = await getData(10000, '', 0);

        const content = [];
        const header = [];

        header.push('Id');
        header.push('Name');
        header.push('State');
        header.push('Profile Url');
        header.push('Website Url');
        header.push('Payscale Url');
        header.push('Other Salary Url');

        content.push(header);

        let row = [];
        response.seoSchools.items.forEach((x: any) => {
            row = [];
            row.push(x.id);
            row.push(x.college_name);
            row.push(x.state);
            row.push(x.profile_url);
            row.push(x.website_url);
            row.push(x.payscale_url);
            row.push(x.other_salary_url);
            content.push(row);
        });

        downloadCsv('schools.csv', content).then(() => {});
    }

    return (
        <>
            <h2>SEO Schools {years.map(y => {
                return <Button component={Link} key={y} variant="outlined" size="small" sx={{margin: '0 5px'}} href={`/seoRankings/${y}`}>{y}</Button>
            })}</h2>
            <FormGroup row={true}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="items-label">Items</InputLabel>
                    <Select
                        labelId="items-label"
                        value={limit.toString()}
                        label="Items"
                        onChange={handleLimit}
                        size={'small'}
                    >
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                        <MenuItem value={1000}>1000</MenuItem>
                        <MenuItem value={2000}>2000</MenuItem>
                        <MenuItem value={10000}>10000</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} size="small">
                    <Input
                        id="search"
                        type="text"
                        spellCheck="false"
                        placeholder="Search"
                        value={search || ''}
                        onChange={(e: any) => setSearch(e.target.value)}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="Add" onClick={() => { setSchoolUpsert({}) }} variant={'contained'} color={'warning'}>
                        <AddBoxOutlinedIcon />&nbsp;Create School
                    </Button>
                </FormControl>
                {!!schools && !!schools.length && !loading && <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="download" onClick={() => { download() }} variant={'contained'} color={'success'}>
                        <FileDownloadIcon />&nbsp;Export
                    </Button>
                </FormControl>}
            </FormGroup>
            {loading ? <Loading/> :
                <>
                    {!schools || schools.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                            <Box sx={{margin: '10px 0', fontWeight: 'bold', fontSize: '13px', display:"flex",}}>
                                Showing {1 + page * limit} to {Math.min(schools.length + page * limit, count).toLocaleString(undefined, {maximumFractionDigits: 0})} of {count.toLocaleString(undefined, {maximumFractionDigits: 0})}.
                            </Box>
                            <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)', maxHeight: 'calc(100vh - 300px)'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableHeaderRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell>
                                                Id
                                            </TableCell>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                State
                                            </TableCell>
                                            <TableCell>
                                                Logo
                                            </TableCell>
                                            <TableCell>
                                                Payscale
                                            </TableCell>
                                            <TableCell>
                                                Other
                                            </TableCell>
                                            <TableCell>
                                                Nces
                                            </TableCell>
                                            <TableCell>
                                                Website
                                            </TableCell>
                                            <TableCell>
                                                Profile
                                            </TableCell>
                                            <TableCell>
                                                Description
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableHeaderRow>
                                    </TableHead>
                                    <TableBody>
                                        {schools.map((row: any, index: number) => (
                                            <React.Fragment key={row.id}>
                                                <StripedTableRow>
                                                    <TableCell>
                                                        <b>{index + 1 + page*limit}.</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.college_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.state}
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <input type="file"
                                                               onChange={() => handleFile(row.id, event, inputFileRef.current[index])}
                                                               key={index}
                                                               ref={el => inputFileRef.current[index] = el}
                                                               accept=".png"
                                                               style={{opacity: 0, position: 'absolute', zIndex: -1, width: 0, height: 0}}
                                                        />
                                                        {row.logo_url.indexOf('http') === 0 && <><img src={row.logo_url} onClick={()=> inputFileRef.current[index].click()} style={{cursor: "pointer"}}/></>}
                                                        {row.logo_url.indexOf('http') !== 0 && <><div style={{borderStyle: 'dashed', borderWidth: 2, width: 100, height: 50, color: "#ccc", cursor: 'pointer'}} onClick={()=> inputFileRef.current[index].click()}></div></>}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.payscale_url.indexOf('http') === 0 && <><a href={row.payscale_url} target="_blank">{row.payscale_url}</a></>}
                                                        {row.payscale_url.indexOf('http') !== 0 && <>{row.payscale_url}</>}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.other_salary_url.indexOf('http') === 0 && <><a href={row.other_salary_url} target="_blank">{row.other_salary_url}</a></>}
                                                        {row.other_salary_url.indexOf('http') !== 0 && <>{row.other_salary_url}</>}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.nces_url.indexOf('http') === 0 && <><a href={row.nces_url} target="_blank">{row.nces_url}</a></>}
                                                        {row.nces_url.indexOf('http') !== 0 && <>{row.nces_url}</>}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.website_url.indexOf('http') === 0 && <><a href={row.website_url} target="_blank">{row.website_url}</a></>}
                                                        {row.website_url.indexOf('http') !== 0 && <>{row.website_url}</>}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.profile_url.indexOf('http') === 0 && <><a href={row.profile_url} target="_blank">{row.profile_url}</a></>}
                                                        {row.profile_url.indexOf('http') !== 0 && <>{row.profile_url}</>}
                                                    </TableCell>
                                                    <TableCell>
                                                        {elipses(row.description, 60)}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            aria-haspopup="true"
                                                            onClick={(event) => {
                                                                setAnchorEls({...anchorEls, [row.id]: event.currentTarget});
                                                            }}
                                                        >
                                                            <MoreVertIcon sx={{fontSize: '15px'}} />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={anchorEls[row.id]}
                                                            open={!!anchorEls[row.id]}
                                                            onClose={() => {
                                                                setAnchorEls({...anchorEls, [row.id]: null});
                                                            }}
                                                        >
                                                            <MenuItem onClick={() => {
                                                                setSchoolUpsert(row);
                                                                setAnchorEls({...anchorEls, [row.id]: null});
                                                            }} disableRipple><EditIcon sx={{marginRight: 1}} /> Edit School</MenuItem>
                                                        </Menu>
                                                    </TableCell>
                                                </StripedTableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Pagination count={maxPage} variant="outlined" shape="rounded" style={{paddingTop: 15}} defaultPage={page + 1} onChange={handlePageChange} />
                        </>
                    }
                </>
            }
            <SeoSchoolUpsert school={schoolUpsert} setSchool={setSchoolUpsert} reload={() => {reload(limit, search, page).then(() => {})}} />
        </>
    );
}
