import * as React from 'react';
import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import Loading from "../loading";
import {Alert, Chip, FormControl, InputLabel, ListItem, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";

export default function PubBlocked(params: {pubId: number}) {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState(null as any);

    const reload = () => {
        const q = gql`
            query q ($input: BlockedPublishersInput!) {
                blockedPublishers(input: $input) {
                    brokerId
                    brokerName
                }
            }
        `;

        const variables = {
            input: {
                publisherId: params.pubId,
            },
        }

        setLoading(true);
        query(q, variables).then(x => {
            setData(x.blockedPublishers);
            setLoading(false);
        });
    }

    React.useEffect(() => {
        reload();
    }, []);

    return (
        <>
            {loading ? <Box sx={{padding: 3}}><Loading/></Box> :
                <>
                    {data && !data.length && <Alert severity="warning">No results found</Alert>}
                    {data && data.map((x: any) => {
                        return (
                            <ListItem key={x.brokerId} sx={{borderBottom: '1px solid #ccc', display: 'block', fontSize: '14px'}}>
                                {x.brokerName} ({x.brokerId})
                            </ListItem>
                        )
                    })}
                </>}
        </>
    )

}
