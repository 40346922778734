export default async function downloadCsv(filename: string, content: any[][]) {
    let finalVal = '';

    for (let i = 0; i < content.length; i += 1) {
        const value = content[i];

        for (let j = 0; j < value.length; j += 1) {
            let innerValue = value[j];

            if (typeof innerValue === 'undefined' || innerValue == null) {
                innerValue = '';
            }

            let result = innerValue.toString().replace(/"/g, '""');

            if (innerValue === 0 || innerValue === '0') {
                result = innerValue.toString();
            }

            if (result.search(/("|,|\n)/g) >= 0) {
                result = `"${result}"`;
            }

            if (j > 0) {
                finalVal += ',';
            }
            finalVal += result;
        }

        finalVal += '\n';
    }


    const pom = document.createElement('a');
    pom.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(finalVal)}`);
    pom.setAttribute('download', filename);
    pom.click();
}
