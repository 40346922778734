import * as React from "react";
import {gql} from "@apollo/client";
import {mutate} from "../../lib/apollo";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import LoadingDialog from "../../components/loadingDialog";
import Button from "@mui/material/Button";
export default function SeoRankingSchoolHide (params: {school: any, setSchool: any, reload: any}) {
    const [loading, setLoading] = React.useState(false);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setSchool(null);
    };

    const handleUpdateActive= async () => {
        setLoading(true);
        const query = gql`
            mutation m($id: Int!, $is_hidden: Boolean!) {
                seoRankingSchoolHide(id: $id, is_hidden: $is_hidden)
            }
        `;

        const variables = {
            id: params.school.id,
            is_hidden: !params.school.isHidden,
        }

        const response = await mutate(query, variables);
        if (response && response.seoRankingSchoolHide) {
            toast.success(<><Box component={"span"}
                                 sx={{fontWeight: "bold"}}>{params.school.school.college_name}</Box> has been {params.school.isHidden ? 'Un-hidden' : 'Hidden'}.</>);
            handleClose();
            params.reload();
        }
        setLoading(false);

    }

    if (!params.school) return <> </>;

    return (
        <>

            <Dialog
                open={!!params.school}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {params.school.isHidden ? 'Un-hide' : 'Hide'} <Box component={"span"} sx={{fontWeight: "bold"}}>{params.school.school.college_name}</Box>?
                    </DialogContentText>
                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>No</Button>
                        <Button sx={{color: 'green'}} onClick={handleUpdateActive} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}
