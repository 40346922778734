import * as React from 'react';
import {Route, Routes} from "react-router";
import Layout from "./layouts/layout";
import LoginPage from "./pages/login";
import Public from "./layouts/public";
import NotFoundPage from "./pages/notfound";
import Secure from "./layouts/secure";
import DashboardPage from "./pages/dashboard/dashboard";
import {AuthProvider} from "./providers/auth";
import BlockedPublishersPage from "./pages/blockedPublishers/blockedPublishers";
import IpLookupPage from "./pages/ipLookup";
import ClickFeedImportedPage from "./pages/clickFeedImported";
import PublishersPage from "./pages/publishers/publishers";
import PublisherPage from "./pages/publisher";
import PublisherCreditsPage from "./pages/publisherCredits/publisherCredits";
import BrokersPage from "./pages/brokers/brokers";
import CompaniesPage from "./pages/companies/companies";
import RevenueCalendarPage from "./pages/revenueCalendar";
import RulesPage from './pages/rules/rules';
import SchoolsPage from './pages/schools/schools';
import UsersPage from './pages/users/users';
import SeoSchoolsPage from "./pages/seo/seoSchools";
import SeoYearPage from "./pages/seo/seoYear";
import SeoRankingsPage from "./pages/seo/seoRankings";
import SeoRankingPage from "./pages/seo/seoRanking";
import GclidRevenuePage from "./pages/google/gclidRevenue";
import ClickWallSchoolsPage from "./pages/clickwall/clickWallSchools";
import ClickWallProgramsPage from "./pages/clickwall/clickWallPrograms";
import ClickWallProgramMapsPage from "./pages/clickwall/clickWallProgramMaps";
import WindowsDashboardPage from "./pages/dashboard/windows/dashboard";
import LegalDashboardPage from "./pages/dashboard/legal/dashboard";
import AutoDashboardPage from "./pages/dashboard/auto/dashboard";
import ApiUsagePage from "./pages/apiUsage";
import ClickWallLeaderboard from "./pages/clickwall/clickWallLeaderboard";
import ZipUpload from "./pages/zipUpload";
import ReportCardsPage from "./pages/reportCards";
import TTDashboardPage from "./pages/dashboard/tt/dashboard";
import PhoneUpload from "./pages/phoneUpload";

export default function App() {
    return (
        <AuthProvider>
            <Routes>
                <Route element={<Layout />}>
                    <Route element={<Public />}>
                        <Route path="/" element={<LoginPage />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Route>
                    <Route element={<Secure />}>
                        <Route path="/dashboard" element={<DashboardPage />} />
                        <Route path="/blockedPubs" element={<BlockedPublishersPage />} />
                        <Route path="/ipLookup" element={<IpLookupPage />} />
                        <Route path="/clickFeedImported" element={<ClickFeedImportedPage />} />
                        <Route path="/publishers" element={<PublishersPage />} />
                        <Route path="/publishers/:id" element={<PublisherPage />} />
                        <Route path="/brokers" element={<BrokersPage />} />
                        <Route path="/schools" element={<SchoolsPage />} />
                        <Route path="/companies" element={<CompaniesPage /> } />
                        <Route path="/publisherCredits" element={<PublisherCreditsPage />} />
                        <Route path="/revenueCalendar" element={<RevenueCalendarPage />} />
                        <Route path="/rules" element={<RulesPage />} />
                        <Route path="/users" element={<UsersPage />} />
                        <Route path="/seoSchools" element={<SeoSchoolsPage />} />
                        <Route path="/seoYear/:year" element={<SeoYearPage />} />
                        <Route path="/seoRankings/:year" element={<SeoRankingsPage />} />
                        <Route path="/seoRanking/:id" element={<SeoRankingPage />} />
                        <Route path="/gclidRevenue" element={<GclidRevenuePage />} />
                        <Route path="/clickWallSchools" element={<ClickWallSchoolsPage />} />
                        <Route path="/clickWallPrograms/:school_id" element={<ClickWallProgramsPage />} />
                        <Route path="/clickWallProgramMaps/:school_id/:program_id" element={<ClickWallProgramMapsPage />} />
                        <Route path="/clickWallLeaderboard" element={<ClickWallLeaderboard />} />
                        <Route path="/windows" element={<WindowsDashboardPage />} />
                        <Route path="/legal" element={<LegalDashboardPage />} />
                        <Route path="/auto" element={<AutoDashboardPage />} />
                        <Route path="/tt" element={<TTDashboardPage />} />
                        <Route path="/apiUsage" element={<ApiUsagePage />} />
                        <Route path="/zipUpload" element={<ZipUpload />} />
                        <Route path="/phoneUpload" element={<PhoneUpload />} />
                        <Route path="/reportCards" element={<ReportCardsPage/>} />
                    </Route>
                </Route>

            </Routes>
        </AuthProvider>
    );
}
