import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import SingleSelect from "../../components/SingleSelect";
import {getBillingGroup, getDupeGroup} from "../schools/schoolsService";
import LoadingDialog from "../../components/loadingDialog";
import Button from "@mui/material/Button";
import {gql} from "@apollo/client";
import {mutate} from "../../lib/apollo";
import {toast} from "react-toastify";

export default function SeoSchoolUpsert (params: {school: any, setSchool: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [name, setName] = React.useState('' as string);
    const [state, setState] = React.useState('' as string);
    const [payscaleUrl, setPayscaleUrl] = React.useState('' as string);
    const [otherSalaryUrl, setOtherSalaryUrl] = React.useState('' as string);
    const [ncesUrl, setNcesUrl] = React.useState('' as string);
    const [websiteUrl, setWebsiteUrl] = React.useState('' as string);
    const [profileUrl, setProfileUrl] = React.useState('' as string);
    const [description, setDescription] = React.useState('' as string);

    React.useEffect(() => {
        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (params.school) {
            if (params.school.college_name) {
                setName(params.school.college_name || '');
                setState(params.school.state || '');
                setPayscaleUrl(params.school.payscale_url || '');
                setOtherSalaryUrl(params.school.other_salary_url || '');
                setNcesUrl(params.school.nces_url || '');
                setWebsiteUrl(params.school.website_url || '');
                setProfileUrl(params.school.profile_url || '');
                setDescription(params.school.description || '');
            } else {
                setName('');
                setState('');
                setPayscaleUrl('');
                setOtherSalaryUrl('');
                setNcesUrl('');
                setWebsiteUrl('');
                setProfileUrl('');
                setDescription('');
            }
        }
    }, [params.school]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setSchool(null);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        const input = {
            college_name: name,
            state,
            payscale_url: payscaleUrl,
            other_salary_url: otherSalaryUrl,
            nces_url: ncesUrl,
            website_url: websiteUrl,
            profile_url: profileUrl,
            description
        };

        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $input: SeoSchoolWrite!) {
                    seoSchoolUpdate(id: $id, input: $input)
                }
            `;

            variables = {
                id: params.school.id,
                input
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.seoSchoolUpdate;
        } else {
            q = gql`
                mutation m($input: SeoSchoolWrite!) {
                    seoSchoolCreate(input: $input) 
                }
            `;

            variables = {
                input: input
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.seoSchoolCreate;
        }

        if (good) {
            toast.success(isEdit ? 'School updated successfully' : 'School created successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }
    }

    if (!params.school) return <> </>;

    const isEdit = !!params.school.id;

    return (
        <>
            <Dialog
                open={!!params.school}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    {isEdit ? `Update: ${params.school.college_name}` : 'Create School'}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="state"
                                label="State"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="payScaleUrl"
                                label="Payscale Url"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={payscaleUrl}
                                onChange={(e) => setPayscaleUrl(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="otherSalaryUrl"
                                label="Other Salary Url"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={otherSalaryUrl}
                                onChange={(e) => setOtherSalaryUrl(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="ncesUrl"
                                label="NCES Url"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={ncesUrl}
                                onChange={(e) => setNcesUrl(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="websiteUrl"
                                label="Website Url"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={websiteUrl}
                                onChange={(e) => setWebsiteUrl(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="profileUrl"
                                label="Profile Url"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={profileUrl}
                                onChange={(e) => setProfileUrl(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="description"
                                label="Description"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                multiline
                                rows={4}
                                inputProps={{
                                    style: {
                                        resize: 'vertical'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}
