import {Card, CardContent, CardHeader, CircularProgress, Grid, IconButton} from "@mui/material";
import theme2 from "../../lib/theme";
import * as React from "react";
import {gql} from "@apollo/client";
import moment from "moment/moment";
import {query} from "../../lib/apollo";
import Loading from "../../components/loading";
import Box from "@mui/material/Box";
import CachedIcon from "@mui/icons-material/Cached";
import PieChart from "../../components/pieChart";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {NearbyError} from "@mui/icons-material";

export default function Inventory(params: {dateRange: any, selectedPublishers: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [series, setSeries] = React.useState([] as any[]);
    const [error, setError] = React.useState(false);
    const reload = async () => {
        setLoading(true);
        setError(false);

        const q = gql`
            query q($input: InventoryBreakDownInput!) {
                inventoryBreakDown(input: $input) {
                    value
                    label
                }
            }
        `;

        const variables = {
            input: {
                startDate: moment(params.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(params.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
                publisherIds: params.selectedPublishers.map((p: any) => p.id),
            }
        }

        const response = await query(q, variables);
        if (response && response.inventoryBreakDown) {
            const series = [{
                name: 'Lead Quality',
                colorByPoint: true,
                data: [] as any[]
            }]

            response.inventoryBreakDown.forEach((i: any) => {
                series[0].data.push({
                    name: i.label,
                    y: i.value,
                });
            });

            setSeries(series);
        } else {
            setError(true);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (params.reload) {
            reload().then(() => {});
        }
    }, [params.reload]);

    return (
        <Card sx={{height: '365px', maxWidth: 'calc(100vw - 10px)'}}>
            <CardHeader title={"Inventory"} sx={{backgroundColor: theme2.palette.primary.main, color: theme2.palette.primary.contrastText, padding: '5px'}}
                        titleTypographyProps={{sx:{fontSize: '18px'}}}
                        action={
                            <IconButton aria-label="refresh" sx={{color: theme2.palette.primary.contrastText}} onClick={reload}>
                                <CachedIcon />
                            </IconButton>
                        }
            />
            <CardContent>
                {loading ? <Box sx={{marginTop: '130px'}}><Loading/></Box> : (<>
                {error ?
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '130px'
                    }}
                >
                    <NearbyError sx={{color: theme2.palette.warning.light, fontSize: '50px'}}/>
                </Box> :<PieChart height={300} series={series}/>}
                </>)}
            </CardContent>
        </Card>
    );
}
