import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";

export async function getCompanies() {
    const q = gql`
      query q {
        companies {
          id
          active
          name
        }
      } 
    `;

    const response = await query(q, {});
    if (!response || !response.companies) {
        return {companies: []};
    }

    return {companies: response.companies};
}