import * as React from "react";
import {downloadBrokers, searchBrokers} from "./brokersService";
import Loading from "../../components/loading";
import {
    Alert, FormControl,
    FormControlLabel, FormGroup, IconButton, Input, InputAdornment, InputLabel, Menu, MenuItem,
    Paper, Select, SelectChangeEvent,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableSortLabel,
    Box,
    Button,
    Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../lib/UseDebounce";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from '@mui/icons-material/Edit';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import BrokerUpsert from "./brokerUpsert";
import BrokerToggleActive from "./brokerToggleActive";

export default function BrokersPage() {
    //
    const [brokers, setBrokers] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [active, setActive] = React.useState(true);
    const [limit, setLimit] = React.useState(100);
    const [search, setSearch] = React.useState('');
    const [anchorEls, setAnchorEls] = React.useState<any>({});
    const [brokerActive, setBrokerActive] = React.useState<any>(null as any);
    const [brokerUpsert, setBrokerUpsert] = React.useState<any>(null as any);
    const [count, setCount] = React.useState(0);
    const [sort, setSort] = React.useState({column: 'name', sortAsc: true});

    //
    React.useEffect(() => {
        document.title = "Brokers | DN";
    }, []);

    //
    const reload = async (isActive: boolean, nLimit: number, sSearch: string, sort: any) => {
        setLoading(true);
        const p = await searchBrokers(isActive, nLimit, sSearch, sort.column, sort.sortAsc);
        setBrokers(p.brokers);
        setCount(p.count);
        setLoading(false);
    }

    React.useEffect(() => {
        reload(active, limit, search, sort).then(x=>{});
    }, []);

    const handleActive= async (event: React.ChangeEvent<HTMLInputElement>) => {
        setActive(event.target.checked);
        await reload(event.target.checked, limit, search, sort);
    };

    const handleLimit = async (event: SelectChangeEvent) => {
        const l = parseInt(event.target.value as string, 10);
        setLimit(l);
        await reload(active, l, search, sort);
    };

    useDebounce(() => {
        reload(active, limit, search, sort).then(x=>{});
        }, [search], 400
    );

    const doSort = async (column: string, defaultAsc: boolean = false) => {
        let s;
        if (sort.column === column) {
            s = {column: column, sortAsc: !sort.sortAsc};
        } else {
            s = {column: column, sortAsc: defaultAsc};
        }

        setSort(s);
        await reload(active, limit, search, s);
    }

    //
    return (
        <>
            <h2>Brokers</h2>
            <FormGroup row={true}>
                <FormControlLabel control={<Switch checked={active} onChange={handleActive} />} label="Active" />
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="items-label">Items</InputLabel>
                    <Select
                        labelId="items-label"
                        value={limit.toString()}
                        label="Items"
                        onChange={handleLimit}
                        size={'small'}
                    >
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                        <MenuItem value={1000}>1000</MenuItem>
                        <MenuItem value={2000}>2000</MenuItem>
                        <MenuItem value={10000}>10000</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} size="small">
                    <Input
                        id="search"
                        type="text"
                        spellCheck="false"
                        placeholder="Search"
                        value={search || ''}
                        onChange={(e: any) => setSearch(e.target.value)}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    />
                </FormControl>
                {!!brokers && !!brokers.length && !loading && <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="download" onClick={() => { downloadBrokers(active, search, sort.column, sort.sortAsc) }} variant={'contained'} color={'success'}>
                        <FileDownloadIcon />&nbsp;Export
                    </Button>
                </FormControl>}

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="Add" onClick={() => { setBrokerUpsert({}) }} variant={'contained'} color={'warning'}>
                        <AddBoxOutlinedIcon />&nbsp;Create Broker
                    </Button>
                </FormControl>
            </FormGroup> 
            {loading ? <Loading/> :
                <>
                    {!brokers || brokers.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                            <Box sx={{margin: '10px 0', fontWeight: 'bold', fontSize: '13px', display:"flex",}}>
                                Showing 1 to {Math.min(brokers.length, count).toLocaleString(undefined, {maximumFractionDigits: 0})} of {count.toLocaleString(undefined, {maximumFractionDigits: 0})}.
                            </Box>
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'name'}
                                                direction={sort.column === 'name' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('name', true)}}
                                            >
                                                Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'id'}
                                                direction={sort.column === 'id' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('id', false)}}
                                            >
                                                Id
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'rating'}
                                                direction={sort.column === 'rating' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('rating', false)}}
                                            >
                                                Rating
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'timeZone'}
                                                direction={sort.column === 'timeZone' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('timeZone', false)}}
                                            >
                                                Time Zone
                                            </TableSortLabel>
                                        </TableCell>                                        
                                        <TableCell sx={{textAlign:"center"}}>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'nonPaymentPaused'}
                                                direction={sort.column === 'nonPaymentPaused' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('nonPaymentPaused', false)}}
                                            >
                                                Non-Payment Paused &nbsp; 
                                                <Tooltip title="Turning this on will freeze all ClickFeed traffic to client.">
                                                    <HelpIcon sx={{fontSize: '20px'}}/>
                                                </Tooltip>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell sx={{textAlign:"center"}}>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'creatives'}
                                                direction={sort.column === 'creatives' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('creatives', false)}}
                                            >
                                                Creatives &nbsp; 
                                                <Tooltip title="This broker will only receive leads that have an Approved Creative.">
                                                    <HelpIcon sx={{fontSize: '20px'}}/>
                                                </Tooltip>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {brokers.map((row: any) => (
                                        <React.Fragment key={row.id}>
                                            <StripedTableRow>
                                            <TableCell>
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.id}
                                            </TableCell>
                                            <TableCell sx={{paddingLeft:4}}>
                                                {row.rating}
                                            </TableCell>
                                            <TableCell>
                                                {row.timeZone}
                                            </TableCell>
                                            <TableCell sx={{paddingLeft:6}}>
                                                {row.nonPaymentPaused && <HighlightOffIcon sx={{color: 'red', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/>}
                                            </TableCell>
                                            <TableCell sx={{paddingLeft:6}}>
                                                {row.creatives && <CheckCircleOutlineIcon sx={{color: 'green', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/>}
                                            </TableCell>

                                            <TableCell align="right">
                                                <IconButton
                                                    aria-haspopup="true"
                                                    onClick={(event) => {
                                                        setAnchorEls({...anchorEls, [row.id]: event.currentTarget});
                                                    }}
                                                >
                                                    <MoreVertIcon sx={{fontSize: '15px'}} />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEls[row.id]}
                                                    open={!!anchorEls[row.id]}
                                                    onClose={() => {
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }}
                                                >
                                                    <MenuItem onClick={() => {
                                                        setBrokerUpsert(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><EditIcon sx={{marginRight: 1}} /> Edit Broker</MenuItem>
                                                    {!row.active && <MenuItem onClick={() => {
                                                        setBrokerActive(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><DoneOutlineIcon  sx={{marginRight: 1}}/> Activate</MenuItem>}

                                                    {row.active && <MenuItem onClick={() => {
                                                        setBrokerActive(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><HighlightOffIcon  sx={{marginRight: 1}}/> De-Activate</MenuItem>}
                                                </Menu>
                                            </TableCell>
                                        </StripedTableRow>                                        
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </>
                    }
                </>
            }
            <BrokerUpsert broker={brokerUpsert} setBroker={setBrokerUpsert} reload={() => {reload(active, limit, search, sort).then(() => {})}} />
            <BrokerToggleActive broker={brokerActive} setBroker={setBrokerActive} reload={() => {reload(active, limit, search, sort).then(() => {})}} />
        </>
    )
}