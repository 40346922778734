import * as React from 'react';
import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import Loading from "../loading";
import {
    Alert,
    Chip,
    FormControl,
    InputLabel,
    ListItem,
    MenuItem,
    Paper,
    Select, Table, TableBody,
    TableCell,
    TableContainer, TableHead, TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import DateRange, {RangeToTimes} from "../DateRange";
import moment from "moment/moment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import StripedTableRow from "../StripedTableRow";
import TableHeaderRow from "../TableHeaderRow";

export default function PubCreative(params: {pubId: number, creative: any, setCreative: any}) {
    React.useEffect(() => {
        if (!params.creative.dateRange.length) {
            updateDateRange(RangeToTimes("Last 30 Days"));
        }
    }, []);

    const updateDateRange = async (value: any) => {
        params.setCreative({...params.creative, loading: true, dateRange: value});
        const q = gql`
            query q($input: CreativeByPublisherInput!) {
                creativeByPublisher(input: $input) {
                    count
                    creative {
                        url
                        c_id
                        blockZovio
                        blockPEC
                        isApproved
                    }
                    lastUsed
                }
            }
        `;
        const variables = {
            input: {
                startDate: moment(value[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(value[1]).format("YYYY-MM-DD HH:mm:ss"),
                publisherId: params.pubId,
            }
        }

        const response = await query(q, variables);

        if (response && response.creativeByPublisher) {
            params.setCreative({...params.creative, loading: false, dateRange: value, data: response.creativeByPublisher});
        } else {
            params.setCreative({...params.creative, loading: false, dateRange: value});
        }
    }

    return (
        <>
            {params.creative.loading ? <Box sx={{padding: 3}}><Loading/></Box> :
                <Box sx={{padding: '0 10px 10px 10px'}}>
                    <DateRange showTime={false} dateRange={params.creative.dateRange} setDateRange={updateDateRange} style={{marginBottom: '15px'}}/>
                    {params.creative.data && !params.creative.data.length && <Alert severity="warning">No results found</Alert>}
                    {params.creative.data && !!params.creative.data.length &&
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 20px)'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell>URL</TableCell>
                                        <TableCell>Creative ID</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                        <TableCell align="right">Last</TableCell>
                                        <TableCell>Approved</TableCell>
                                        <TableCell>Blocks</TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {params.creative.data.map((y: any) =>  (
                                        <React.Fragment key={`${y.creative.url}-${y.creative.c_id}`}>
                                            <StripedTableRow>
                                                <TableCell><a href={y.creative.url} target={"_blank"}>{y.creative.url}</a></TableCell>
                                                <TableCell>{y.creative.c_id}</TableCell>
                                                <TableCell align="right">{y.count.toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                                <TableCell align="right">{moment(y.lastUsed).format("MM/DD/YYY HH:mm")}</TableCell>
                                                <TableCell align="center">
                                                    {y.creative.isApproved && <DoneOutlineIcon sx={{color: 'darkgreen', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/>}
                                                    {!y.creative.isApproved && <HighlightOffIcon sx={{color: 'red', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/>}
                                                </TableCell>
                                                <TableCell>
                                                    {y.creative.blockPEC && <span style={{textDecoration: 'line-through', color: 'red'}}>P</span>} {y.creative.blockZovio && <span style={{textDecoration: 'line-through', color: 'red'}}>U</span>}
                                                </TableCell>
                                            </StripedTableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>

                        </TableContainer>
                    }
                </Box>}
        </>
    )

}
