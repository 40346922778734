import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {CircularProgress} from "@mui/material";
import * as React from "react";
import {gql} from "@apollo/client";
import {mutate} from "../lib/apollo";
import {useAuth} from "../providers/auth";
import {Navigate, useLocation, useNavigate} from "react-router";

export default function LoginPage() {
    const [loading, setLoading] = React.useState(false);
    let location = useLocation();
    let auth = useAuth();

    React.useEffect(() => {
        document.title = "DN";
    }, []);

    if (auth.user) {
        const to = location.state?.from?.pathname ? location.state.from.pathname : "/dashboard";

        return <Navigate to={to} state={{ from: location }} />;
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        await auth.signIn(data.get('email') as string, data.get('password') as string);

        setLoading(false);
    };
    return (
        <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}

                >
                    {!loading ? <>Sign In</> : <>Please Wait...<CircularProgress color="primary" size={20}/></>}
                </Button>
            </Box>
        </>
    )
}
