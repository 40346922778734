import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import {getAssignedPublishers} from "./usersService";
import MultiSelect from "../../components/multiSelect/multiSelect";
import { searchPubs } from "../blockedPublishers/blockedPublishersService";

export default function UserAssignPubs (params: {user: any, setUser: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [selectedPublishers, setSelectedPublishers] = React.useState([] as any[]);

    React.useEffect(() => {
        if (params.user) {
            if (params.user.id) {
                getAssignedPublishers(params.user.id).then(x=> {
                    var mappedData = x.map((pub: any) => {
                        return { id: pub.id, value: `${pub.name} (${pub.id})`,};
                    });
                    setSelectedPublishers(mappedData);
                });
                
            }
        }
        setLoading(false); 
    }, [params.user]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setUser(null);
        setSelectedPublishers([]);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        q = gql`
            mutation m($id: Int!, $pubIds: [Int!]!) {
                userAssignPubs(id: $id, pubIds: $pubIds)
            }
        `;

        variables = {            
            id: params.user.id,
            pubIds: selectedPublishers.map((pid:any) => { return pid.id})
        };

        setLoading(true);

        const res = await mutate(q, variables);
        good = res && res.userAssignPubs;

        if (good) {
            toast.success('Assigned Publishers Successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }
    }

    if (!params.user) return <> </>;

    return (
        <>
                <Dialog
                    open={!!params.user}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'md'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Assign Pubs to ${params.user.name}`}
                    </DialogTitle>
                    <DialogContent>                        
                        <MultiSelect
                            searchFunction={searchPubs}
                            selectedItems={selectedPublishers}
                            setSelectedItems={setSelectedPublishers}
                            placeholder={"Select Publishers"}
                        />
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>Save</Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}