import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
export default function CompanyToggleActive (params: {company: any, setCompany: any, reload: any}) {
    const [loading, setLoading] = React.useState(false);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setCompany(null);
    };

    const handleToggleActive = async () => {
        setLoading(true);
        const query = gql`
            mutation m($id: Int!, $active: Boolean!) {
                companyUpdateActive(id: $id, active: $active)
            }
        `;

        const variables = {
            id: params.company.id,
            active: !params.company.active,
        }

        const response = await mutate(query, variables);
        if (response && response.companyUpdateActive) {
            toast.success(<><Box component={"span"}
                                 sx={{fontWeight: "bold"}}>{params.company.name} </Box> has been updated
                to <Box
                    component={"span"}
                    sx={{fontWeight: "bold"}}>{params.company.active ? "Inactive" : "Active"}</Box></>);
            handleClose();
            params.reload();
        }
        setLoading(false);

    }

    if (!params.company) return <> </>;

    return (
        <>

                <Dialog
                    open={!!params.company}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to set <Box component={"span"}
                                                                    sx={{fontWeight: "bold"}}>{params.company.name} </Box> to <Box
                            component={"span"}
                            sx={{fontWeight: "bold"}}>{params.company.active ? "Inactive" : "Active"}</Box>?
                        </DialogContentText>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>No</Button>
                        <Button sx={{color: 'green'}} onClick={handleToggleActive} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}