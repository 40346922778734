import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import TextField from "@mui/material/TextField";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { getRoles } from "./usersService";

export default function UserUpsert (params: {user: any, setUser: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [name, setName] = React.useState('' as string);
    const [email, setEmail] = React.useState('' as string);
    const [password, setPassword] = React.useState('' as string);
    const [showPassword, setShowPassword] = React.useState(false);
    const [roles, setRoles] = React.useState([] as any);
    const [role, setRole] = React.useState('' as string);

    React.useEffect(() => {
        getRoles().then((res: any) => {
            setRoles(res);
            setLoading(false);
        })
           
    }, []);

    React.useEffect(() => {
        if (params.user) {
            if (params.user.name) {
                setName(params.user.name || '');
                setEmail(params.user.email || '');
                setRole(params.user.role.id || '0')
            } else {
                setName('');
                setEmail('');
                setRole('0')
            }
            setPassword('');
        }
    }, [params.user]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setUser(null);
    };

    const handleToggle = () => {
        setShowPassword(!showPassword);
     }

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $input: UserUpdateInput!) {
                    userUpdate(id: $id, input: $input)
                }
            `;

            variables = {
                id: params.user.id,
                input: {
                    name,
                    email,
                    password,
                    role : parseInt(role)
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.userUpdate;
        } else {
            q = gql`
                mutation m($input: UserCreateInput!) {
                    userCreate(input: $input) {
                        id
                    }
                }
            `;

            variables = {
                input: {
                    name,
                    email,
                    password,
                    role : parseInt(role)
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.userCreate && res.userCreate.id;
        }

        if (good) {
            toast.success(isEdit ? 'User updated successfully' : 'User created successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }

    }

    if (!params.user) return <> </>;

    const isEdit = !!params.user.id;

    return (
        <>
                <Dialog
                    open={!!params.user}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'sm'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {isEdit ? `Update User ${params.user.name}` : 'Create User'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    size={'small'}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="email"
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    size={'small'}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}                                    
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                     autoComplete={'off'}
                                     margin="dense"
                                     type={showPassword ? "text" : "password"}
                                     name="password"
                                     label="Password"
                                     placeholder="Enter New Password"
                                     fullWidth
                                     size={'small'}
                                     value={password}
                                     onChange={(e) => setPassword(e.target.value)}
                                />
                                <span onClick={handleToggle}>
                                    {showPassword ? <VisibilityOutlinedIcon  sx={{width:'25px', height:'25px', zIndex:999, position:'absolute', right:'30px', top:'185px'}} /> :
                                                    <VisibilityOffOutlinedIcon  sx={{width:'25px', height:'25px', zIndex:999, position:'absolute', right:'30px', top:'185px'}}/>}
                                </span>
                                <Grid item xs={12}>
                                <FormControl fullWidth size={'small'} sx={{marginTop:'10px'}}>
                                    <InputLabel id="iRoles">Role</InputLabel>
                                    <Select
                                        labelId="role"
                                        id="role"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        label="Roles"
                                    >                                                                           
                                        {roles.items.map((rl: any) => <MenuItem key={rl.id} value={rl.id}>{rl.value}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid> 
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}