import * as React from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {styled} from "@mui/material";
import Loading from "../components/loading";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import {mutate} from "../lib/apollo";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function PhoneUpload() {
    const [loading, setLoading] = React.useState(false);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setLoading(true);
            const text = await file.text();
            let i = 0;
            const data = [];
            for (const line of text.split("\n")) {
                const phone = line.substring(0, 6);
                if (phone.length === 6 && phone !== '999999' && phone !== '000000') {
                   data.push(parseInt(phone));
                }

            }

            if (data && data.length > 200000) {
                const q = gql`
                    mutation m($input: [Int!]!) {
                        locationPhoneUpload(input: $input)
                    }
                    `;
                const r = await mutate(q, {input: data});

                if (r && r.locationPhoneUpload) {
                    toast.success("File uploaded");
                }
            } else {
                toast.error("Invalid file");
            }
            setLoading(false);
        }
    }

    return (
        <>
            <h2>Phone Upload</h2>

            <p>Upload FONE.DAT from FONEData_YYYYMM.zip purchased from Melissa Data</p>

            {loading ? <Loading/> :
                <>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                Upload file
                <VisuallyHiddenInput type="file" accept={".DAT"} onChange={handleChange} />
            </Button>
                </>
            }
        </>
    )
}
