import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Paper, Table,
    TableBody,
    TableCell,
    TableContainer, TableFooter,
    TableHead
} from "@mui/material";
import theme2 from "../../lib/theme";
import * as React from "react";
import {gql} from "@apollo/client";
import moment from "moment/moment";
import {query} from "../../lib/apollo";
import Loading from "../../components/loading";
import Box from "@mui/material/Box";
import CachedIcon from "@mui/icons-material/Cached";
import PieChart from "../../components/pieChart";
import Timeseries from "../../components/timeseries";
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import TableFooterRow from "../../components/TableFooterRow";


export default function Brokers(params: {dateRange: any, selectedPublishers: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([] as any[]);
    const reload = async () => {
        setLoading(true);

        const q = gql`
            query q($input: SalesByBrokerInput!) {
                salesByBroker(input: $input) {
                    value
                    valueUnscrubbed
                    brokerId
                    brokerName                    
                }
            }
        `;

        const variables = {
            input: {
                startDate: moment(params.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(params.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
                publisherIds: params.selectedPublishers.map((p: any) => p.id),
            }
        }

        const response = await query(q, variables);
        if (response && response.salesByBroker) {
            setData(response.salesByBroker);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (params.reload) {
            reload().then(() => {});
        }
    }, [params.reload]);

    let total = 0;
    let totalUnscrubbed = 0;
    let totalScrub = 0;
    if (data.length) {
        total = data.reduce((a, b) => a + b.value, 0);
        totalUnscrubbed = data.reduce((a, b) => a + b.valueUnscrubbed, 0);

        if (totalUnscrubbed) {
            totalScrub = ( 1 - total / totalUnscrubbed) * 100;
        }
    }

    return (
        <Card sx={{height: '365px', maxWidth: 'calc(100vw - 10px)'}}>
            <CardHeader title={"Brokers"} sx={{backgroundColor: theme2.palette.primary.main, color: theme2.palette.primary.contrastText, padding: '5px'}}
                        titleTypographyProps={{sx:{fontSize: '18px'}}}
                        action={
                            <IconButton aria-label="refresh" sx={{color: theme2.palette.primary.contrastText}} onClick={reload}>
                                <CachedIcon />
                            </IconButton>
                        }
            />
            <CardContent>
                {loading ? <Box sx={{marginTop: '130px'}}><Loading/></Box> : <>
                    <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 40px)', overflowY: 'auto', maxHeight: '290px'}}>
                        <Table size="small">
                            <TableHead>
                                <TableHeaderRow>
                                    <TableCell>Broker</TableCell>
                                    <TableCell align="right">Revenue</TableCell>
                                    <TableCell align="right">Unsucrubbed</TableCell>
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row) => (
                                    <StripedTableRow key={row.brokerId}>
                                        <TableCell sx={{whiteSpace: 'nowrap'}}>{row.brokerName} ({row.brokerId})</TableCell>
                                        <TableCell sx={{whiteSpace: 'nowrap'}} align="right">${row.value.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell sx={{whiteSpace: 'nowrap'}} align="right">${row.valueUnscrubbed.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} ({(( 1 - row.value / row.valueUnscrubbed) * 100).toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%)</TableCell>
                                    </StripedTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableFooterRow>
                                    <TableCell>Totals</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap'}} align="right">${total.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap'}} align="right">${totalUnscrubbed.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}  ({totalScrub.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%)</TableCell>
                                </TableFooterRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>}
            </CardContent>
        </Card>
    );
}
