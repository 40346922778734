import {Role} from './Role';

export enum PermissionType {
  Admin = 'Admin',
  CallCenterAdmin = 'CallCenterAdmin',
  PublisherStats = 'PublisherStats',
  ConversionStatsAdmin = 'ConversionStatsAdmin',
  ConversionStats = 'ConversionStats',
  RestrictedPubs = 'RestrictedPubs',
  AdminPubs = 'AdminPubs',
  HPCampaigns = 'HPCampaigns',
  HPCampaignsModify = 'HPCampaignsModify',
  FailedEvents = 'FailedEvents',
  BadProgramsZips = 'BadProgramsZips',
  BrokersSchools = 'BrokersSchools',
  LookupLead = 'LookupLead',
  ManagePublishers = 'ManagePublishers',
  DownloadByPublisher = 'DownloadByPublisher',
  Revenue = 'Revenue',
  Inventory = 'Inventory',
  InventoryAdmin = 'InventoryAdmin',
  RevenueAdmin = 'RevenueAdmin',
  TargusZero = 'TargusZero',
  Upload = 'Upload',
  Scoring = 'Scoring',
  ScoringAdmin = 'ScoringAdmin',
  HPEvents = 'HPEvents',
  Billables = 'Billables',
  BillablesAdmin = 'BillablesAdmin',
  CallCenter = 'CallCenter',
  HPCampaignsActive = 'HPCampaignsActive',
  Pacing = 'Pacing',
  Unknowns = 'Unknowns',
  HPCampaignsShowAll = 'HPCampaignsShowAll',
  BillablesShowAll = 'BillablesShowAll',
  CallCenterCEC = 'CallCenterCEC',
  HPCampaignsPM = 'HPCampaignsPM',
  CF = 'CF',
  CECStats = 'CECStats',
  PR = 'PR',
  CreativeApproval = 'CreativeApproval',
  GroupCaps = 'GroupCaps',
  CFRead = "CFRead",
  SEO = "SEO",
  SEOData = "SEOData",
  PublisherAPI = "PublisherAPI",
  Clickwall = "Clickwall",
}


export interface UserRead {
  id: number;
  name: string;
  email: string;
  active: boolean;
  role: Role;
  permissions: PermissionType[];
}
