import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Menu,
    MenuItem
} from "@mui/material";
import Button from "@mui/material/Button";
import LoadingDialog from "../../../components/loadingDialog";
import TextField from "@mui/material/TextField";
import MultiSelect from "../../../components/multiSelect/multiSelect";
import {addBlockedPubs, searchBrokers, searchPubs} from "../blockedPublishersService";
import {toast} from "react-toastify";
import {useEffect} from "react";

export default function BlockedPublisherAddComponent (params: {addPub: boolean, setAddPub: any, reload: any}) {
    const [loading, setLoading] = React.useState(false);
    const [comments, setComments] = React.useState('');
    const [selectedPublishers, setSelectedPublishers] = React.useState([] as any[]);
    const [selectedBrokers, setSelectedBrokers] = React.useState([] as any[]);

    useEffect(() => {
        setSelectedPublishers([]);
        setSelectedBrokers([]);
        setComments('');
    }, [params.addPub]);
    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setAddPub(false);
    };

    const handleAdd = async () => {
        if (selectedPublishers.length === 0) {
            toast.error("Please select at least one publisher");
            return;
        }

        if (selectedBrokers.length === 0) {
            toast.error("Please select at least one broker");
            return;
        }
        setLoading(true);

        const response = await addBlockedPubs(selectedPublishers, selectedBrokers, comments);
        if (response) {
            toast.success(`${selectedPublishers[0].value}${selectedPublishers.length > 1 ? ` +${selectedPublishers.length - 1} more publishers are` : ' is'} now blocked from
            ${selectedBrokers[0].value}${selectedBrokers.length > 1 ? ` +${selectedBrokers.length - 1} more brokers` : ''}`);
            handleClose();
            params.reload();
        }

        setLoading(false);

    }

    if (!params.addPub) return <> </>;

    return (
        <>

            <Dialog
                open={params.addPub}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"sm"}
                fullWidth={true}
                sx={{
                    "& .MuiDialog-container .MuiDialog-paper": {
                    margin: "24px 0px",
                }}}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Create Blocked Publishers"}
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth variant="standard"
                                 sx={{marginBottom: "10px"}}
                    >
                        <MultiSelect
                            searchFunction={searchPubs}
                            selectedItems={selectedPublishers}
                            setSelectedItems={setSelectedPublishers}
                            placeholder={"Search Publishers"}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="standard"
                                 sx={{marginBottom: "10px"}}
                    >
                        <MultiSelect
                            searchFunction={searchBrokers}
                            selectedItems={selectedBrokers}
                            setSelectedItems={setSelectedBrokers}
                            placeholder={"Search Brokers"}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            placeholder="Comments"
                            multiline
                            value={comments}
                            onChange={(e: any) => setComments(e.target.value)}
                            rows={3}
                        />
                    </FormControl>

                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Cancel</Button>
                        <Button sx={{color: 'green'}} onClick={handleAdd} autoFocus>
                            Create
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}
