import React from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

const options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: undefined,
        align: 'left'
    },
    tooltip: {
        headerFormat: '',
        pointFormat: '{point.name}: <b>{point.y:,0.f}</b>',
        footerFormat: '',
    },
    accessibility: {
        point: {
            valueSuffix: ''
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b style="color:#999">{point.name}</b>:<Br> {point.y:,.0f}'
            },
            showInLegend: true
        }
    },
    credits: {
        enabled: false
    },
    series: [] as any[]
}
export default function PieChart({height, series}: {height: number, series: any[]}) {

    options.series = series;

    return (
        <HighchartsReact
            containerProps={{ style: { height: height + "px" } }}
            highcharts={Highcharts}
            options={options}
        />
    );
}
