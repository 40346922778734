import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView from "@mui/lab/TreeView";
import Loading from "../../components/loading";
import {FormControl, IconButton, Input, InputAdornment, SpeedDial, SpeedDialIcon} from "@mui/material";
import useDebounce from "../../lib/UseDebounce";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import BlockedPublisherDeleteComponent from "./components/blockedPublisherDelete";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {downloadPubs, getAllPubs, getFilteredPubs, getPubGroups} from "./blockedPublishersService";
import TreeRow from "./components/blockedPublisherTreeRow";
import {TreeItem} from "@mui/lab";
import BlockedPublisherAddComponent from "./components/blockedPublisherAdd";
import PubCard from "../../components/pubCard/pubCard";
import theme from "../../lib/theme";
import Button from "@mui/material/Button";

export default function BlockedPublishersPage() {
    const [pubs, setPubs] = React.useState([]);
    const [groups, setGroups] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [search, setSearch] = React.useState('');
    const [pubToDelete, setPubToDelete] = React.useState(null);
    const [addPub, setAddPub] = React.useState(false);

    const groupPubsByBrokerId = (pubs: any[]) => {
        const groups = getPubGroups(pubs);

        setGroups(groups);
        setTimeout(() => {
            setLoading(false);
        }, 1000);

    }

    const reload = () => {
        setLoading(true);
        getAllPubs().then(x=> {
            setPubs(x);
        });
    }

    React.useEffect(() => {
        reload();
        document.title = "Blocked Publishers | DN";
    }, []);

    useDebounce(() => {
        const filtered = getFilteredPubs(search, pubs);
        groupPubsByBrokerId(filtered);
        }, [pubs, search], 400
    );

    return (
        <>
            <h2>Blocked Publishers</h2>

            {loading ? <Loading/> :
                <>
                    <Box sx={{display: 'flex'}}>
                        <FormControl fullWidth variant="standard">
                            <Input
                                id="search"
                                type="text"
                                spellCheck="false"
                                placeholder="Search"
                                value={search || ''}
                                onChange={(e: any) => setSearch(e.target.value)}
                                startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                                sx={{maxWidth: '200px'}}
                            />
                        </FormControl>

                        <Button aria-label="download" onClick={() => downloadPubs(groups)} variant={'contained'} color={'success'}>
                            <FileDownloadIcon />&nbsp;Export
                        </Button>
                    </Box>
                <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ flexGrow: 1, overflowY: 'auto', py: 2 }}
            >
                {groups.map((group: any ) => {
                    return (
                        <TreeItem key={group.brokerId} nodeId={`${group.brokerId}-0`} label={`${group.brokerName} (${group.count})`}>
                            {group.pubs.map((pub: any, index2: number) => {
                                return (
                                    <TreeItem key={pub.publisherId} nodeId={`${group.brokerId}-${pub.publisherId}`} label={<TreeRow pub={pub} setPubToDelete={setPubToDelete}/>} />
                                )
                            })}
                        </TreeItem>
                    )
                })}
            </TreeView>
                </>
            }

            <BlockedPublisherDeleteComponent pubToDelete={pubToDelete} setPubToDelete={setPubToDelete} pubs={pubs} setPubs={setPubs}/>
            <BlockedPublisherAddComponent addPub={addPub} setAddPub={setAddPub} reload={reload}/>
            <SpeedDial
                open={false}
                onClick={() => setAddPub(true)}
                ariaLabel="Add Blocked Publisher"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon/>}
            />
        </>
    )
}
