import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import downloadCsv from "../../lib/downloadCsv";
import moment from "moment";

export async function searchBrokers(isActive: boolean, limit: number, search: string, sortColumn: string, sortAsc: boolean) {
    const q = gql`
      query q ($input: BrokersInput!) {
        brokers(input: $input) {
            count
            items {
                active
                groupId
                id
                name
                rating
                timeZone
                nonPaymentPaused
                creatives
            }
        }
      } 
    `;

    const variables = {
        input: {
            active: isActive,
            limit,
            skip: 0,
            search,
            sort: [{column: sortColumn, isAsc: sortAsc}]
        }
    };

    const response = await query(q, variables);
    if (!response || !response.brokers) {
        return {count: 0, brokers: []};
    }

    return {count: response.brokers.count, brokers: response.brokers.items};
}

export async function getTimeZoneOptions() {
    const q = gql`
      query q {
        globals {
            timeZones {
                count
                items {
                    pstOffset
                    timeZone                    
                }
            }
        }
    } 
    `;

    const response = await query(q, "");
    if (!response || !response.globals.timeZones) {
        return {count: 0, items: []};
    }

    return {count: response.globals.timeZones.count, timeZones: response.globals.timeZones.items};
}

export function downloadBrokers(isActive: boolean, search: string, sortColumn: string, sortAsc: boolean) {

    searchBrokers(isActive, 10000, search, sortColumn, sortAsc).then((response) => {
        const content = [];
        const header = [];

        header.push('Name');
        header.push('Id');
        header.push('Rating');
        header.push('Time Zone');
        header.push('Non-Payment Paused');
        header.push('Approved Creatives Only');

        content.push(header);

        let row = [];
        response.brokers.forEach((r: any) => {
            row = [];
            row.push(r.name);
            row.push(r.id);
            row.push(r.rating);
            row.push(r.timeZone);
            row.push(r.nonPaymentPaused ? "Yes" : "");
            row.push(r.creatives ? "Yes" : "");
            content.push(row);
        });

        downloadCsv('brokers.csv', content).then(() => {});
    });
}
