import * as React from "react";
import {useAuth} from "../../providers/auth";
import {RoleType} from "../../objects/Role";
import {Card, CardContent, CardHeader, FormControl, Grid, Link} from "@mui/material";
import theme2 from "../../lib/theme";
import DateRange, {RangeToTimes} from "../../components/DateRange";
import Loading from "../../components/loading";
import Box from "@mui/material/Box";
import MultiSelect from "../../components/multiSelect/multiSelect";
import {searchPubs} from "../blockedPublishers/blockedPublishersService";
import Button from "@mui/material/Button";
import CachedIcon from '@mui/icons-material/Cached';
import {gql} from "@apollo/client";
import moment from "moment";
import {query} from "../../lib/apollo";
import Uptime from "./uptime";
import Inventory from "./inventory";
import InventoryTimeseries from "./inventoryTimeseries";
import RevenueTimeseries from "./revenueTimeseries";
import Settings from "./settings";
import Brokers from "./brokers";
import Publishers from "./publishers";
import DashboardTabs from "./shared/dashboardTabs";

export default function DashboardPage() {
    let auth = useAuth();
    const [dateRange, setDateRange] = React.useState([] as any);
    const [selectedPublishers, setSelectedPublishers] = React.useState([] as any[]);
    const [reload, setReload] = React.useState(0);

    React.useEffect(() => {
        document.title = "Edu Dashboard | DN";
    }, []);

    React.useEffect(() => {
        if (auth.user && auth.user.role.type === RoleType.ADMIN) {
            updateDateRange(RangeToTimes("Today")).then(() => {
            });
        }
    }, []);

    const updateDateRange = async (value: any) => {
        setDateRange(value);
        setReload(Math.random());
    }

    return (
        <>
            <h2><DashboardTabs selected={"Edu"}/></h2>

            {auth.user && auth.user.role.type === RoleType.ADMIN &&
                <>
                    <Box sx={{display: 'flex', marginBottom: 2}}>
                        <DateRange showTime={false} dateRange={dateRange} setDateRange={updateDateRange} style={{marginLeft: 'auto'}} placement={'bottomEnd'}/>
                        <FormControl variant="standard"
                                     sx={{marginLeft: "5px", width: window.innerWidth < 768 ? '135px' : '200px'}}
                        >
                            <MultiSelect
                                searchFunction={searchPubs}
                                selectedItems={selectedPublishers}
                                setSelectedItems={setSelectedPublishers}
                                placeholder={"Search Publishers"}
                            />
                        </FormControl>

                        <Button aria-label="download" onClick={() => {updateDateRange(dateRange)}} variant={'contained'} color={'success'} sx={{marginLeft: '5px', minWidth: '40px'}} size={"small"}>
                            <CachedIcon sx={{fontSize: '20px'}} />
                        </Button>
                    </Box>

                    <Grid container spacing={3}>
                        <Grid md={9} item sx={{width: '100%'}}>
                            <RevenueTimeseries dateRange={dateRange} selectedPublishers={selectedPublishers} reload={reload}/>
                        </Grid>
                        <Grid md={3} item sx={{width: '100%'}}>
                            <Inventory dateRange={dateRange} selectedPublishers={selectedPublishers} reload={reload}/>
                        </Grid>
                        <Grid md={12} item sx={{width: '100%'}}>
                            <Grid container spacing={3}>
                                <Grid md={5} item sx={{width: '100%'}}>
                                    <InventoryTimeseries selectedPublishers={selectedPublishers} reload={reload}/>
                                </Grid>
                                <Grid md={5} item sx={{width: '100%'}}>
                                    <Brokers selectedPublishers={selectedPublishers} reload={reload} dateRange={dateRange}/>
                                </Grid>
                                <Grid md={2} item sx={{width: '100%'}}>
                                    <Settings reload={reload}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid md={12} item sx={{width: '100%'}}>
                            <Publishers selectedPublishers={selectedPublishers} reload={reload} dateRange={dateRange}/>
                        </Grid>
                    </Grid>



                </>
            }
        </>
    )
}
