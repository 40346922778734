import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import SingleSelect from "../../components/SingleSelect";
import {getBillingGroup, getDupeGroup} from "../schools/schoolsService";
import LoadingDialog from "../../components/loadingDialog";
import Button from "@mui/material/Button";
import {gql} from "@apollo/client";
import {mutate} from "../../lib/apollo";
import {toast} from "react-toastify";

export default function SeoRankingSeal (params: {ranking: any, setRanking: any, reload: any, year: number}) {
    const [loading, setLoading] = React.useState(true);
    const [header, setHeader] = React.useState('' as string);
    const [line1, setLine1] = React.useState('' as string);
    const [line2, setLine2] = React.useState('' as string);
    const [line3, setLine3] = React.useState('' as string);

    React.useEffect(() => {
        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (params.ranking == null) return;

        setHeader(params.ranking.seal.header);
        setLine1(params.ranking.seal.line1);
        setLine2(params.ranking.seal.line2);
        setLine3(params.ranking.seal.line3);
    }, [params.ranking]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setRanking(null);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        const input = {
            header,
            line1,
            line2,
            line3
        };

            q = gql`
                mutation m($id: Int!, $input: SeoSealWrite!) {
                    seoRankingSealUpdate(id: $id, input: $input)
                }
            `;

            variables = {
                id: params.ranking.id,
                input
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.seoRankingSealUpdate;


        if (good) {
            toast.success('Ranking seal updated successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }
    }

    if (!params.ranking) return <> </>;


    return (
        <>
            <Dialog
                open={!!params.ranking}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    Seal: {params.ranking.name}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="name"
                                label="Header"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={header}
                                onChange={(e) => setHeader(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="name"
                                label="Line 1"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={line1}
                                onChange={(e) => setLine1(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="name"
                                label="Line 2"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={line2}
                                onChange={(e) => setLine2(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="name"
                                label="Line 3"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={line3}
                                onChange={(e) => setLine3(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} sx={{textAlign: 'center'}}>
                            <img src={`https://seal.universityhq.org/sandbox?header=${header}&footer=${params.year}&line1=${encodeURIComponent(line1)}&line2=${encodeURIComponent(line2)}&line3=${encodeURIComponent(line3)}`}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            Update
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}
