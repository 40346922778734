import * as React from "react";
import {Button, Link} from "@mui/material";
import {useAuth} from "../../../providers/auth";
import {RoleType} from "../../../objects/Role";
export default function dashboardTabs(params: {selected: string}) {
    let auth = useAuth();

    return (
        <>
        {auth.user && auth.user.role.type === RoleType.ADMIN &&
                <>
                    <Button component={Link} variant={params.selected === 'Edu' ? 'contained' : 'outlined'} size="small"
                            sx={{margin: '0 5px'}} href={`/`}
                            color={params.selected === 'Edu' ? 'warning' : 'info'}>Edu</Button>
                    <Button component={Link} variant={params.selected === 'Windows' ? 'contained' : 'outlined'}
                            size="small" sx={{margin: '0 5px'}} href={`/windows`}
                            color={params.selected === 'Windows' ? 'warning' : 'info'}>Win</Button>
                    <Button component={Link} variant={params.selected === 'Legal' ? 'contained' : 'outlined'}
                            size="small" sx={{margin: '0 5px'}} href={`/legal`}
                            color={params.selected === 'Legal' ? 'warning' : 'info'}>Legal</Button>
                    <Button component={Link} variant={params.selected === 'Auto' ? 'contained' : 'outlined'}
                            size="small" sx={{margin: '0 5px'}} href={`/auto`}
                            color={params.selected === 'Auto' ? 'warning' : 'info'}>Auto</Button>
                    <Button component={Link} variant={params.selected === 'Thumb' ? 'contained' : 'outlined'}
                            size="small" sx={{margin: '0 5px'}} href={`/tt`}
                            color={params.selected === 'Thumb' ? 'warning' : 'info'}>Thumb</Button>
                </>
        }
        </>
    );
}
