import * as React from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {styled} from "@mui/material";
import Loading from "../components/loading";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import {mutate} from "../lib/apollo";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function ZipUpload() {
    const [loading, setLoading] = React.useState(false);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setLoading(true);
            const text = await file.text();
            let i = 0;
            const data = [];
            for (const line of text.split("\n")) {
                const row = line.split(",");
                if (i === 0 && (row[0] !== 'ZIP' || row[8] !== 'DominantAreaCode')) {
                    toast.error("Invalid file");
                    break;
                } else if (i > 0) {
                    if (row[0].length === 5 && row[0] !== '99999' && row[10] === 'L' && row[8].trim() !== "") {
                        data.push({z: row[0], s: row[1], c: row[2], la: parseFloat(row[4]), lo: parseFloat(row[5]), a: parseFloat(row[8])});
                    }
                }

                i++;
            }

            if (data && data.length > 40000) {
                const q = gql`
                    mutation m($input: [ZipStateWrite!]!) {
                        locationZipStateUpload(input: $input)
                    }
                    `;
                const r = await mutate(q, {input: data});

                if (r && r.locationZipStateUpload) {
                    toast.success("File uploaded");
                }
            } else {
                toast.error("Invalid file");
            }
            setLoading(false);
        }
    }

    return (
        <>
            <h2>Zip Upload</h2>

            <p>Upload ZipData2.csv from ZD2_YYYYMM.zip purchased from Melissa Data</p>

            {loading ? <Loading/> :
                <>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                Upload file
                <VisuallyHiddenInput type="file" accept={".csv"} onChange={handleChange} />
            </Button>
                </>
            }
        </>
    )
}
