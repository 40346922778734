import * as React from "react";
import {gql} from "@apollo/client";
import {mutate, query} from "../lib/apollo";
import {UserRead} from "../objects/UserRead";
import {useEffect} from "react";
import {deleteCookie, getCookie, setCookie} from "../lib/cookies";

interface AuthContextType {
    user: UserRead | null;
    signIn: (email: string, password: string) => Promise<boolean>;
    signInAs: (userId: number) => Promise<boolean>;
    signOut: (saveFrom: boolean) => void;
    self: () => Promise<boolean>;
    loading: boolean;
    saveFrom: boolean;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    let [user, setUser] = React.useState<any>(null);
    let [loading, setLoading] = React.useState<boolean>(true);
    let [saveFrom, setSaveFrom] = React.useState<boolean>(true);

    let signIn = async (email: string, password: string) => {
        const query =
        gql`
            mutation Login($email: String!, $password: String!) {
                userLogin(input: {email: $email, password: $password}) {
                    sessionId
                    user {
                        id
                        name
                        email
                        active
                        role {
                            id
                            type
                            value
                        }
                        permissions
                    }
                }
            }
        `;

        const variables = {
            email,
            password
        }
        const response = await mutate(query, variables);

        if (response && response.userLogin && response.userLogin.user) {
            setCookie("session_id", response.userLogin.sessionId, 0)
            setUser(response.userLogin.user);
            return true;
        }
        return false;
    };

    let signInAs = async (userId: number) => {
        const query = gql`
            mutation m($userId: Int!) {
                userLoginAs(userId: $userId) {
                    sessionId
                    user {
                        id
                        name
                        email
                        active
                        role {
                            id
                            type
                            value
                        }
                        permissions
                    }
                }
            }
        `;

        const variables = {
            userId,
        }

        const response = await mutate(query, variables);
        if (response && response.userLoginAs) {
            setCookie("session_id", response.userLoginAs.sessionId, 0);
            setUser(response.userLoginAs.user);
            window.history.pushState({}, "Dashboard", "/dashboard");
            window.location.reload();
            return true;
        }
        return false;
    }

    let signOut = async (saveFrom: boolean) => {
        const query =
        gql`
            mutation {
                selfLogout
            }
        `;

        await mutate(query, null);

        deleteCookie("session_id");
        setUser(null);
        setSaveFrom(saveFrom);
    }

    let self = async (): Promise<boolean> => {
        const q =
        gql`
            #NO_NETWORK_ERROR
            query {
                self {
                    id
                    name
                    email
                    active
                    role {
                        id
                        type
                        value
                    }
                    permissions
                }
            }
        `;

        const response = await query(q, null);

        if (response && response.self) {
            setUser(response.self);
            return true;
        }

        if (response && response.accessDenied) {
            setUser(null);
            return false
        }

        return true;
    };

    let value: AuthContextType = { user, signIn, signInAs, signOut, self, loading, saveFrom  };


    useEffect(() => {
        if (!getCookie("session_id")) {
            setLoading(false);
            return;
        }
        value.self().then(()=>{
            setLoading(false);
        });
    }, []);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}
