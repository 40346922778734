import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid
} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import TextField from "@mui/material/TextField";
import {getDupeGroup, getBillingGroup} from "./schoolsService";
import SingleSelect from "../../components/SingleSelect";

export default function SchoolUpsert (params: {school: any, setSchool: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [name, setName] = React.useState('' as string);
    const [dupeGroup, setDupeGroup] = React.useState({id: '', value: ''} as any);
    const [billingGroup, setBillingGroup] = React.useState({id: '', value: ''} as any);

    React.useEffect(() => {
            setLoading(false);
    }, []);

    React.useEffect(() => {
        if (params.school) {
            if (params.school.name) {
                setName(params.school.name || '');
            } else {
                setName('');
            }

            if (params.school.dupeGroup) {
                setDupeGroup({"id":params.school.dupeGroup.id, "value":params.school.dupeGroup.name + " (" + params.school.dupeGroup.id + ")"});
            } else {
                setDupeGroup(undefined);
            }

            if (params.school.billingGroup) {
                setBillingGroup({"id":params.school.billingGroup.id, "value":params.school.billingGroup.name + " (" + params.school.billingGroup.id + ")"});
            } else {
                setBillingGroup(undefined);
            }
        }
    }, [params.school]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setSchool(null);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $input: SchoolUpdateInput!) {
                    schoolUpdate(id: $id, input: $input)
                }
            `;

            variables = {
                id: params.school.id,
                input: {
                    name,
                    billingGroupId: billingGroup.id,
                    dupeGroupId: dupeGroup.id,
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.schoolUpdate;
        } else {
            q = gql`
                mutation m($input: SchoolCreateInput!) {
                    schoolCreate(input: $input) {
                        id
                    }
                }
            `;

            variables = {
                input: {
                    name,
                    billingGroupId: billingGroup.id,
                    dupeGroupId: dupeGroup.id
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.schoolCreate && res.schoolCreate.id;
        }

        if (good) {
            toast.success(isEdit ? 'School updated successfully' : 'School created successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }

    }

    if (!params.school) return <> </>;

    const isEdit = !!params.school.id;

    return (
        <>
                <Dialog
                    open={!!params.school}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'sm'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {isEdit ? `Update School ${params.school.name}` : 'Create School'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    size={'small'}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                Dupe Group:
                                <SingleSelect searchFunction={getDupeGroup} selectedItem={dupeGroup} setSelectedItem={setDupeGroup} placeholder={"Select Dupe Group"}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                Billing Group:
                                <SingleSelect searchFunction={getBillingGroup} selectedItem={billingGroup} setSelectedItem={setBillingGroup} placeholder={"Select Billing Group"}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
