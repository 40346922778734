import {ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {toast} from "react-toastify";
import {setContext} from "@apollo/client/link/context";
import {getCookie} from "./cookies";
// import ApolloLinkTimeout from 'apollo-link-timeout';

let uri = process.env.REACT_APP_API_URL;

if (location.hostname.includes("gitpod")) {
    uri = "https://" + location.hostname.replace("3001", "3000") + "/dev/graphql";
}

const httpLink = createHttpLink({
    uri
});

// const timeoutLink = new ApolloLinkTimeout(10000);

const errorLink = onError(({ graphQLErrors, networkError, operation}) => {
    if (networkError)  {
        const body = operation.query.loc?.source.body || "";
        if (!body.includes("NO_NETWORK_ERROR")) {
            toast.error('Unable to reach our servers. Please check your connection');
        }
    }
});


const errorMiddleware = new ApolloLink((operation, forward) => {
    return forward(operation).map((response: any) => {
        let err = null;

        if (response.errors && response.errors.length > 0) {
            err = response.errors[0];
            let msg = "Unexpected Error. Please try again";
            if (err.extensions && err.extensions.exception && err.extensions.exception.isFriendly) {
                msg = err.message;
            }
            else  if (err.extensions && err.extensions.exception && err.extensions.exception.accessDenied) {
                const body = operation.query.loc?.source.body || "";
                if (!body.includes("self {")) {
                    msg = err.message;
                } else {
                    return response;
                }

            } else {
                console.error(err.message)
            }
            toast.error(msg);
        }

        return response;
    });
});

const authMiddleware = setContext(async (req, { headers }) => {
    const token = getCookie("session_id")

    return {
        headers: {
            ...headers,
            Authorization: token
        },
    };
});

const cache = new InMemoryCache();

export const client = new ApolloClient({
    cache,
    link: from([errorLink, authMiddleware, errorMiddleware, httpLink]),
});

export async function mutate(query: any, variables: any, refetchQueries?: any): Promise<any> {
    try {
        const data: any = await client.mutate({
                mutation: query,
                variables,
                refetchQueries
            },
        );

        return data.data;
    } catch (err) {
        return null;
    }
}

export async function query(query: any, variables: any): Promise<any>
{
    try {
        const data: any = await client.query({
                query,
                variables,
                fetchPolicy: 'network-only'
            },
        );

        return data.data;
    } catch (err: any) {
        const body = query.loc?.source.body || "";
        if (body.includes("self {") && err.toString().includes("Access Denied")) {
            return {accessDenied: true}
        }

        return null;
    }
}
