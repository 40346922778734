import * as React from 'react';
import {gql} from "@apollo/client";
import {mutate, query} from "../../lib/apollo";
import Loading from "../loading";
import {
    Alert,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    ListItem,
    MenuItem,
    Paper,
    Select, Table, TableBody,
    TableCell, TableContainer, TableFooter, TableHead,
    TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import StripedTableRow from "../StripedTableRow";
import theme from "../../lib/theme";
import TableFooterRow from "../TableFooterRow";
import TableHeaderRow from "../TableHeaderRow";
import Timeseries from "../timeseries";
import theme2 from "../../lib/theme";
import LineGraph from "../lineGraph";

export default function PubStats(params: {pubId: number, stats: any, setStats: any}) {
    const reload = async (db: number, mb: number) => {
       params.setStats({...params.stats, daysBack: db, monthsBack: mb, loading: true});
        const q = gql`
            query Query($input: PublisherStatsCriteria!) {
                publisherStats(input: $input) {
                    daily {
                        date
                        q99
                        q100
                        hpPixels
                        cfPixels
                        cfClicks
                        cakeClicks
                        cakeConversions
                        epc
                        q100PerCakeClick
                        cakeConversionRate
                    }
                    monthly {
                        date
                        q99
                        q100
                        hpPixels
                        cfPixels
                        cfClicks
                        cakeClicks
                        cakeConversions
                        epc
                        q100PerCakeClick
                        cakeConversionRate
                    }
                }
            }            
        `

       const variables = {
            input: {
                publisherId: params.pubId,
                daysBack: db,
                monthsBack: mb,
            }
        }

        const response = await query(q, variables);

        if (response && response.publisherStats) {
            const series = [] as any[];
            const categories = [] as any[];

            if (response.publisherStats && response.publisherStats.daily && response.publisherStats.daily.length) {

                const colors = ['#b20000',
                    '#a0607e',
                    '#a0609c',
                    '#6960a0',
                    '#5f7aa8',
                    '#5f97a8',
                    '#0087b0',
                    '#01b1a5',
                    '#7ab294',
                    '#00b218',
                    '#000000',
                    '#6d6d6d',
                    '#6cb103',
                    '#99b273',
                    '#a9b273',
                    '#95b200',
                    '#b29b00',
                    '#b27d00',
                    '#b19b66',
                    '#b18966',
                    '#b25300'];

                const p100 = {name: "100s", data: [] as any, color: "#b29b00", visible: false};
                const pCClk = {name: "CF Clk", data: [] as any, color: "#95b200", visible: false};
                const pCakeClk = {name: "Cake Clk", data: [] as any, color: "#a0609c", yAxis: 2, visible: false};
                const pCakeConv = {name: "Cake Conv", data: [] as any, color: "#6960a0", visible: false};
                const pEPC = {name: "EPC", data: [] as any, color: "#b20000", yAxis: 1};
                const p100PerCakeClk = {name: "100/Cake Clk", data: [] as any, color: "#0087b0", visible: false};
                const pCakeConvRate = {name: "Cake Conv Rate", data: [] as any, color: "#7ab294", visible: false};

                // loop in reverse:
                for (let i = response.publisherStats.daily.length - 1; i >= 0; i--) {
                    const row = response.publisherStats.daily[i];
                    categories.push(row.date);
                    p100.data.push([row.q100]);
                    pCClk.data.push([row.cfClicks]);
                    pCakeClk.data.push([row.cakeClicks]);
                    pCakeConv.data.push([row.cakeConversions]);
                    pEPC.data.push([row.epc]);
                    p100PerCakeClk.data.push([row.q100PerCakeClick * 100]);
                    pCakeConvRate.data.push([row.cakeConversionRate * 100]);
                }
                series.push(p100);
                series.push(pCClk);
                series.push(pCakeClk);
                series.push(pCakeConv);
                series.push(pEPC);
                series.push(p100PerCakeClk);
                series.push(pCakeConvRate);
            }

            params.setStats({...params.stats, daysBack: db, monthsBack: mb, data: response.publisherStats, loading: false, series, categories});
        } else {
            params.setStats({...params.stats, daysBack: db, monthsBack: mb, loading: false});
        }

        //
    }

    React.useEffect(() => {
        if (!params.stats.data) {
            reload(params.stats.daysBack, params.stats.monthsBack).then(() => {});
        }
    }, []);

    const rows = [];
    for (let i = 0; i <= 90; i++) {
        rows.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }

    const rows2 = [];
    for (let i = 0; i <= 24; i++) {
        rows2.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }

    if (!params.pubId) {
        return <></>;
    }


    const yAxisRight = {
        title: {
            text: ''
        },
        opposite: true
    };

    const yAxisLeft2 = {
        title: {
            text: ''
        },
        opposite: false
    };

    return (
        <>
            {params.stats.loading ? <Box sx={{padding: 3}}><Loading/></Box> :
                <>
                    <Grid container spacing={2} sx={{padding: '10px'}}>
                        <Grid item xs={6}>
                            <FormControl fullWidth size={"small"}>
                                <InputLabel id="demo-simple-select-label">Days Back</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={params.stats.daysBack}
                                    label="Days Back"
                                    onChange={(event) => {params.setStats({...params.stats, daysBack: event.target.value as number}); reload(event.target.value as number, params.stats.monthsBack).then(() => {})}}
                                    disabled={params.stats.loading}
                                >{rows}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size={"small"}>
                                <InputLabel id="demo-simple-select-label2">Month Back</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    value={params.stats.monthsBack}
                                    label="Month Back"
                                    onChange={(event) => {params.setStats({...params.stats, monthsBack: event.target.value as number}); reload(params.stats.daysBack, event.target.value as number).then(() => {})}}
                                    disabled={params.stats.loading}
                                >{rows2}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {!!params.stats.data && !params.stats.data.daily.length && !params.stats.data.monthly.length && <Alert severity="warning">No results found</Alert>}

                    {!!params.stats.data && (!!params.stats.data.daily.length || !!params.stats.data.monthly.length) &&
                        <>
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 20px)', margin: '10px'}}>
                        <Table size="small">
                            <TableHead>
                                <TableHeaderRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell align="right">99s</TableCell>
                                    <TableCell align="right">100s</TableCell>
                                    <TableCell align="right">HP Pxl</TableCell>
                                    <TableCell align="right">CF Pxl</TableCell>
                                    <TableCell align="right">CF Clk</TableCell>
                                    <TableCell align="right">Cake Clk</TableCell>
                                    <TableCell align="right">Cake Conv</TableCell>
                                    <TableCell align="right">EPC</TableCell>
                                    <TableCell align="right">100/Cake Clk</TableCell>
                                    <TableCell align="right">Cake Conv</TableCell>
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody>
                                {params.stats.data.daily.map((row: any) => (
                                    <StripedTableRow key={row.date}>
                                        <TableCell sx={{whiteSpace: 'nowrap'}}>{row.date}</TableCell>
                                        <TableCell align="right">{row.q99.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.q100.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.hpPixels.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.cfPixels.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.cfClicks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.cakeClicks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.cakeConversions.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">${row.epc.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right">{(row.q100PerCakeClick * 100).toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</TableCell>
                                        <TableCell align="right">{(row.cakeConversionRate * 100).toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</TableCell>
                                    </StripedTableRow>))}
                                <SummaryRow data={params.stats.data.daily} title={"Daily"} />
                                {params.stats.data.monthly.map((row: any) => (
                                    <StripedTableRow key={row.date}>
                                        <TableCell sx={{whiteSpace: 'nowrap'}}>{row.date}</TableCell>
                                        <TableCell align="right">{row.q99.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.q100.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.hpPixels.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.cfPixels.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.cfClicks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.cakeClicks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">{row.cakeConversions.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                        <TableCell align="right">${row.epc.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right">{(row.q100PerCakeClick * 100).toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</TableCell>
                                        <TableCell align="right">{(row.cakeConversionRate * 100).toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</TableCell>
                                    </StripedTableRow>))}

                                <SummaryRow data={params.stats.data.monthly}  title={"Monthly"} />

                            </TableBody>
                        </Table>

                        </TableContainer>
                            <LineGraph height={260} series={params.stats.series} title={""} prefix={""} suffix={""} decimalPlaces={0} yAxisRight={yAxisRight} yAxisLeft2={yAxisLeft2} categories={params.stats.categories}/>
                        </>
                                }
                </>}
        </>
    )

}

const SummaryRow = function(props: {data: any[], title: string}) {
    const cl = props.data.reduce((a: any, b: any) => a + b.cakeClicks, 0);
    const conv = props.data.reduce((a: any, b: any) => a + b.cakeConversions, 0);
    const q100 = props.data.reduce((a: any, b: any) => a + b.q100, 0);

    let epcs = props.data.reduce((a: any, b: any) => a + (b.epc * b.cakeClicks), 0);

    if (cl > 0) {
        epcs = epcs / cl;
    } else {
        epcs = 0;
    }

    return (
        <TableFooterRow>
            <TableCell><strong>{props.title}</strong></TableCell>
            <TableCell align="right"><strong>{props.data.reduce((a: any, b: any) => a + b.q99, 0).toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</strong></TableCell>
            <TableCell align="right"><strong>{q100.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</strong></TableCell>
            <TableCell align="right"><strong>{props.data.reduce((a: any, b: any) => a + b.hpPixels, 0).toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</strong></TableCell>
            <TableCell align="right"><strong>{props.data.reduce((a: any, b: any) => a + b.cfPixels, 0).toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</strong></TableCell>
            <TableCell align="right"><strong>{props.data.reduce((a: any, b: any) => a + b.cfClicks, 0).toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</strong></TableCell>
            <TableCell align="right"><strong>{props.data.reduce((a: any, b: any) => a + b.cakeClicks, 0).toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</strong></TableCell>
            <TableCell align="right"><strong>{conv.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</strong></TableCell>
            <TableCell align="right"><strong>${epcs.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</strong></TableCell>
            <TableCell align="right"><strong>{((cl ? q100 / cl : 0) * 100).toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</strong></TableCell>
            <TableCell align="right"><strong>{((cl ? conv / cl : 0) * 100).toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</strong></TableCell>
        </TableFooterRow>
    )
}
