import {styled, TableRow} from "@mui/material";

const StripedTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "white",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "#f9f9f9",
    },
}));

export default StripedTableRow;
