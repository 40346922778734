import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import SingleSelect from "../../components/SingleSelect";
import {getBillingGroup, getDupeGroup} from "../schools/schoolsService";
import LoadingDialog from "../../components/loadingDialog";
import Button from "@mui/material/Button";
import {gql} from "@apollo/client";
import {mutate} from "../../lib/apollo";
import {toast} from "react-toastify";

export default function SeoRankingUpsert (params: {ranking: any, setRanking: any, reload: any, year: number}) {
    const [loading, setLoading] = React.useState(true);
    const [slug, setSlug] = React.useState('' as string);
    const [name, setName] = React.useState('' as string);
    const [numberOfSchools, setNumberOfSchools] = React.useState(50);
    const [url, setUrl] = React.useState('' as string);
    const [headline, setHeadline] = React.useState('' as string);
    const [adDegree, setAdDegree] = React.useState('' as string);
    const [adCategory, setAdCategory] = React.useState('' as string);
    const [adSubject, setAdSubject] = React.useState('' as string);

    React.useEffect(() => {
        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (params.ranking) {
            if (params.ranking.name) {
                setName(params.ranking.name || '');
                setSlug(params.ranking.slug || '');
                setNumberOfSchools(params.ranking.number_schools || 50);
                setUrl(params.ranking.url || '');
                setHeadline(params.ranking.headline || '');
                setAdDegree(params.ranking.ad_degree || '');
                setAdCategory(params.ranking.ad_category || '');
                setAdSubject(params.ranking.ad_subject || '');
            } else {
                setName('');
                setSlug('');
                setNumberOfSchools(50);
                setUrl('');
                setHeadline('');
                setAdDegree('');
                setAdCategory('');
                setAdSubject('');
            }
        }
    }, [params.ranking]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setRanking(null);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        const input = {
            name,
            slug,
            number_schools: numberOfSchools,
            url,
            headline,
            ad_degree: adDegree,
            ad_category: adCategory,
            ad_subject: adSubject
        };


        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $input: SeoRankingWrite!) {
                    seoRankingUpdate(id: $id, input: $input)
                }
            `;

            variables = {
                id: params.ranking.id,
                input
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.seoRankingUpdate;
        } else {
            q = gql`
                mutation m($year: Int!, $input: SeoRankingWrite!) {
                    seoRankingCreate(year: $year, input: $input)
                }
            `;

            variables = {
                year: params.year,
                input: input
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.seoRankingCreate;
        }

        if (good) {
            toast.success(isEdit ? 'Ranking updated successfully' : 'Ranking created successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }
    }

    if (!params.ranking) return <> </>;

    const isEdit = !!params.ranking.id;

    return (
        <>
            <Dialog
                open={!!params.ranking}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    {isEdit ? `Update: ${params.ranking.name}` : 'Create Ranking'}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="slug"
                                label="Slug"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={slug}
                                onChange={(e) => setSlug(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="numberOfSchools"
                                label="Number of Schools"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={numberOfSchools}
                                onChange={(e) => setNumberOfSchools(parseInt(e.target.value))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="name"
                                label="Url"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="name"
                                label="Headline"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={headline}
                                onChange={(e) => setHeadline(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="ad_degree"
                                label="Ad Degree"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={adDegree}
                                onChange={(e) => setAdDegree(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="ad_category"
                                label="Ad Category"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={adCategory}
                                onChange={(e) => setAdCategory(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="ad_subject"
                                label="Ad Subject"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={adSubject}
                                onChange={(e) => setAdSubject(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}
