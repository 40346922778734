import * as React from "react";
import {useAuth} from "../../../providers/auth";
import {RoleType} from "../../../objects/Role";
import DashboardTabs from "../shared/dashboardTabs";
import DateRange, {RangeToTimes} from "../../../components/DateRange";
import Box from "@mui/material/Box";
import {FormControl, Grid, Link} from "@mui/material";
import Button from "@mui/material/Button";
import CachedIcon from "@mui/icons-material/Cached";
import Publishers from "./publishers";

export default function TTDashboardPage() {
    let auth = useAuth();
    const [dateRange, setDateRange] = React.useState([] as any);
    const [reload, setReload] = React.useState(0);

    React.useEffect(() => {
        document.title = "Thumbtack Dashboard | DN";
    }, []);

    React.useEffect(() => {
        if (auth.user && auth.user.role.type === RoleType.ADMIN) {
            updateDateRange(RangeToTimes("Today")).then(() => {
            });
        } else {
            window.location.href = "/";
        }
    }, []);

    const updateDateRange = async (value: any) => {
        setDateRange(value);
        setReload(Math.random());
    }


    return (
        <>
            <h2><DashboardTabs selected={"Thumb"}/></h2>
            <>

                <Link href="https://cfplatform.degreenexus.com"
                      target={'_blank'}>https://cfplatform.degreenexus.com</Link><br/>
                <Box sx={{display: 'flex', marginBottom: 2}}>
                    <DateRange showTime={false} dateRange={dateRange} setDateRange={updateDateRange} style={{marginLeft: 'auto'}} placement={'bottomEnd'}/>

                    <Button aria-label="download" onClick={() => {updateDateRange(dateRange)}} variant={'contained'} color={'success'} sx={{marginLeft: '5px', minWidth: '40px'}} size={"small"}>
                        <CachedIcon sx={{fontSize: '20px'}} />
                    </Button>
                </Box>
            </>

            <Grid container spacing={3}>
                <Grid md={12} item sx={{width: '100%'}}>
                    <Publishers reload={reload} dateRange={dateRange}/>
                </Grid>
            </Grid>

        </>
    );
}
