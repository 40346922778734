import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import downloadCsv from "../../lib/downloadCsv";

export async function searchRules(skip: number, limit: number, search: string, sortColumn: string, sortAsc: boolean) {
    const q = gql`
      query q ($input: RulesInput!) {
        rules(input: $input) {
            count
            items {
                id
                brokerId
                brokerName
                action {
                    id
                    name
                }
                matchString
            }
        }
      } 
    `;

    const variables = {
        input: {
            limit,
            skip,
            search,
            sort: [{column: sortColumn, isAsc: sortAsc}]
        }
    };

    const response = await query(q, variables);
    if (!response || !response.rules) {
        return {count: 0, rules: []};
    }

    return {count: response.rules.count, rules: response.rules.items};
}

export async function getBrokerOptions(search: string, selectedOptions: any[]) {
    const variables = {
        input: {
            limit: 100,
            skip: 0,
            sort: [{isAsc: true, column: 'name'}],
            search
        },
    };
    const g = gql`
        query ($input: BrokersInput!) {
            brokers(input: $input) {
                items {
                    id
                    name
                }
            }
        }
    `;
    const response = await query(g, variables);

    if (response && response.brokers) {
        return response.brokers.items.map((b: any) => ({
            id: b.id,
            value: `${b.name} (${b.id})`,
        }));
    }
    return [];
}

export async function getRuleActionOptions() {
    const q = gql`
      query q {
        ruleActions {
            id
            name
        }
    } 
    `;

    const response = await query(q, "");
    if (!response || !response.ruleActions) {
        return {options: []};
    }

    return {options: response.ruleActions};
}

export function downloadRules(search: string, sortColumn: string, sortAsc: boolean) {

    searchRules(0, 10000, search, sortColumn, sortAsc).then((response) => {
        const content = [];
        const header = [];

        header.push('Broker');
        header.push('Action');
        header.push('Match String');

        content.push(header);

        let row = [];
        response.rules.forEach((r: any) => {
            const brokerDisplayName = r.brokerId ? r.brokerName + " (" + r.brokerId + ")" : r.brokerName;

            row = [];
            row.push(brokerDisplayName);
            row.push(r.action.name);
            row.push(r.matchString);
            content.push(row);
        });

        downloadCsv('rules.csv', content).then(() => {});
    });
}
