import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import {Outlet} from "react-router";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

export default function Public() {
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Outlet/>
            </Box>
        </Container>
    )
}
