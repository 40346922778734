import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './lib/theme';
import {BrowserRouter} from "react-router-dom";
import 'rsuite/dist/rsuite-no-reset.min.css';
import './assets/overrides.css';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
    <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />

        <BrowserRouter>
            <App />
        </BrowserRouter>

    </ThemeProvider>
);
