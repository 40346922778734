import * as React from "react";
export default function NotFoundPage() {

    React.useEffect(() => {
        document.title = "Not Found | DN";
    }, []);

    return (
        <>
            <h1>Not Found</h1>
        </>
    )
}
