import * as React from "react";
import {
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    FormControlLabel,
    FormGroup, InputAdornment, InputLabel, MenuItem, Select
} from "@mui/material";
import MultiSelect from "../../components/multiSelect/multiSelect";
import {searchPubs} from "../blockedPublishers/blockedPublishersService";
import LoadingDialog from "../../components/loadingDialog";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {toast} from "react-toastify";

export default function SeoRankingFields({fields, setFields, title, editButtonLabel, showBackButton}: {fields: any, setFields: any, title: string, editButtonLabel?: string, showBackButton?: boolean}) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [netPriceEnabled, setNetPriceEnabled] = React.useState(false);
    const [netPriceAsc, setNetPriceAsc] = React.useState(1);
    const [netPricePercent, setNetPricePercent] = React.useState(0);

    const [ratioEnabled, setratioEnabled] = React.useState(false);
    const [ratioAsc, setratioAsc] = React.useState(1);
    const [ratioPercent, setratioPercent] = React.useState(0);

    const [in_state_tuitionEnabled, setin_state_tuitionEnabled] = React.useState(false);
    const [in_state_tuitionAsc, setin_state_tuitionAsc] = React.useState(1);
    const [in_state_tuitionPercent, setin_state_tuitionPercent] = React.useState(0);

    const [out_of_state_tuitionEnabled, setout_of_state_tuitionEnabled] = React.useState(false);
    const [out_of_state_tuitionAsc, setout_of_state_tuitionAsc] = React.useState(1);
    const [out_of_state_tuitionPercent, setout_of_state_tuitionPercent] = React.useState(0);

    const [graduate_in_state_tuitionEnabled, setgraduate_in_state_tuitionEnabled] = React.useState(false);
    const [graduate_in_state_tuitionAsc, setgraduate_in_state_tuitionAsc] = React.useState(1);
    const [graduate_in_state_tuitionPercent, setgraduate_in_state_tuitionPercent] = React.useState(0);

    const [graduate_out_of_state_tuitionEnabled, setgraduate_out_of_state_tuitionEnabled] = React.useState(false);
    const [graduate_out_of_state_tuitionAsc, setgraduate_out_of_state_tuitionAsc] = React.useState(1);
    const [graduate_out_of_state_tuitionPercent, setgraduate_out_of_state_tuitionPercent] = React.useState(0);

    const [acceptance_rateEnabled, setacceptance_rateEnabled] = React.useState(false);
    const [acceptance_rateAsc, setacceptance_rateAsc] = React.useState(1);
    const [acceptance_ratePercent, setacceptance_ratePercent] = React.useState(0);

    const [grad_rateEnabled, setgrad_rateEnabled] = React.useState(false);
    const [grad_rateAsc, setgrad_rateAsc] = React.useState(0);
    const [grad_ratePercent, setgrad_ratePercent] = React.useState(0);

    const [retention_rateEnabled, setretention_rateEnabled] = React.useState(false);
    const [retention_rateAsc, setretention_rateAsc] = React.useState(0);
    const [retention_ratePercent, setretention_ratePercent] = React.useState(0);

    const [payscale_salaryEnabled, setpayscale_salaryEnabled] = React.useState(false);
    const [payscale_salaryAsc, setpayscale_salaryAsc] = React.useState(0);
    const [payscale_salaryPercent, setpayscale_salaryPercent] = React.useState(0);

    const [other_salaryEnabled, setother_salaryEnabled] = React.useState(false);
    const [other_salaryAsc, setother_salaryAsc] = React.useState(0);
    const [other_salaryPercent, setother_salaryPercent] = React.useState(0);

    React.useEffect(() => {
        if (fields) {
            fields.forEach((x: any) => {
               if (x.name === 'net_price') {
                   setNetPriceEnabled(true);
                   setNetPriceAsc(x.isAsc ? 1 : 0);
                   setNetPricePercent(x.percent);
               } else if (x.name === 'ratio') {
                   setratioEnabled(true);
                   setratioAsc(x.isAsc ? 1 : 0);
                   setratioPercent(x.percent);
               } else if (x.name === 'in_state_tuition') {
                   setin_state_tuitionEnabled(true);
                   setin_state_tuitionAsc(x.isAsc ? 1 : 0);
                   setin_state_tuitionPercent(x.percent);
               } else if (x.name === 'out_of_state_tuition') {
                   setout_of_state_tuitionEnabled(true);
                   setout_of_state_tuitionAsc(x.isAsc ? 1 : 0);
                   setout_of_state_tuitionPercent(x.percent);
               } else if (x.name === 'graduate_in_state_tuition') {
                   setgraduate_in_state_tuitionEnabled(true);
                   setgraduate_in_state_tuitionAsc(x.isAsc ? 1 : 0);
                   setgraduate_in_state_tuitionPercent(x.percent);
               } else if (x.name === 'graduate_out_of_state_tuition') {
                   setgraduate_out_of_state_tuitionEnabled(true);
                   setgraduate_out_of_state_tuitionAsc(x.isAsc ? 1 : 0);
                   setgraduate_out_of_state_tuitionPercent(x.percent);
               } else if (x.name === 'payscale_salary') {
                     setpayscale_salaryEnabled(true);
                     setpayscale_salaryAsc(x.isAsc ? 1 : 0);
                     setpayscale_salaryPercent(x.percent);
               } else if (x.name === 'other_salary') {
                     setother_salaryEnabled(true);
                     setother_salaryAsc(x.isAsc ? 1 : 0);
                     setother_salaryPercent(x.percent);
               } else if (x.name === 'acceptance_rate') {
                    setacceptance_rateEnabled(true);
                    setacceptance_rateAsc(x.isAsc ? 1 : 0);
                    setacceptance_ratePercent(x.percent);
               } else if (x.name === 'grad_rate') {
                    setgrad_rateEnabled(true);
                    setgrad_rateAsc(x.isAsc ? 1 : 0);
                    setgrad_ratePercent(x.percent);
               } else if (x.name === 'retention_rate') {
                    setretention_rateEnabled(true);
                    setretention_rateAsc(x.isAsc ? 1 : 0);
                    setretention_ratePercent(x.percent);
               }
            });
        }
    }, [fields]);
    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        setOpen(false);
    };

    let totalPercent = 0;
    if (netPriceEnabled) totalPercent += netPricePercent;
    if (ratioEnabled) totalPercent += ratioPercent;
    if (in_state_tuitionEnabled) totalPercent += in_state_tuitionPercent;
    if (out_of_state_tuitionEnabled) totalPercent += out_of_state_tuitionPercent;
    if (graduate_in_state_tuitionEnabled) totalPercent += graduate_in_state_tuitionPercent;
    if (graduate_out_of_state_tuitionEnabled) totalPercent += graduate_out_of_state_tuitionPercent;
    if (acceptance_rateEnabled) totalPercent += acceptance_ratePercent;
    if (grad_rateEnabled) totalPercent += grad_ratePercent;
    if (retention_rateEnabled) totalPercent += retention_ratePercent;
    if (payscale_salaryEnabled) totalPercent += payscale_salaryPercent;
    if (other_salaryEnabled) totalPercent += other_salaryPercent;

    const handleSubmit = async (event: any) => {
        if (totalPercent !== 100) {
            toast.error("Total Percent must be 100%");
            return;
        }
        const input: any[] = [];
        if (netPriceEnabled) input.push({name: 'net_price', isAsc: netPriceAsc === 1, percent: netPricePercent});
        if (ratioEnabled) input.push({name: 'ratio', isAsc: ratioAsc === 1, percent: ratioPercent});
        if (in_state_tuitionEnabled) input.push({name: 'in_state_tuition', isAsc: in_state_tuitionAsc === 1, percent: in_state_tuitionPercent});
        if (out_of_state_tuitionEnabled) input.push({name: 'out_of_state_tuition', isAsc: out_of_state_tuitionAsc === 1, percent: out_of_state_tuitionPercent});
        if (graduate_in_state_tuitionEnabled) input.push({name: 'graduate_in_state_tuition', isAsc: graduate_in_state_tuitionAsc === 1, percent: graduate_in_state_tuitionPercent});
        if (graduate_out_of_state_tuitionEnabled) input.push({name: 'graduate_out_of_state_tuition', isAsc: graduate_out_of_state_tuitionAsc === 1, percent: graduate_out_of_state_tuitionPercent});
        if (acceptance_rateEnabled) input.push({name: 'acceptance_rate', isAsc: acceptance_rateAsc === 1, percent: acceptance_ratePercent});
        if (grad_rateEnabled) input.push({name: 'grad_rate', isAsc: grad_rateAsc === 1, percent: grad_ratePercent});
        if (retention_rateEnabled) input.push({name: 'retention_rate', isAsc: retention_rateAsc === 1, percent: retention_ratePercent});
        if (payscale_salaryEnabled) input.push({name: 'payscale_salary', isAsc: payscale_salaryAsc === 1, percent: payscale_salaryPercent});
        if (other_salaryEnabled) input.push({name: 'other_salary', isAsc: other_salaryAsc === 1, percent: other_salaryPercent});

        setLoading(true);
        const response = await setFields(input);
        setLoading(false);

        if (response) {
            setOpen(false);
        }
    }

    return (
        <>
        <Box sx={{marginBottom: '0px', marginTop: "-10px"}}>
            <b>{title}:</b> {fields.map((x: any) => <span key={x.name} style={{marginRight: '5px'}}>{x.name} ({x.percent}%)</span>)}
            <Button variant="outlined" size="small" sx={{margin: '0 5px'}} onClick={() => setOpen(true)}>{editButtonLabel || 'Edit'}</Button>
            {showBackButton && <Button variant="outlined" size="small" sx={{margin: '0 5px'}} onClick={() => setFields(null)} color={'error'}>Revert to Default</Button>}
        </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={netPriceEnabled} onClick={() => setNetPriceEnabled(!netPriceEnabled)} />} label="Net Price" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="netPrice-label"
                                value={netPriceAsc.toString()}
                                label="Sort"
                                onChange={(event) => setNetPriceAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="netPricePercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={netPricePercent}
                                onChange={(e) => setNetPricePercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={ratioEnabled} onClick={() => setratioEnabled(!ratioEnabled)} />} label="Student-to-Faculty Ratio" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="ratio-label"
                                value={ratioAsc.toString()}
                                label="Sort"
                                onChange={(event) => setratioAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="ratioPercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={ratioPercent}
                                onChange={(e) => setratioPercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={in_state_tuitionEnabled} onClick={() => setin_state_tuitionEnabled(!in_state_tuitionEnabled)}  />} label="In-State Tuition" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="in_state_tuition-label"
                                value={in_state_tuitionAsc.toString()}
                                label="Sort"
                                onChange={(event) => setin_state_tuitionAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="in_state_tuitionPercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={in_state_tuitionPercent}
                                onChange={(e) => setin_state_tuitionPercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={out_of_state_tuitionEnabled} onClick={() => setout_of_state_tuitionEnabled(!out_of_state_tuitionEnabled)}  />} label="Out-of-State Tuition" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="out_of_state_tuition-label"
                                value={out_of_state_tuitionAsc.toString()}
                                label="Sort"
                                onChange={(event) => setout_of_state_tuitionAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="out_of_state_tuitionPercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={out_of_state_tuitionPercent}
                                onChange={(e) => setout_of_state_tuitionPercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={graduate_in_state_tuitionEnabled} onClick={() => setgraduate_in_state_tuitionEnabled(!graduate_in_state_tuitionEnabled)}  />} label="Grad. In-State Tuition" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="graduate_in_state_tuition-label"
                                value={graduate_in_state_tuitionAsc.toString()}
                                label="Sort"
                                onChange={(event) => setgraduate_in_state_tuitionAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="graduate_in_state_tuitionPercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={graduate_in_state_tuitionPercent}
                                onChange={(e) => setgraduate_in_state_tuitionPercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={graduate_out_of_state_tuitionEnabled} onClick={() => setgraduate_out_of_state_tuitionEnabled(!graduate_out_of_state_tuitionEnabled)}  />} label="Grad. Out-of-State Tuition" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="graduate_out_of_state_tuition-label"
                                value={graduate_out_of_state_tuitionAsc.toString()}
                                label="Sort"
                                onChange={(event) => setgraduate_out_of_state_tuitionAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="graduate_out_of_state_tuitionPercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={graduate_out_of_state_tuitionPercent}
                                onChange={(e) => setgraduate_out_of_state_tuitionPercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={acceptance_rateEnabled} onClick={() => setacceptance_rateEnabled(!acceptance_rateEnabled)}  />} label="acceptance Rate" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="acceptance_rate-label"
                                value={acceptance_rateAsc.toString()}
                                label="Sort"
                                onChange={(event) => setacceptance_rateAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="acceptance_ratePercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={acceptance_ratePercent}
                                onChange={(e) => setacceptance_ratePercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={retention_rateEnabled} onClick={() => setretention_rateEnabled(!retention_rateEnabled)}  />} label="Retention Rate" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="retention_rate-label"
                                value={retention_rateAsc.toString()}
                                label="Sort"
                                onChange={(event) => setretention_rateAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="retention_ratePercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={retention_ratePercent}
                                onChange={(e) => setretention_ratePercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={grad_rateEnabled} onClick={() => setgrad_rateEnabled(!grad_rateEnabled)}  />} label="Grad Rate" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="grad_rate-label"
                                value={grad_rateAsc.toString()}
                                label="Sort"
                                onChange={(event) => setgrad_rateAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="grad_ratePercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={grad_ratePercent}
                                onChange={(e) => setgrad_ratePercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={payscale_salaryEnabled} onClick={() => setpayscale_salaryEnabled(!payscale_salaryEnabled)}  />} label="Payscale Salary" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="payscale_salary-label"
                                value={payscale_salaryAsc.toString()}
                                label="Sort"
                                onChange={(event) => setpayscale_salaryAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="payscale_salaryPercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={payscale_salaryPercent}
                                onChange={(e) => setpayscale_salaryPercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>

                    <FormGroup row={true}>
                        <FormControlLabel sx={{width: 250}} control={<Checkbox checked={other_salaryEnabled} onClick={() => setother_salaryEnabled(!other_salaryEnabled)}  />} label="Other Salary" />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="items-label">Sort</InputLabel>
                            <Select
                                labelId="other_salary-label"
                                value={other_salaryAsc.toString()}
                                label="Sort"
                                onChange={(event) => setother_salaryAsc(parseInt(event.target.value as string, 10))}
                                size={'small'}
                            >
                                <MenuItem value={0}>Highest Value Best</MenuItem>
                                <MenuItem value={1}>Lowest Value Best</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 100 }} size="small">
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="other_salaryPercent"
                                label="Percent"
                                type="number"
                                fullWidth
                                size={'small'}
                                value={other_salaryPercent}
                                onChange={(e) => setother_salaryPercent(parseInt(e.target.value,10))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    step: "1"
                                }}
                            />
                        </FormControl>
                    </FormGroup>

                    <FormGroup row={true} sx={{fontWeight: 'bold'}}>
                        <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                            Total:
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 220, textAlign: 'right' }} size="small">
                            {totalPercent}%
                        </FormControl>
                    </FormGroup>
                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>{editButtonLabel || 'Save'}</Button>
                    </DialogActions>
                }
            </Dialog>
            </>
    )

}
