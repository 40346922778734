import {useParams} from "react-router";
import * as React from "react";
import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import Loading from "../../components/loading";
import useDebounce from "../../lib/UseDebounce";
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormGroup, IconButton,
    Input,
    InputAdornment,
    Link, Menu, MenuItem,
    Paper, Table, TableBody,
    TableCell,
    TableContainer, TableHead
} from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SearchIcon from "@mui/icons-material/Search";
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import ClickWallSchoolUpsert from "./clickWallSchoolUpsert";
import ClickWallProgramUpsert from "./clickWallProgramUpsert";

export default function ClickWallProgramsPage() {
    let { school_id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [search, setSearch] = React.useState('');
    const [school, setSchool] = React.useState(null as any);
    const [programs, setPrograms] = React.useState([] as any[]);
    const [programUpsert, setProgramUpsert] = React.useState(null as any);
    const [anchorEls, setAnchorEls] = React.useState<any>({});
    const getData = async (sSearch: string) => {
        const q = gql`
            query q ($criteria: ClickWallProgramCriteria!, $criteria2: ClickWallSchoolCriteria!) {
                clickWallPrograms(criteria: $criteria) {
                    id
                    name
                    description
                    is_active
                }
                clickWallSchools(criteria: $criteria2) {
                    id
                    name
                }
            }`;

        const variables = {
            criteria: {
                school_id: parseInt(school_id || '0'),
                search: sSearch
            },
            criteria2: {
                id: parseInt(school_id || '0')
            }
        };

        return await query(q, variables);
    }

    const reload = async (sSearch: string) => {
        setLoading(true);
        const data = await getData(sSearch);
        setSchool(data.clickWallSchools[0]);
        document.title = `Click Wall Programs: ${data.clickWallSchools[0].name} | DN`;

        // sort data.clickWallPrograms by name
        let p = JSON.parse(JSON.stringify(data.clickWallPrograms)).sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
        });

        setPrograms(p);
        setLoading(false);
    }

    useDebounce(() => {
            reload(search).then(x=>{});
        }, [search], 400
    );

    const elipses = (str: string, len: number) => {
        if (str.length > len) {
            return str.substring(0, len) + '...';
        }
        return str;
    }

    if (!school) {
        return <> </>;
    }

    return (
        <>
            <h2>CW Programs: {school.name}
                <Button component={Link} variant="outlined" size="small" sx={{margin: '0 5px'}}
                        href={`/clickWallSchools`}>&larr; Schools</Button>
            </h2>
            <FormGroup row={true}>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} size="small">
                    <Input
                        id="search"
                        type="text"
                        spellCheck="false"
                        placeholder="Search"
                        value={search || ''}
                        onChange={(e: any) => setSearch(e.target.value)}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    />
                </FormControl>
                <FormControl sx={{ minWidth: 120 }} size="small">
                    <Button aria-label="Add" onClick={() => { setProgramUpsert({}) }} variant={'contained'} color={'warning'}>
                        <AddBoxOutlinedIcon />&nbsp;Create Program
                    </Button>
                </FormControl>
            </FormGroup>
            {loading ? <Loading/> : <>
                {!programs || programs.length === 0 ? <Alert severity="warning">No results found</Alert> :
                    <>
                        <Box sx={{margin: '10px 0', fontWeight: 'bold', fontSize: '13px', display:"flex",}}>
                            Showing 1 to {programs.length.toLocaleString(undefined, {maximumFractionDigits: 0})} of {programs.length.toLocaleString(undefined, {maximumFractionDigits: 0})}.
                        </Box>
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)', maxHeight: 'calc(100vh - 300px)'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell>
                                            #
                                        </TableCell>
                                        <TableCell>
                                            Id
                                        </TableCell>
                                        <TableCell>
                                            Name
                                        </TableCell>
                                        <TableCell>
                                            Active
                                        </TableCell>
                                        <TableCell>
                                            Description
                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {programs.map((row: any, index: number) => (
                                        <React.Fragment key={row.id}>
                                            <StripedTableRow>
                                                <TableCell>
                                                    <b>{index + 1}.</b>
                                                </TableCell>
                                                <TableCell>
                                                    {row.id}
                                                </TableCell>
                                                <TableCell>
                                                    <Link href={`/clickWallProgramMaps/${school_id}/${row.id}`}>{row.name}</Link>
                                                </TableCell>
                                                <TableCell>
                                                    {row.is_active && <CheckCircleOutlineIcon sx={{color: 'green', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/>}
                                                    {!row.is_active && <HighlightOffIcon sx={{color: 'red', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/>}
                                                </TableCell>
                                                <TableCell>
                                                    {elipses(row.description, 100)}
                                                </TableCell>


                                                <TableCell align="right">
                                                    <IconButton
                                                        aria-haspopup="true"
                                                        onClick={(event) => {
                                                            setAnchorEls({...anchorEls, [row.id]: event.currentTarget});
                                                        }}
                                                    >
                                                        <MoreVertIcon sx={{fontSize: '15px'}} />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEls[row.id]}
                                                        open={!!anchorEls[row.id]}
                                                        onClose={() => {
                                                            setAnchorEls({...anchorEls, [row.id]: null});
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => {
                                                            setProgramUpsert(row);
                                                            setAnchorEls({...anchorEls, [row.id]: null});
                                                        }} disableRipple><EditIcon sx={{marginRight: 1}} /> Edit Program</MenuItem>
                                                    </Menu>
                                                </TableCell>
                                            </StripedTableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </>}
            </>}

            <ClickWallProgramUpsert program={programUpsert} setProgram={setProgramUpsert} schoolId={parseFloat(school_id || '0')} reload={() => {reload(search).then(() => {})}} />
        </>
    );
}
