import * as React from "react";
import DateRange, {RangeToTimes} from "../components/DateRange";
import Loading from "../components/loading";
import {gql} from "@apollo/client";
import {query} from "../lib/apollo";
import {
    Alert, Input, InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow, TableSortLabel
} from "@mui/material";
import theme from "../lib/theme";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import StripedTableRow from "../components/StripedTableRow";
import TableFooterRow from "../components/TableFooterRow";
import TableHeaderRow from "../components/TableHeaderRow";

export default function ClickFeedImportedPage() {

    React.useEffect(() => {
        document.title = "ClickFeed Imported Stats | DN";
    }, []);


    const [dateRange, setDateRange] = React.useState([] as any);
    const [results, setResults] = React.useState([] as any);
    const [filtered, setFiltered] = React.useState([] as any);
    const [loading, setLoading] = React.useState(true);
    const [search, setSearch] = React.useState<string>('');
    const [sort, setSort] = React.useState({column: 'rpm', direction: 'desc'});

    React.useEffect(() => {
        updateDateRange(RangeToTimes("Yesterday"));
    }, []);

    const updateDateRange = async (value: any) => {
        setDateRange(value);
        setLoading(true);
        const q = gql`
            query q($input: ClickFeedImportStatInput!) {
                clickFeedImportStats(input: $input) {
                    broker
                    campaign
                    impressions
                    clicks
                    revenue
                    conversionPercent
                    rpc
                    rpm
                }
            }
        `;
        const variables = {
            input: {
                startDate: moment(value[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(value[1]).format("YYYY-MM-DD HH:mm:ss"),
            }
        }

        const response = await query(q, variables);

        if (response && response.clickFeedImportStats) {
            setResults(response.clickFeedImportStats);
            updateFiltered(search, response.clickFeedImportStats, sort);
        }

        setLoading(false);
    }

    const updateFiltered = (s: string, r: any, srt: any) => {
        let f = JSON.parse(JSON.stringify(r));
        if (f.length > 0 && s) {
            s = s.trim().toLowerCase();
            f = f.filter((row: any) => {
                return row.broker.toLowerCase().includes(s) || row.campaign.toLowerCase().includes(s);
            });

        }

        const sorted = f.sort((a: any, b: any) => {
            if (srt.direction === 'desc') {
                if (a[srt.column] < b[srt.column]) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a[srt.column] > b[srt.column]) {
                    return 1;
                } else {
                    return -1;
                }
            }
        });
        setFiltered(sorted);
    }

    const doSort = (column: string) => {
        let s;
        if (sort.column === column) {
            if (sort.direction === 'desc') {
                s = {column: column, direction: 'asc'};

            } else {
                s ={column: column, direction: 'desc'};
            }
        } else {
            if (column === 'broker' || column === 'campaign') {
                s = {column: column, direction: 'asc'};
            } else {
                s = {column: column, direction: 'desc'};
            }
        }

        setSort(s);
        updateFiltered(search, results, s);
    }

    return (
        <>
            <h2>ClickFeed Imported Stats</h2>

            {loading ? <Loading/> :
                <>
                    <Box sx={{display: 'flex', flexDirection: 'row', }}>
                        <DateRange showTime={false} dateRange={dateRange} setDateRange={updateDateRange} style={{marginRight: '10px'}}/>
                        {results.length > 0 ? <Input
                            id="search"
                            type="text"
                            spellCheck="false"
                            placeholder="Search"
                            value={search || ''}
                            onChange={(e: any) => {setSearch(e.target.value); updateFiltered(e.target.value, results, sort);} }
                            startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                            sx={{maxWidth: '200px', marginLeft: 'auto'}}
                        /> : <> </>}
                    </Box>

                    <p></p>
                    {results.length === 0 ? <Alert severity="warning">No results found in date range</Alert> :
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)'}}>
                        <Table>
                            <TableHead>
                                <TableHeaderRow>
                                    <TableCell>
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'broker'}
                                            direction={sort.column === 'broker' && sort.direction === 'desc' ? sort.direction : 'asc'}
                                            onClick={() => {doSort('broker')}}
                                        >
                                            Broker
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'campaign'}
                                            direction={sort.column === 'campaign' && sort.direction === 'desc' ? sort.direction : 'asc'}
                                            onClick={() => {doSort('campaign')}}
                                        >
                                            Campaign
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'impressions'}
                                            direction={sort.column === 'impressions' && sort.direction === 'desc' ? sort.direction : 'asc'}
                                            onClick={() => {doSort('impressions')}}
                                        >
                                            Impressions
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'clicks'}
                                            direction={sort.column === 'clicks' && sort.direction === 'desc' ? sort.direction : 'asc'}
                                            onClick={() => {doSort('clicks')}}
                                        >
                                            Clicks
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'revenue'}
                                            direction={sort.column === 'revenue' && sort.direction === 'desc' ? sort.direction : 'asc'}
                                            onClick={() => {doSort('revenue')}}
                                        >
                                            Revenue
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'conversionPercent'}
                                            direction={sort.column === 'conversionPercent' && sort.direction === 'desc' ? sort.direction : 'asc'}
                                            onClick={() => {doSort('conversionPercent')}}
                                        >
                                            Conv
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'rpc'}
                                            direction={sort.column === 'rpc' && sort.direction === 'desc' ? sort.direction : 'asc'}
                                            onClick={() => {doSort('rpc')}}
                                        >
                                            RPC
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'rpm'}
                                            direction={sort.column === 'rpm' && sort.direction === 'desc' ? sort.direction : 'asc'}
                                            onClick={() => {doSort('rpm')}}
                                        >
                                            RPM
                                        </TableSortLabel>
                                    </TableCell>
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody>
                                {filtered.map((row: any) => (
                                    <StripedTableRow
                                        key={`${row.broker}-${row.campaign}`}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.broker}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.campaign}
                                        </TableCell>
                                        <TableCell align="right">{row.impressions.toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                        <TableCell align="right">{row.clicks.toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                        <TableCell align="right">${row.revenue.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right">{row.conversionPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%</TableCell>
                                        <TableCell align="right">${row.rpc.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell align="right">${row.rpm.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</TableCell>
                                    </StripedTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableFooterRow>
                                    <TableCell colSpan={2}>Totals</TableCell>
                                    <TableCell align="right">{filtered.reduce((a: any, b: any) => a + b.impressions, 0).toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                    <TableCell align="right">{filtered.reduce((a: any, b: any) => a + b.clicks, 0).toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                    <TableCell align="right">${filtered.reduce((a: any, b: any) => a + b.revenue, 0).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">{(filtered.reduce((a: any, b: any) => a + b.clicks, 0) / filtered.reduce((a: any, b: any) => a + b.impressions, 0) * 100 || 0).toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%</TableCell>
                                    <TableCell align="right">${(filtered.reduce((a: any, b: any) => a + b.revenue, 0) / filtered.reduce((a: any, b: any) => a + b.clicks, 0) || 0).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">${(filtered.reduce((a: any, b: any) => a + b.revenue, 0) / filtered.reduce((a: any, b: any) => a + b.impressions, 0) * 1000 || 0).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</TableCell>
                                </TableFooterRow>
                            </TableFooter>
                        </Table>
                        </TableContainer>
                    }
            </>}
        </>
    )
}
