import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch} from "@mui/material";
import TextField from "@mui/material/TextField";
import SingleSelect from "../../components/SingleSelect";
import {getBillingGroup, getDupeGroup} from "../schools/schoolsService";
import LoadingDialog from "../../components/loadingDialog";
import Button from "@mui/material/Button";
import {gql} from "@apollo/client";
import {mutate} from "../../lib/apollo";
import {toast} from "react-toastify";

export default function ClickWallProgramUpsert (params: {program: any, setProgram: any, reload: any, schoolId: number}) {
    const [loading, setLoading] = React.useState(true);
    const [name, setName] = React.useState('' as string);
    const [description, setDescription] = React.useState('' as string);
    const [isActive, setIsActive] = React.useState(true);


    React.useEffect(() => {
        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (params.program) {
            if (params.program.name) {
                setName(params.program.name || '');
                setDescription(params.program.description || '');
                setIsActive(params.program.is_active || false);
            } else {
                setName('');
                setDescription('');

                setIsActive(true);
            }
        }
    }, [params.program]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setProgram(null);
    };


    if (!params.program) return <> </>;

    const isEdit = !!params.program.id;

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        const input = {
            name,
            description,
            is_active: isActive
        };


        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $program: ClickWallProgramWrite!) {
                    clickWallProgramUpdate(id: $id, program: $program)
                }
            `;

            variables = {
                id: params.program.id,
                program: input
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.clickWallProgramUpdate;
        } else {
            q = gql`
                mutation m($school_id: Int!, $program: ClickWallProgramWrite!) {
                    clickWallProgramCreate(school_id: $school_id, program: $program)
                }
            `;

            variables = {
                school_id: params.schoolId,
                program: input
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.clickWallProgramCreate;
        }

            if (good) {
                toast.success(isEdit ? 'Program updated successfully' : 'Program created successfully');
                setLoading(false);
                handleClose();
                params.reload();
            } else {
                setLoading(false);
            }
        }

    return (
        <>
            <Dialog
                open={!!params.program}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    {isEdit ? `Update: ${params.program.name}` : 'Create Program'}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={isActive} onChange={(event)=> setIsActive(event.target.checked)} />} label={isActive ? 'Active' : 'Inactive'} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="description"
                                label="Description"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                multiline
                                rows={6}
                                inputProps={{
                                    style: {
                                        resize: 'vertical'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}
