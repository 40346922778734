import * as React from 'react';
import {AppBar, Badge, Chip, Dialog, DialogContent, DialogTitle, FormControl, Tab, Tabs} from "@mui/material";
import {useEffect} from "react";
import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LoadingDialog from "../loadingDialog";
import PubComments from "./pubComments";
import PubBlocked from "./pubBlocked";
import PubCreative from "./pubCreative";
import PubSettings from "./pubSettings";
import PubStats from "./pubStats";
import PubConversions from "./pubConversions";
import PubRevenue from "./pubRevenue";

export default function PubCard(params: {pubId: number, setPubId: any}) {
    const [loading, setLoading] = React.useState(true);
    const [pub, setPub] = React.useState(null as any);
    const [tab, setTab] = React.useState(0);
    const [stats, setStats] = React.useState({loading: true, data: null as any, daysBack: 7, monthsBack: 6, series: [] as any[], yAxisRight: null as any, categories: [] as any[]});
    const [creative, setCreative] = React.useState({loading: true, data: null as any, dateRange: [] as any});
    const [conversions, setConversions] = React.useState({loading: true, data: null as any, dateRange: [] as any, totals: {}, selectedPublishers: [] as any});
    const [revenue, setRevenue] = React.useState({dateRange: [] as any, selectedPublishers: [] as any});

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setPubId(0);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    }

    useEffect(() => {
      if (params.pubId) {
          const q = gql`
              query($input: PublishersInput!) {
                  publishers(input: $input) {
                      items {
                          id
                          name
                          cpl
                          trafficType
                          maxEpc
                          savedMaxEpc
                          whenStarts {
                              allowedOnPathImmediately
                              allowedOnPathOne_to_Three
                              allowedOnPathFour_to_Six  
                                allowOnPathSixMonths
                              sellToAImmediately
                              sellToAOne_to_Three
                              sellToAFour_to_Six
                                sellToASixMonths
                          }
                      }
                  }
              }
          `;

          const variables = {
              input: {
                  skip: 0,
                  limit: 1,
                  ids: [params.pubId],
              },
          };

          setLoading(true)
            query(q, variables).then(x=> {
                const pub = x.publishers.items[0];
                setPub(pub);
                setConversions({...conversions, selectedPublishers: conversions.selectedPublishers.length  ? conversions.selectedPublishers : [{ id: pub.id, value: `${pub.name} (${pub.id})`}]});
                setRevenue({...revenue, selectedPublishers: revenue.selectedPublishers.length ? revenue.selectedPublishers : [{ id: pub.id, value: `${pub.name} (${pub.id})`}]});
                setLoading(false);
            });

      }
    }, [params.pubId])

    if (!params.setPubId) {
        return <></>;
    }

    let uri = process.env.REACT_APP_OLD_URL;

    if (window.location.hostname.includes("gitpod")) {
        uri = "https://www2.degreenexus.com/admin";
    }

    return (
        <Dialog
            open={!!params.pubId}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"lg"}
            fullScreen={true}
            sx={{
                "& .MuiDialog-container .MuiDialog-paper": {
                    margin: "24px 0px",
                }}}
        >
            {loading || !pub ? <Box style={{marginTop: '20px'}}><LoadingDialog/></Box> : <>
                <DialogTitle id="alert-dialog-title" sx={{display: 'flex', flexDirection: 'row', margin: 0, padding: 1}}>
                    <Box style={{fontSize: '15px'}}>{pub.name} ({pub.id}): ${parseFloat(pub.cpl).toFixed(2)}</Box>
                    <Chip color="success" label={pub.trafficType} sx={{marginX: '10px', marginTop: '2px'}} size={"small"}></Chip>
                    <HighlightOffIcon sx={{cursor: 'pointer', verticalAlign: 'text-top', marginLeft: 'auto', marginTop: '2px'}} onClick={handleClose}></HighlightOffIcon>
                </DialogTitle>
                <AppBar position="static">
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Conversions"/>
                        <Tab label="Comments"/>
                        <Tab label="Stats"/>
                        <Tab label="Settings"/>
                        <Tab label="CF Redirect" href={`${uri}/new/ui/index.php#/pub/${pub.id}`} target={"_blank"}/>
                        <Tab label="Inventory" href={`${uri}/new/ui/index.php#/pub/${pub.id}`} target={"_blank"}/>
                        <Tab label="Creative"/>
                        <Tab label="Blocked"/>
                        <Tab label="Revenue"/>
                    </Tabs>
                </AppBar>
                <Box sx={{marginTop: 2}}>
                    {tab === 0 && <PubConversions pubId={pub.id} conversions={conversions} setConversions={setConversions}/>}
                    {tab === 1 && <PubComments pubId={pub.id}/>}
                    {tab === 2 && <PubStats pubId={pub.id} stats={stats} setStats={setStats}/>}
                    {tab === 3 && <PubSettings pub={pub} setPub={setPub}/>}
                    {tab === 6 && <PubCreative pubId={pub.id} creative={creative} setCreative={setCreative}/>}
                    {tab === 7 && <PubBlocked pubId={pub.id}/>}
                    {tab === 8 && <PubRevenue pubId={pub.id} revenue={revenue} setRevenue={setRevenue}/>}
                </Box>


            </>}

        </Dialog>
    )

}
