import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import downloadCsv from "../../lib/downloadCsv";

export async function searchUsers(active: boolean, search: string, sortColumn: string, sortAsc: boolean) {
    const q = gql`
      query q ($input: UsersInput!) {
        users(input: $input) {
            count
            items {
                id
                name
                active
                email
                role {
                    id
                    value
                    type
                }
            }
        }
      } 
    `;

    const variables = {
        input: {
            limit: 100,
            active,
            skip: 0,
            sort: [{column: sortColumn, isAsc: sortAsc}],
            search
        }
    };

    const response = await query(q, variables);
    if (!response || !response.users) {
        return {count: 0, users: []};
    }

    return {count: response.users.count, users: response.users.items};
}

export async function searchCampaigns(search: string, selectedCampaigns: any[]) {
    const variables = {
        input: {
            limit: 100,
            active: true,
            skip: 0,
            search,
            excludeIds: selectedCampaigns.map((x) => x.id),
        },
    };
    const g = gql`
        query ($input: HpCampaignsInput!) {
            hpCampaigns(input: $input) {
                count
                items {
                    id
                    broker {
                        id
                        name
                    }
                    school {
                        id
                        name
                    }
                    company {
                        id
                        name
                    }
                    app_id
                }
            }
        }
    `;
    const response = await query(g, variables);

    if (response && response.hpCampaigns) {
        return response.hpCampaigns.items.map((c: any) => ({
            id: c.id,
            value: `${c.broker.name} [${c.school.name}][${c.company.name}][${c.id}]`,
        }));
    }
    return [];
}

export async function getRoles() {
    const q = gql`
      query q {
        roles {
            id
            type
            value
        }
    } 
    `;

    const response = await query(q, "");
    if (!response || !response.roles || response.roles.length == 0) {
        return {count: 0, items: []};
    }

    const defaultOptions = [{"__typename":"Role", "id": 0, "type":"DEFAULT_OPTION", "value":"Select Option"}];
    const allOptions = defaultOptions.concat(response.roles);
    return {count: response.roles.length, items: allOptions};
}

export async function getSelf() {
    const q = gql`
      query q {
        self {
            id
        }
    } 
    `;

    const response = await query(q, "");
    if (!response || !response.self) {
        return 0;
    }

    return response.self.id;
}

export async function getAssignedPublishers(id:number) {
    const q = gql`
      query q ($id: Int!) {
        userPublishers (id: $id){
            id
            name
        }
    } 
    `;

    const variables = {
        id
    };
    
    const response = await query(q, variables);
    if (!response || !response.userPublishers) {
        return {items: []};
    }

    return response.userPublishers;
}

export async function getAssignedCampaigns(id:number) {
    const q = gql`
      query q ($id: Int!) {
        userCampaigns (id: $id){
            id
            name
        }
    } 
    `;

    const variables = {
        id
    };
    
    const response = await query(q, variables);
    if (!response || !response.userCampaigns) {
        return {items: []};
    }

    return response.userCampaigns;
}

export function downloadUsers(search: string, active: boolean, sortColumn: string, sortAsc: boolean) {

    searchUsers(active, search, sortColumn, sortAsc).then((response) => {
        const content = [];
        const header = [];

        header.push('Id');
        header.push('Name');
        header.push('Email');
        header.push('Role');

        content.push(header);

        let row = [];
        response.users.forEach((r: any) => {
            row = [];
            row.push(r.id);
            row.push(r.name);
            row.push(r.email);
            row.push(r.role.value);
            content.push(row);
        });

        downloadCsv('users.csv', content).then(() => {});
    });
}