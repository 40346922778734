import {Card, CardContent, CardHeader, Grid, IconButton} from "@mui/material";
import theme2 from "../../lib/theme";
import * as React from "react";
import {gql} from "@apollo/client";
import moment from "moment/moment";
import {query} from "../../lib/apollo";
import Loading from "../../components/loading";
import Box from "@mui/material/Box";
import CachedIcon from "@mui/icons-material/Cached";
import PieChart from "../../components/pieChart";
import Timeseries from "../../components/timeseries";


export default function InventoryTimeseries(params: {selectedPublishers: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [series, setSeries] = React.useState([] as any[]);
    const reload = async () => {
        setLoading(true);

        const q = gql`
            query q($publisherIds: [Int!]) {
                inventoryTimeSeries(publisherIds: $publisherIds) {
                    today {
                        timestamp
                        value
                    }
                    yesterday {
                        timestamp
                        value
                    }
                    average {
                        timestamp
                        value
                    }
                }
            }
        `;

        const variables = {
            publisherIds: params.selectedPublishers.map((p: any) => p.id),
        }

        const response = await query(q, variables);
        if (response && response.inventoryTimeSeries) {
            const s = response.inventoryTimeSeries;

            const series = [] as any[];

            const average = {name: "Average", data: [] as any, color : "red"};

            s.average.forEach((item: any) => {
                average.data.push([item.timestamp, item.value]);
            });

            const today = {name: "Today", data: [] as any, type:'area', color: '#90ed7d'};
            s.today.forEach((item: any) => {
                today.data.push([item.timestamp, item.value]);
            });

            const yesterday = {name: "Yesterday", data: [] as any, type:'area'};
            s.yesterday.forEach((item: any) => {
                yesterday.data.push([item.timestamp, item.value]);
            });

            series.push(yesterday);
            series.push(today);
            series.push(average);

            setSeries(series);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (params.reload) {
            reload().then(() => {});
        }
    }, [params.reload]);

    return (
        <Card sx={{height: '365px', maxWidth: 'calc(100vw - 10px)'}}>
            <CardHeader title={"Inventory By Hour"} sx={{backgroundColor: theme2.palette.primary.main, color: theme2.palette.primary.contrastText, padding: '5px'}}
                        titleTypographyProps={{sx:{fontSize: '18px'}}}
                        action={
                            <IconButton aria-label="refresh" sx={{color: theme2.palette.primary.contrastText}} onClick={reload}>
                                <CachedIcon />
                            </IconButton>
                        }
            />
            <CardContent>
                {loading ? <Box sx={{marginTop: '130px'}}><Loading/></Box> : <>
                    <Timeseries height={300} series={series} title={"Leads Per Hour"} prefix={""} suffix= {""} dateFormat={"ha"} decimalPlaces={0}/>

                </>}
            </CardContent>
        </Card>
    );
}
