import * as React from 'react';
import Button from "@mui/material/Button";
import {
    FormControl, IconButton,
    Input,
    InputAdornment, ListItemButton, ListItemText,
    Menu,
} from "@mui/material";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from "@mui/icons-material/Search";

import useDebounce from "../lib/UseDebounce";
import LoadingDialog from "./loadingDialog";
import theme from "../lib/theme";
import CloseIcon from '@mui/icons-material/Close';


export default function SingleSelect(props: {searchFunction: any, selectedItem: any, setSelectedItem: any, placeholder: string}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [search, setSearch] = React.useState<string>('');
    const [items, setItems] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let dropDownText = props.selectedItem?.value || props.placeholder || 'None';

    const searchItems = () => {
        props.searchFunction(search, []).then((x: any)=> {
            setItems(x);
            setLoading(false);
        });
    }

    useDebounce(() => {
            setLoading(true);
            searchItems();

        }, [search], 300
    );

    const handleSelect = (event: any, item: any) => {
        event.stopPropagation();
        items.forEach(x => {
            if (x.id === item.id) {
                x.selected = !x.selected;
            } else {
                x.selected = false
            }
        });
        setItems(items);
        handleClose();
        props.setSelectedItem(item);

    }

    return (
        <FormControl fullWidth>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{border: '1px solid #ced4da', borderRadius: '5px', padding: '5px', textAlign: 'left', alignItems: 'left', justifyContent: 'left', width: '100%', color: '#6c757d'
                    ,textTransform: 'none', fontWeight: 'normal', '&:hover': {backgroundColor: '#e9ecef', color: '#212529', borderColor: '#ced4da'}
                }}
            >
                <Box style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden'}}>{dropDownText}</Box>
                <Box style={{marginLeft: 'auto'}}>
                    {open ? <KeyboardArrowUpIcon sx={{verticalAlign: 'text-top', fontSize: 'medium'}}/> : <KeyboardArrowDownIcon sx={{verticalAlign: 'text-top', fontSize: 'medium'}}/>}
                </Box>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{width: '360px', padding: 1,

                }}>
                    <FormControl fullWidth variant="standard">
                        <Input
                            autoFocus={true}
                            id="search"
                            type="text"
                            spellCheck="false"
                            placeholder="Search"
                            value={search || ''}
                            onChange={(e: any) => setSearch(e.target.value)}
                            startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                            endAdornment={search && !loading && <IconButton onClick={() => setSearch("")}><CloseIcon sx={{fontSize: '15px'}}/></IconButton>}
                        />
                    </FormControl>
                    {loading ? <Box sx={{marginTop: '25px'}}>
                            <LoadingDialog/>
                        </Box>:
                        <Box style={{display: 'flex', flexDirection: 'row'}}>
                            <Box>
                                <Box sx={{height:200, width: 345, border: "1px solid #ccc", overflowY: 'scroll', overflowX: 'scroll'
                                    , "&::-webkit-scrollbar": {width: '5px', height: '5px'}
                                    , "&::-webkit-scrollbar-thumb": {backgroundColor: '#ccc', borderRadius: '10px'},
                                }}>
                                    {items.map((item, index) => {
                                        return (
                                            <ListItemButton key={index}
                                                            selected={item.selected}
                                                            onClick={(event) => {
                                                                handleSelect(event, item);

                                                            }}
                                                            sx={{padding: '2px 3px 2px 3px'
                                                                , "&.Mui-selected": {backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}
                                                                , "&.Mui-selected:hover": {backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}
                                                            }}

                                            ><ListItemText
                                                sx={{margin: 0}}
                                                primary={item.value}
                                                primaryTypographyProps={{fontSize: '13px',whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                                            /></ListItemButton>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Box>}
                </Box>

            </Menu>
        </FormControl>
    )
}
