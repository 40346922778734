export const enum RoleType {
    NONE = 'NONE',
    ADMIN = 'ADMIN',
    CPA_MANAGER = 'CPA_MANAGER',
    CPA_REP = 'CPA_REP',
    CAMPAIGN_MANAGER = 'CAMPAIGN_MANAGER',
    SALES_REP = 'SALES_REP',
    JUNIOR_ADMIN = 'JUNIOR_ADMIN',
    CALL_CENTER_REP = 'CALL_CENTER_REP',
    PUB_REP = 'PUB_REP',
    CALL_CENTER_MANAGER = 'CALL_CENTER_MANAGER',
    ASSISTANT = 'ASSISTANT',
    ACCOUNTANT = 'ACCOUNTANT',
    SEO = "SEO",
    SEO_DATA = "SEO_DATA",
    PUB = "PUB",
}

export interface Role {
    id: number;
    type: RoleType;
    value: string;
}
