import * as React from "react";
import {downloadRules, searchRules} from "./rulesService";
import Loading from "../../components/loading";
import {
    Alert, FormControl,
    FormGroup, IconButton, Input, InputAdornment, InputLabel, Menu, MenuItem,
    Paper, Select, SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableSortLabel,
    Box,
    Button,
    Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../lib/UseDebounce";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import RuleUpsert from "./ruleUpsert";
import RuleDelete from "./ruleDelete";

export default function RulesPage() {
    //
    const [rules, setRules] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(100);
    const [search, setSearch] = React.useState('');
    const [anchorEls, setAnchorEls] = React.useState<any>({});
    const [ruleUpsert, setRuleUpsert] = React.useState<any>(null as any);
    const [ruleDelete, setRuleDelete] = React.useState<any>(null as any);
    const [count, setCount] = React.useState(0);
    const [sort, setSort] = React.useState({column: 'name', sortAsc: true});
    const [page, setPage] = React.useState(1);

    //
    React.useEffect(() => {
        document.title = "Rules | DN";
    }, []);

    //
    const reload = async (nSkip: number, nLimit: number, sSearch: string, sort: any) => {
        setLoading(true);
        const p = await searchRules(nSkip, nLimit, sSearch, sort.column, sort.sortAsc);
        setRules(p.rules);
        setCount(p.count);
        setLoading(false);
    }

    React.useEffect(() => {
        reload(skip, limit, search, sort).then(x=>{});
    }, []);

    const handleLimit = async (event: SelectChangeEvent) => {
        const l = parseInt(event.target.value as string, 10);
        setLimit(l);
        setPage(1);
        setSkip(0);
        await reload(0, l, search, sort);
    };

    useDebounce(() => {
        reload(skip, limit, search, sort).then(x=>{});
        }, [search], 400
    );

    const doSort = async (column: string, defaultAsc: boolean = false) => {
        let s;
        if (sort.column === column) {
            s = {column: column, sortAsc: !sort.sortAsc};
        } else {
            s = {column: column, sortAsc: defaultAsc};
        }

        setSort(s);
        await reload(skip, limit, search, s);
    }

    const doPageChange = async (newPageNum: number) => {
        const newSkip = limit * (newPageNum - 1);
        setSkip(newSkip);
        setPage(newPageNum);
        await reload(newSkip, limit, search, sort);
    }

    const doSearch = (value: any) => {
        setSkip(0);
        setPage(1);
        setSearch(value)
    }

    //
    return (
        <>
            <h2>Rules</h2>
            <FormGroup row={true}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="items-label">Items</InputLabel>
                    <Select
                        labelId="items-label"
                        value={limit.toString()}
                        label="Items"
                        onChange={handleLimit}
                        size={'small'}
                    >
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                        <MenuItem value={1000}>1000</MenuItem>
                        <MenuItem value={2000}>2000</MenuItem>
                        <MenuItem value={10000}>10000</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} size="small">
                    <Input
                        id="search"
                        type="text"
                        spellCheck="false"
                        placeholder="Search"
                        value={search || ''}
                        onChange={(e: any) => doSearch(e.target.value)}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    />
                </FormControl>
                {!!rules && !!rules.length && !loading && <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="download" onClick={() => { downloadRules(search, sort.column, sort.sortAsc) }} variant={'contained'} color={'success'}>
                        <FileDownloadIcon />&nbsp;Export
                    </Button>
                </FormControl>}

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="Add" onClick={() => { setRuleUpsert({id: 0}) }} variant={'contained'} color={'warning'}>
                        <AddBoxOutlinedIcon />&nbsp;Create Rule
                    </Button>
                </FormControl>
            </FormGroup>
            {loading ? <Loading/> :
                <>
                    {!rules || rules.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                            <Box sx={{margin: '10px 0', fontWeight: 'bold', fontSize: '13px', display:"flex",}}>
                                Showing {(skip + 1).toLocaleString(undefined, {maximumFractionDigits: 0})} to {Math.min(limit + skip, count).toLocaleString(undefined, {maximumFractionDigits: 0})} of {count.toLocaleString(undefined, {maximumFractionDigits: 0})}.
                            </Box>
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'name'}
                                                direction={sort.column === 'name' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('name', true)}}
                                            >
                                                Broker
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'action'}
                                                direction={sort.column === 'action' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('action', false)}}
                                            >
                                                Action
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                sx={{width: '100%'}}
                                                active={sort.column === 'matchString'}
                                                direction={sort.column === 'matchString' && !sort.sortAsc ? 'desc' : 'asc'}
                                                onClick={() => {doSort('matchString', false)}}
                                            >
                                                Match String
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {rules.map((row: any) => (
                                        <React.Fragment key={row.id}>
                                            <StripedTableRow>
                                            <TableCell>
                                                {row.brokerId ? `${row.brokerName} (${row.brokerId})` : `${row.brokerName}`}
                                            </TableCell>
                                            <TableCell>
                                                {row.action.name}
                                            </TableCell>
                                            <TableCell>
                                                {row.matchString}
                                            </TableCell>

                                            <TableCell align="right">
                                                <IconButton
                                                    aria-haspopup="true"
                                                    onClick={(event) => {
                                                        setAnchorEls({...anchorEls, [row.id]: event.currentTarget});
                                                    }}
                                                >
                                                    <MoreVertIcon sx={{fontSize: '15px'}} />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEls[row.id]}
                                                    open={!!anchorEls[row.id]}
                                                    onClose={() => {
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }}
                                                >
                                                    <MenuItem onClick={() => {
                                                        setRuleUpsert(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><EditIcon sx={{marginRight: 1}} /> Edit Rule</MenuItem>
                                                     <MenuItem onClick={() => {
                                                        setRuleDelete(row);
                                                        setAnchorEls({...anchorEls, [row.id]: null});
                                                    }} disableRipple><HighlightOffIcon sx={{marginRight: 1}} /> Delete Rule</MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </StripedTableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{margin: '30px 0', fontWeight: 'bold', fontSize: '13px', display:"inline-block",}}>
                            Showing {(skip + 1).toLocaleString(undefined, {maximumFractionDigits: 0})} to {Math.min(limit + skip, count).toLocaleString(undefined, {maximumFractionDigits: 0})} of {count.toLocaleString(undefined, {maximumFractionDigits: 0})}.
                        </Box>
                        <Box sx={{margin: '10px 0', fontWeight: 'bold', fontSize: '13px', display:"inline-block", position: 'relative', float: 'right'}}>
                            <Pagination sx={{marginTop: '20px'}} count={Math.max(1, (Math.ceil(count / limit)))} onChange={(e, value) => doPageChange(value)} defaultPage={page} variant="outlined" />
                        </Box>
                        </>
                    }
                </>
            }
            <RuleUpsert rule={ruleUpsert} setRule={setRuleUpsert} reload={() => {reload(skip, limit, search, sort).then(() => {})}} />
            <RuleDelete rule={ruleDelete} setRule={setRuleDelete} reload={() => {reload(skip, limit, search, sort).then(() => {})}} />
        </>
    )
}
