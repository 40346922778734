import * as React from "react";
import {useParams} from "react-router";
import {
    Alert, Box,
    Button, CircularProgress,
    FormControl,
    FormGroup,
    Input, InputAdornment,
    InputLabel,
    Link,
    MenuItem, Pagination, Paper,
    Select,
    SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead
} from "@mui/material";
import {gql} from "@apollo/client";
import {mutate, query} from "../../lib/apollo";
import useDebounce from "../../lib/UseDebounce";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Loading from "../../components/loading";
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SeoRankingFields from "./seoRankingFields";
import {useAuth} from "../../providers/auth";
import {PermissionType} from "../../objects/UserRead";

export default function SeoYearPage() {
    const [schools, setSchools] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [limit, setLimit] = React.useState(100);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [maxPage, setMaxPage] = React.useState(1);
    const [edits, setEdits] = React.useState({} as any);
    const [temp, setTemp] = React.useState({} as any);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [defaultRankingFields, setDefaultRankingFields] = React.useState([] as any[]);
    let auth = useAuth();
    let { year } = useParams();

    React.useEffect(() => {
        document.title = `Seo Data ${year} | DN`;
    }, [year]);

    const reload = async (nLimit: number, sSearch: string, nPage: number) => {
        setLoading(true);
        const q = gql`
            query q ($input: SeoSchoolYearlyDataInput!, $year: Int!) {
                seoYear(year: $year) {
                    defaultRankingFields {
                        name
                        isAsc
                        percent
                    }
                }
                seoSchoolYearlyData(input: $input) {
                    count
                    items {
                        school_id
                        college_name
                        net_price
                        ratio
                        in_state_tuition
                        out_of_state_tuition
                        graduate_in_state_tuition
                        graduate_out_of_state_tuition
                        acceptance_rate
                        retention_rate
                        grad_rate
                        total_enrollment
                        undergraduate_students
                        graduate_students
                        payscale_salary
                        other_salary
                    }
                }
            }
        `;

        const variables = {
            input: {
                year: parseInt(year || "0", 10),
                limit: nLimit,
                skip: nPage * limit,
                search: sSearch
            },
            year: parseInt(year || "0", 10),
        };

        const response = await query(q, variables);
        if (!response || !response.seoYear) {
            return
        }
        setDefaultRankingFields(response.seoYear.defaultRankingFields);

        if (!response || !response.seoSchoolYearlyData) {
            setSchools([]);
            setCount(0);
            setMaxPage(0)
        } else {
            setSchools(response.seoSchoolYearlyData.items);
            setCount(response.seoSchoolYearlyData.count);
            setMaxPage(Math.ceil(response.seoSchoolYearlyData.count / nLimit));
        }

        setEdits({});
        setTemp({});
        setLoadingSave(false);

        setLoading(false);
    }

    const handleLimit = async (event: SelectChangeEvent) => {
        const l = parseInt(event.target.value as string, 10);
        setLimit(l);
        setPage(0)
        await reload(l, search, 0);
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
        await reload(limit, search, value - 1);
    };

    const handleSave = async (field: string, schoolId: number) => {
        setLoadingSave(true);
        const q = gql`
            mutation m ($input: SeoSchoolYearlyDataWrite!) {
                seoSchoolYearlyDataUpdate(input: $input) 
            }
        `;
        const variables = {
            input: {
                year: parseInt(year || "0", 10),
                school_id: schoolId,
                field,
                value: parseFloat(temp[`${field}-${schoolId}`]),
            }
        };

        const response = await mutate(q, variables);
        if (response && response.seoSchoolYearlyDataUpdate) {
            const newSchools = JSON.parse(JSON.stringify(schools));
            const school = newSchools.find((x: any) => x.school_id === schoolId);
            school[field] = parseFloat(temp[`${field}-${schoolId}`]);
            setSchools([...newSchools]);

            await handleEdit(field, schoolId, false);
        }
        setLoadingSave(false);
    }

    const handleEdit = async (field: string, schoolId: number, isSet: boolean) => {

        if (edits[`net_price-${schoolId}`] && isSet) {
            return
        }

        let newEdits = {...edits};
        let newTemp = {...temp};
        if (isSet) {
            newEdits[`${field}-${schoolId}`] = true;
            newTemp[`${field}-${schoolId}`] = schools.filter(x => x.school_id === schoolId)[0][field];
            setTimeout(() => {
                (document.getElementById(`${field}-${schoolId}`) as any).select();
                (document.getElementById(`${field}-${schoolId}`) as any).focus();
            }, 200);
        } else {
            delete newEdits[`${field}-${schoolId}`];
            delete newTemp[`${field}-${schoolId}`];
        }
        setEdits(newEdits);
        setTemp(newTemp);
    }

    const handleTemp = async (field: string, schoolId: number, value: string) => {
        setTemp({...temp, [`${field}-${schoolId}`]: value});
        setTimeout(() => {
            (document.getElementById(`${field}-${schoolId}`) as any).focus();
        }, 0);
    }

    useDebounce(() => {
            setPage(0);
            reload(limit, search, 0).then(x=>{});
        }, [search], 400
    );

    const setFields = async (fields: any) => {
        const variables = {
            year: parseInt(year || "0", 10),
            input: fields
        };
        const q = gql`
            mutation m ($input: [SeoRankingFieldWrite]!, $year: Int!) {
                seoSchoolYearlyDefaultRankingFieldsUpdate(input: $input, year: $year) 
            }
        `;

        const response = await mutate(q, variables);
        if (response && response.seoSchoolYearlyDefaultRankingFieldsUpdate) {
            setDefaultRankingFields(fields);
            return true;
        }
        return false;
    }

    return (
        <>
            <h2>SEO Data {year}
                <Button component={Link} variant="outlined" size="small" sx={{margin: '0 5px'}} href="/seoSchools">&larr; Schools</Button>
                {auth.user?.permissions.includes(PermissionType.SEO) && <Button component={Link} variant="outlined" size="small" sx={{margin: '0 5px'}} href={`/seoRankings/${year}`} color={'success'}>Rankings</Button>}
            </h2>
            {auth.user?.permissions.includes(PermissionType.SEO) && <SeoRankingFields fields={defaultRankingFields} setFields={setFields} title={`Default Ranking Criteria for ${year}`}/>}
            {!auth.user?.permissions.includes(PermissionType.SEO) && <Box>&nbsp;</Box>}
            <FormGroup row={true}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="items-label">Items</InputLabel>
                    <Select
                        labelId="items-label"
                        value={limit.toString()}
                        label="Items"
                        onChange={handleLimit}
                        size={'small'}
                    >
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                        <MenuItem value={1000}>1000</MenuItem>
                        <MenuItem value={2000}>2000</MenuItem>
                        <MenuItem value={10000}>10000</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} size="small">
                    <Input
                        id="search"
                        type="text"
                        spellCheck="false"
                        placeholder="Search"
                        value={search || ''}
                        onChange={(e: any) => setSearch(e.target.value)}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    />
                </FormControl>
            </FormGroup>
            {loading ? <Loading/> :
                <>
                    {!schools || schools.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                            <Box sx={{margin: '10px 0', fontWeight: 'bold', fontSize: '13px', display:"flex",}}>
                                Showing {1 + page * limit} to {Math.min(schools.length + page * limit, count).toLocaleString(undefined, {maximumFractionDigits: 0})} of {count.toLocaleString(undefined, {maximumFractionDigits: 0})}.
                            </Box>
                            <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)', maxHeight: 'calc(100vh - 320px)'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableHeaderRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell>
                                                Id
                                            </TableCell>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Net Price
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Ratio
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                In-State
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Out-State
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Grad In-State
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Grad Out-State
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Acceptance
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Retention
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Grad Rate
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Enrollment
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Undergrad.
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Grad.
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Payscale Sal.
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Other Sal.
                                            </TableCell>
                                        </TableHeaderRow>
                                    </TableHead>
                                    <TableBody>
                                        {schools.map((row: any, index: number) => (
                                            <React.Fragment key={row.school_id}>
                                                <StripedTableRow>
                                                    <TableCell>
                                                        <b>{index + 1 + page*limit}.</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.school_id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.college_name}
                                                    </TableCell>
                                                    <EditCell school_id={row.school_id} field={'net_price'} value={row.net_price} prefix={"$"} suffix={""} width={70}/>
                                                    <EditCell school_id={row.school_id} field={'ratio'} value={row.ratio} prefix={""} suffix={":1"} width={40}/>
                                                    <EditCell school_id={row.school_id} field={'in_state_tuition'} value={row.in_state_tuition} prefix={"$"} suffix={""} width={70}/>
                                                    <EditCell school_id={row.school_id} field={'out_of_state_tuition'} value={row.out_of_state_tuition} prefix={"$"} suffix={""} width={70}/>
                                                    <EditCell school_id={row.school_id} field={'graduate_in_state_tuition'} value={row.graduate_in_state_tuition} prefix={"$"} suffix={""} width={70}/>
                                                    <EditCell school_id={row.school_id} field={'graduate_out_of_state_tuition'} value={row.graduate_out_of_state_tuition} prefix={"$"} suffix={""} width={70}/>
                                                    <EditCell school_id={row.school_id} field={'acceptance_rate'} value={row.acceptance_rate} prefix={""} suffix={"%"} width={60}/>
                                                    <EditCell school_id={row.school_id} field={'retention_rate'} value={row.retention_rate} prefix={""} suffix={"%"} width={60}/>
                                                    <EditCell school_id={row.school_id} field={'grad_rate'} value={row.grad_rate} prefix={""} suffix={"%"} width={60}/>
                                                    <EditCell school_id={row.school_id} field={'total_enrollment'} value={row.total_enrollment} prefix={""} suffix={""} width={70}/>
                                                    <EditCell school_id={row.school_id} field={'undergraduate_students'} value={row.undergraduate_students} prefix={""} suffix={""} width={70}/>
                                                    <EditCell school_id={row.school_id} field={'graduate_students'} value={row.graduate_students} prefix={""} suffix={""} width={70}/>
                                                    <EditCell school_id={row.school_id} field={'payscale_salary'} value={row.payscale_salary} prefix={"$"} suffix={""} width={70}/>
                                                    <EditCell school_id={row.school_id} field={'other_salary'} value={row.other_salary} prefix={"$"} suffix={""} width={70}/>
                                                </StripedTableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination count={maxPage} variant="outlined" shape="rounded" style={{paddingTop: 15}} defaultPage={page + 1} onChange={handlePageChange} />
                        </>
                    }
                </>
            }
        </>
    )


    function EditCell({school_id, field, value, prefix, suffix, width}: {school_id: number, field: string, value:number, prefix: string, suffix: string, width: number}) {
        return (
        <TableCell align={"right"} style={{cursor: edits[`${field}-${school_id}`] ? "inherit" : "cell"}} onDoubleClick={() => handleEdit(field, school_id, true)} sx={{width: `${width + 65}px`}}>
            {!edits[`${field}-${school_id}`] && <>{prefix}{value.toLocaleString(undefined, {maximumFractionDigits: 0})}{suffix}</>}
            {edits[`${field}-${school_id}`] && <>
                <FormGroup row={true} sx={{width: width + 65}}>
                    <FormControl variant="filled" sx={{ m: 1, width: width }} size="small">
                        <Input
                            type="number"
                            spellCheck="false"
                            value={temp[`${field}-${school_id}`]}
                            onChange={(e: any) => handleTemp(field, school_id, e.target.value)}
                            id={`${field}-${school_id}`}
                        />
                    </FormControl>
                    {!loadingSave && <FormControl variant="filled" sx={{ m: 0, flexDirection: 'row'}} size="small">
                        <CheckCircleOutlineIcon sx={{color: 'green', fontSize: '22px', marginTop: '14px', cursor: 'pointer'}} onClick={() => handleSave(field, school_id)}/>
                        <HighlightOffIcon sx={{color: 'red', fontSize: '22px', marginTop: '14px', marginLeft: '5px', cursor: 'pointer'}} onClick={() => handleEdit(field, school_id, false)}/>
                    </FormControl>
                    }
                    {loadingSave && <FormControl variant="filled" sx={{ m: 0, flexDirection: 'row'}} size="small">
                        <CircularProgress size={22} sx={{marginTop: '14px', marginLeft: '5px'}} />
                    </FormControl>}
                </FormGroup>
            </>}
        </TableCell>
        )
    }
}
