import * as React from 'react';
import {
    Alert, Box,
    Button,
    FormControl, FormControlLabel,
    FormGroup, IconButton,
    Input,
    InputAdornment,
    InputLabel, Link, Menu,
    MenuItem, Pagination, Paper,
    Select,
    SelectChangeEvent, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableSortLabel
} from "@mui/material";
import Loading from "../../components/loading";
import {gql} from "@apollo/client";
import {mutate, query} from "../../lib/apollo";
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import {useAuth} from "../../providers/auth";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ClickWallSchoolUpsert from "./clickWallSchoolUpsert";


export default function ClickWallSchoolsPage() {
    const [schools, setSchools] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [anchorEls, setAnchorEls] = React.useState<any>({});
    const [schoolUpsert, setSchoolUpsert] = React.useState<any>(null as any);
    const [immedietly, setImmedietly] = React.useState(false);
    const [one, setOne] = React.useState(false);
    const [four, setFour] = React.useState(false);
    const [six, setSix] = React.useState(false);

    let auth = useAuth();

    React.useEffect(() => {
        document.title = "Click Wall Schools | DN";
    }, []);

    const getData = async () => {
        const q = gql`
            query q {
                clickWallSchools {
                    id
                    name
                    location
                    logo_url
                    is_active
                    is_online
                    is_profit
                    is_public
                    school_order
                }
                
                clickWallWhenStarts {
                    id
                    name                
                }
            }
        `;

        return await query(q, null);
    };
    const reload = async () => {
        setLoading(true);

        const response = await getData();
        if (!response || !response.clickWallSchools) {
            setSchools([]);
        } else {
            setSchools(response.clickWallSchools);
        }

        if (response && response.clickWallWhenStarts) {
            setImmedietly(response.clickWallWhenStarts.some((x: any) => x.id === 2));
            setOne(response.clickWallWhenStarts.some((x: any) => x.id === 4));
            setFour(response.clickWallWhenStarts.some((x: any) => x.id === 8));
            setSix(response.clickWallWhenStarts.some((x: any) => x.id === 16));
        }

        setLoading(false);
    }

    const setWhenStarts = async (id: number, value: boolean) => {
        const q = gql`
            mutation m($when_start_id: Int!, $selected: Boolean!) {
                clickWallWhenStartUpdate(when_start_id: $when_start_id, selected: $selected)
            }
        `;

        const variables = {
            when_start_id: id,
            selected: value
        }

        switch (id) {
            case 2:
                setImmedietly(value);
                break;
            case 4:
                setOne(value);
                break;
            case 8:
                setFour(value);
                break;
            case 16:
                setSix(value);
                break;
        }

        await mutate(q, variables);

    }

    React.useEffect(() => {
        reload().then(() => {});
    }, []);

    return (
        <>
            <h2>CW Schools</h2>
            Demo Links: <Link href="https://schools.degreelocate.com/?id=458116785040"
                             target={'_blank'}>https://schools.degreelocate.com/?id=458116785040</Link><br/>
            <Link href="https://schools.universityhq.org/?id=458116785040"
                  target={'_blank'}>https://schools.universityhq.org/?id=458116785040</Link>
            <br/>

            <FormGroup row={true}>
                <FormControlLabel control={<Switch checked={immedietly} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWhenStarts(2, event.target.checked)}/>} label="Immediately"/>
                <FormControlLabel control={<Switch checked={one} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWhenStarts(4, event.target.checked)}/>} label="1-3 Months"/>
                <FormControlLabel control={<Switch checked={four} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWhenStarts(8, event.target.checked)}/>} label="4-6 Months"/>
                <FormControlLabel control={<Switch checked={six} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWhenStarts(16, event.target.checked)}/>} label="6+ Months"/>
            </FormGroup>
            <br/>
            <FormGroup row={true}>
                <FormControl sx={{minWidth: 120}} size="small">
                    <Button aria-label="Add" onClick={() => {
                        setSchoolUpsert({})
                    }} variant={'contained'} color={'warning'}>
                        <AddBoxOutlinedIcon/>&nbsp;Create School
                    </Button>
                </FormControl>
            </FormGroup>
            {loading ? <Loading/> :
                <>
                    {!schools || schools.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                            <Box sx={{margin: '10px 0', fontWeight: 'bold', fontSize: '13px', display: "flex",}}>
                                Showing 1
                                to {schools.length.toLocaleString(undefined, {maximumFractionDigits: 0})} of {schools.length.toLocaleString(undefined, {maximumFractionDigits: 0})}.
                            </Box>
                            <TableContainer component={Paper}
                                            sx={{maxWidth: 'calc(100vw - 10px)', maxHeight: 'calc(100vh - 300px)'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableHeaderRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell>
                                                Id
                                            </TableCell>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Order
                                            </TableCell>
                                            <TableCell>
                                                Logo
                                            </TableCell>
                                            <TableCell>
                                                Location
                                            </TableCell>
                                            <TableCell>
                                                Active
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableHeaderRow>
                                    </TableHead>
                                    <TableBody>
                                        {schools.map((row: any, index: number) => (
                                            <React.Fragment key={row.id}>
                                                <StripedTableRow>
                                                    <TableCell>
                                                        <b>{index + 1}.</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link href={`/clickWallPrograms/${row.id}`}>{row.name}</Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.school_order}
                                                    </TableCell>
                                                    <TableCell>
                                                        <img src={row.logo_url}/>
                                                    </TableCell>

                                                    <TableCell>
                                                        {row.location}
                                                    </TableCell>

                                                    <TableCell>
                                                        {row.is_active && <CheckCircleOutlineIcon sx={{
                                                            color: 'green',
                                                            fontSize: '20px',
                                                            marginLeft: '5px',
                                                            verticalAlign: 'text-top'
                                                        }}/>}
                                                        {!row.is_active && <HighlightOffIcon sx={{
                                                            color: 'red',
                                                            fontSize: '20px',
                                                            marginLeft: '5px',
                                                            verticalAlign: 'text-top'
                                                        }}/>}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <IconButton
                                                            aria-haspopup="true"
                                                            onClick={(event) => {
                                                                setAnchorEls({
                                                                    ...anchorEls,
                                                                    [row.id]: event.currentTarget
                                                                });
                                                            }}
                                                        >
                                                            <MoreVertIcon sx={{fontSize: '15px'}}/>
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={anchorEls[row.id]}
                                                            open={!!anchorEls[row.id]}
                                                            onClose={() => {
                                                                setAnchorEls({...anchorEls, [row.id]: null});
                                                            }}
                                                        >
                                                            <MenuItem onClick={() => {
                                                                setSchoolUpsert(row);
                                                                setAnchorEls({...anchorEls, [row.id]: null});
                                                            }} disableRipple><EditIcon sx={{marginRight: 1}}/> Edit
                                                                School</MenuItem>
                                                        </Menu>
                                                    </TableCell>
                                                </StripedTableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </>
                    }
                </>
            }
            <ClickWallSchoolUpsert school={schoolUpsert} setSchool={setSchoolUpsert} reload={() => {
                reload().then(() => {
                })
            }}/>
        </>
    );
}
