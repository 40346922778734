import {Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Menu, MenuItem} from "@mui/material";
import theme2 from "../../lib/theme";
import * as React from "react";
import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import Loading from "../../components/loading";
import Box from "@mui/material/Box";
import CachedIcon from "@mui/icons-material/Cached";
import KeyIcon from '@mui/icons-material/Key';
import SettingsUpdate from "./settingsUpdate";
import SettingsIcon from '@mui/icons-material/Settings';
const colors = {
    'danger': '#CB5754',
    'warning': '#E6AD57',
    'default': '#777777',
    'info': '#75C0DC',
    'success': '#6FB65F',
}
export default function Settings(params: {reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [settings, setSettings] = React.useState({} as any);
    const [salesMarginLock, setSalesMarginLock] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const [openSettings, setOpenSettings] = React.useState(false);

    const reload = async () => {
        setLoading(true);

        const q = gql`
            query q {
                settings {
                    margin_lock_hours
                    margin_target_percent
                    exclusive_percent
                    exclusive_tier1_percent
                    isExclusivePROn
                    isTier1ExclusiveOn
                    no_cf_after_pr_percent
                    no_min_margin_percent
                    pec_wins_margin_percent
                    pec_bl_no_min_margin_percent
                }
                salesMarginLock
            }
        `;


        const response = await query(q, null);
        if (response && response.settings) {
            setSettings(response.settings);
        }
        if (response && response.salesMarginLock) {
            setSalesMarginLock(response.salesMarginLock);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (params.reload) {
            reload().then(() => {});
        }
    }, [params.reload]);

    React.useEffect(() => {
        setTimeout(reload, 1000 * 60 * 2);
    }, []);

    return (
        <Card sx={{height: '365px', maxWidth: 'calc(100vw - 10px)'}}>
            <CardHeader title={"Settings"} sx={{backgroundColor: theme2.palette.primary.main, color: theme2.palette.primary.contrastText, padding: '5px'}}
                        titleTypographyProps={{sx:{fontSize: '18px'}}}
                        action={
                            <>
                            <IconButton
                                aria-haspopup="true"
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                }}
                            >
                                <KeyIcon sx={{color: theme2.palette.primary.contrastText}} />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={!!anchorEl}
                                onClose={() => {
                                    setAnchorEl(null);
                                }}
                            >
                                <MenuItem disableRipple sx={{backgroundColor: colors.success, color: 'white', '&:hover': {backgroundColor: colors.success, color: 'white'}}}>Teamwork: 5%</MenuItem>
                                <MenuItem disableRipple sx={{backgroundColor: colors.info, color: 'white', '&:hover': {backgroundColor: colors.info, color: 'white'}}}>Teamwork: 25%</MenuItem>
                                <MenuItem disableRipple sx={{backgroundColor: colors.default, color: 'white', '&:hover': {backgroundColor: colors.default, color: 'white'}}}>Teamwork: 50%</MenuItem>
                                <MenuItem disableRipple sx={{backgroundColor: colors.warning, color: 'white', '&:hover': {backgroundColor: colors.warning, color: 'white'}}}>Teamwork: 75%</MenuItem>
                                <MenuItem disableRipple sx={{backgroundColor: colors.danger, color: 'white', '&:hover': {backgroundColor: colors.danger, color: 'white'}}}>Teamwork: 100%</MenuItem>
                            </Menu>
                            <IconButton aria-label="settings" sx={{color: theme2.palette.primary.contrastText}} onClick={() => setOpenSettings(true)}>
                                <SettingsIcon />
                            </IconButton>
                            <IconButton aria-label="refresh" sx={{color: theme2.palette.primary.contrastText}} onClick={reload}>
                                <CachedIcon />
                            </IconButton>
                            </>
                        }
            />
            <CardContent>
                {loading ? <Box sx={{marginTop: '100px'}}><Loading/></Box> : (<Box sx={{color: 'white', backgroundColor: getColor(settings.margin_target_percent, salesMarginLock), height: '323px', margin: '-16px 0 0 -20px', width: 'calc(100% + 36px)'}}>
                    <Box sx={{textAlign: 'center'}}>
                        {settings.margin_target_percent}% {settings.margin_lock_hours} hours

                        <Box sx={{fontSize: '110px'}}>
                            {salesMarginLock.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%
                        </Box>
                    </Box>
                </Box>)}
            </CardContent>
            <SettingsUpdate settings={settings} open={openSettings} reload={reload} setOpen={setOpenSettings}/>
        </Card>
    );
}

function getColor(margin_target_percent: number, salesMarginLock: number) {
    if (salesMarginLock < margin_target_percent * .625) {
        return colors.danger;
    } else if (salesMarginLock < margin_target_percent * .75) {
        return colors.warning;
    } else if (salesMarginLock < margin_target_percent * .875) {
        return colors.default;
    } else if (salesMarginLock < margin_target_percent * .96) {
        return colors.info;
    } else {
        return colors.success;
    }
}
