import {gql} from "@apollo/client";
import {mutate, query} from "../../lib/apollo";
import downloadCsv from "../../lib/downloadCsv";

export async function getAllPubs() {
    const g =
    gql`
        query {
            blockedPublishers {
                brokerId
                brokerName
                publisherId
                publisherName
                date
                lastLeadDate
                trafficType
            }
        }
    `;

    const response = await query(g, null);

    return response.blockedPublishers || [];
}

export async function downloadPubs(groups: any) {
    const content = [];
    const header = [];

    header.push('Broker');
    header.push('Publisher');
    header.push('Publisher Name');
    header.push('Publisher Id');
    header.push('Date Added');
    header.push('Last Lead');
    header.push('Traffic Type');

    content.push(header);

    let row = [];
    groups.forEach((broker: any) => {
        broker.pubs.forEach((pub: any) => {
            row = [];
            row.push(pub.brokerName + ' (' + pub.brokerId + ')');
            row.push(pub.publisherName + ' (' + pub.publisherId + ')');
            row.push(pub.publisherName);
            row.push(pub.publisherId);
            row.push(new Date(pub.date).toLocaleString());
            row.push(pub.lastLeadDate ? new Date(pub.lastLeadDate).toLocaleString() : '');
            row.push(pub.trafficType);
            content.push(row);
        });
    });

    await downloadCsv('blockedPubs.csv', content);
}

export function getPubGroups(pubs: any[]) {
    if (!pubs) return [];
    const groups: any[] = [];
    for (let i = 0; i < pubs.length; i++) {
        const pub: any = pubs[i];
        const group = groups.find(x => x.brokerId === pub.brokerId);
        if (group) {
            group.count++
            group.pubs.push({publisherId: pub.publisherId, publisherName: pub.publisherName, trafficType: pub.trafficType, date: pub.date, lastLeadDate: pub.lastLeadDate, brokerId: pub.brokerId, brokerName: pub.brokerName});
        } else {
            groups.push({
                brokerId: pub.brokerId,
                brokerName: pub.brokerName,
                count: 1,
                pubs: [{publisherId: pub.publisherId, publisherName: pub.publisherName, trafficType: pub.trafficType, date: pub.date, lastLeadDate: pub.lastLeadDate, brokerId: pub.brokerId, brokerName: pub.brokerName}]
            });
        }
    }

    return groups;
}

export function getFilteredPubs(search: string, pubs: any[]) {
    const s = search.toLowerCase().trim();

    if (!s) {
        return pubs;
    }
    const filtered = pubs.filter((pub: any) => {
        const isGood = pub.publisherName.toLowerCase().includes(s)
            || pub.publisherId.toString().includes(s)
            || pub.brokerName.toLowerCase().includes(s)
            || pub.brokerId.toString().includes(s)
            || pub.trafficType.toString().includes(s);

        return isGood
    });

    return filtered;
}

export async function searchPubs(search: string, selectedPubs: any[]) {
    const variables = {
        input: {
            limit: 500,
            active: true,
            skip: 0,
            sort: [{isAsc: true, column: 'name'}],
            search,
            excludeIds: selectedPubs.map((x) => x.id),
        },
    };
    const g = gql`
        query ($input: PublishersInput!) {
            publishers(input: $input) {
                items {
                    id
                    name
                }
            }
        }
    `;
    const response = await query(g, variables);

    if (response && response.publishers) {
        return response.publishers.items.map((p: any) => ({
            id: p.id,
            value: `${p.name} (${p.id})`,
        }));
    }
    return [];
}
export async function searchBrokers(search: string, selectedBrokers: any[]) {
    const variables = {
        input: {
            limit: 100,
            active: true,
            skip: 0,
            search,
            excludeIds: selectedBrokers.map((x) => x.id),
        },
    };
    const g = gql`
        query ($input: BrokersInput!) {
            brokers(input: $input) {
                items {
                    id
                    name
                }
            }
        }
    `;
    const response = await query(g, variables);

    if (response && response.brokers) {
        return response.brokers.items.map((p: any) => ({
            id: p.id,
            value: `${p.name} (${p.id})`,
        }));
    }
    return [];
}

export async function addBlockedPubs(pubs: any[], brokers: any[], comment: string) {
    const variables = {
        input: {
            publisherIds: pubs.map((x: any) => x.id),
            brokerIds: brokers.map((x: any) => x.id),
            comment,
        },
    };

    const m = gql`
        mutation($input: BlockedPublisherAddInput!) {
            blockedPublisherAdd(input: $input)
        }
    `;

    const response = await mutate(m, variables);

    return response && response.blockedPublisherAdd;
}
