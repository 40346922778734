import {useParams} from "react-router";
import * as React from "react";
import {gql} from "@apollo/client";
import {mutate, query} from "../../lib/apollo";
import {
    Alert,
    Box,
    Button, FormControl,
    IconButton,
    Link, Menu, MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead
} from "@mui/material";
import Loading from "../../components/loading";
import SeoRankingFields from "./seoRankingFields";
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import SeoRankingSchoolDelete from "./seoRankingSchoolDelete";
import {Delete, DeleteOutline, HideSource, Visibility, VisibilityOff} from "@mui/icons-material";
import SeoRankingSchoolHide from "./seoRankingSchoolHide";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SeoRankingSchoolsAdd from "./seoRankingSchoolsAdd";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import downloadCsv from "../../lib/downloadCsv";

export default function SeoRankingPage() {
    const [loading, setLoading] = React.useState(true);
    const [ranking, setRanking] = React.useState(null as any);
    const [rankingFields, setRankingFields] = React.useState([] as any[]);
    const [isCustom, setIsCustom] = React.useState(false);
    const [anchorEls, setAnchorEls] = React.useState<any>({});
    const [schoolDelete, setSchoolDelete] = React.useState(null as any);
    const [schoolHidden, setSchoolHidden] = React.useState(null as any);
    const [openAddSchools, setOpenAddSchools] = React.useState(false);

    let { id } = useParams();

    React.useEffect(() => {
        document.title = `Seo Ranking | DN`;
    }, [id]);
    const reload = async () => {
        setLoading(true);
        let q = gql`
            query q ($id: Int!) {
                seoRanking(id: $id) {
                    id
                    year
                    slug
                    name
                    number_schools
                    rankingFields {
                        name
                        name
                        isAsc
                        percent
                    }
                    seoYear {
                        defaultRankingFields {
                            name
                            isAsc
                            percent
                        }
                    }
                    schools {
                        id
                        data {
                            net_price
                            ratio
                            in_state_tuition
                            out_of_state_tuition
                            graduate_in_state_tuition
                            graduate_out_of_state_tuition
                            acceptance_rate
                            retention_rate
                            grad_rate
                            total_enrollment
                            undergraduate_students
                            graduate_students
                            payscale_salary
                            other_salary                            
                        }
                        isHidden
                        rank
                        score
                        school {
                            id
                            logo_url
                            state
                            college_name
                            website_url
                        }
                    }
                }
            }`;

        let variables = {
            id: parseInt(id || "0", 10)
        };

        let response = await query(q, variables);
        if (!response || !response.seoRanking) {
            return
        }
        setRanking(response.seoRanking);

        if (response.seoRanking.rankingFields && response.seoRanking.rankingFields.length > 0) {
            setRankingFields(response.seoRanking.rankingFields);
            setIsCustom(true);
        } else {
            setRankingFields(response.seoRanking.seoYear.defaultRankingFields);
            setIsCustom(false);
        }
        setLoading(false);
    }

    const setFields = async (fields: any) => {
        const variables = {
            id: parseInt(id || "0", 10),
            input: fields
        };
        const q = gql`
            mutation m ($input: [SeoRankingFieldWrite], $id: Int!) {
                seoRankingUpdateRankingFields(input: $input, id: $id)
            }
        `;

        const response = await mutate(q, variables);
        if (response && response.seoRankingUpdateRankingFields) {
            reload();
            return true;
        }
        return false;
    }
    React.useEffect(() => {
        reload();
    }, [id]);

    const download = async () => {
        const content = [];
        const header = [];

        header.push('Rank');
        header.push('Id');
        header.push('School');
        header.push('State');
        header.push('Website');
        rankingFields.forEach((field: any) => {
            header.push(field.name);
        });
        header.push('Enrollment')
        header.push('Undergrad.')
        header.push('Grad.')
        header.push('Score');

        content.push(header);

        ranking.schools.forEach((row: any, index: number) => {
            const line = [];
            line.push(row.rank);
            line.push(row.school.id);
            line.push(row.school.college_name);
            line.push(row.school.state);
            line.push(row.school.website_url);
            rankingFields.forEach((field: any) => {
                line.push(row.data[field.name]);
            });
            line.push(row.data.total_enrollment);
            line.push(row.data.undergraduate_students);
            line.push(row.data.graduate_students);
            line.push(row.score);
            content.push(line);
        });

        downloadCsv(`${ranking.name.replace(/\s+/g, '-')}.csv`, content).then(() => {});
    }

    if (!ranking || !rankingFields) {
        return null;
    }

    let criteriaTitle = `${ranking.year} Default Ranking Criteria`;
    let editButtonLabel = 'Customize';
    let showBackButton = false;
    if (isCustom) {
        criteriaTitle = `Custom Ranking Criteria`;
        editButtonLabel = '';
        showBackButton = true;
    }

    return (
        <>
            {loading ? <Loading/> : <>
            <h2>SEO Ranking: {ranking.year} - {ranking.name}
                <Button component={Link} variant="outlined" size="small" sx={{margin: '0 5px'}} href={`/seoRankings/${ranking.year}`}>&larr; Back</Button>
                <Button aria-label="Add" onClick={() => { setOpenAddSchools(true) }} variant={'contained'} color={'warning'} size="small" sx={{margin: '0 5px'}}>
                    <AddBoxOutlinedIcon />&nbsp;Add School(s)
                </Button>
                {!!ranking && !!ranking.schools && ranking.schools.length > 0 && !loading && <FormControl sx={{minWidth: 120}} >
                    <Button aria-label="download" onClick={() => { download() }} variant={'contained'} color={'success'} size="small"sx={{margin: '4px 0 0 5px'}}>
                        <FileDownloadIcon/>&nbsp;Export
                    </Button>
                </FormControl>}
            </h2>
                <SeoRankingFields fields={rankingFields} setFields={setFields} title={criteriaTitle} editButtonLabel={editButtonLabel} showBackButton={showBackButton}/>

                {!ranking.schools || ranking.schools.length === 0 ? <Alert severity="warning">No results found</Alert> :
                    <>
                        <Box sx={{margin: '10px 0', fontWeight: 'bold', fontSize: '13px', display:"flex",}}>
                            Showing 1 to {ranking.schools.length.toLocaleString(undefined, {maximumFractionDigits: 0})}.
                        </Box>
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)', maxHeight: 'calc(100vh - 220px)'}}>
                            <Table size="small"
                                   sx={{
                                       "& .MuiTableRow-root:hover": {
                                           backgroundColor: "primary.light"
                                       }
                                   }}
                                   stickyHeader={true}
                            >
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell align={'right'}>
                                            Rank
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            Id
                                        </TableCell>
                                        <TableCell>
                                            School
                                        </TableCell>
                                        <TableCell>
                                            State
                                        </TableCell>
                                        <TableCell>
                                            Website
                                        </TableCell>
                                        {!!rankingFields.find(x=>x.name === 'net_price') && <TableCell align={"right"}>
                                            Net Price
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'ratio') && <TableCell align={"right"}>
                                            Ratio
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'in_state_tuition') && <TableCell align={"right"}>
                                            In-State
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'out_of_state_tuition') && <TableCell align={"right"}>
                                            Out-State
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'graduate_in_state_tuition') && <TableCell align={"right"}>
                                            Grad In-State
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'graduate_out_of_state_tuition') && <TableCell align={"right"}>
                                            Grad Out-State
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'acceptance_rate') && <TableCell align={"right"}>
                                            Acceptance
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'retention_rate') && <TableCell align={"right"}>
                                            Retention
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'grad_rate') && <TableCell align={"right"}>
                                            Grad Rate
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'total_enrollment' || true) && <TableCell align={"right"}>
                                            Enrollment
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'undergraduate_students' || true) && <TableCell align={"right"}>
                                            Undergrad.
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'graduate_students' || true) && <TableCell align={"right"}>
                                            Grad.
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'payscale_salary') && <TableCell align={"right"}>
                                            Payscale Sal.
                                        </TableCell>}
                                        {!!rankingFields.find(x=>x.name === 'other_salary') && <TableCell align={"right"}>
                                            Other Sal.
                                        </TableCell>}
                                        <TableCell align={'right'}>
                                            Score
                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {ranking.schools.map((row: any, index: number) => (
                                        <React.Fragment key={row.school.id}>
                                            <StripedTableRow sx={{opacity: row.isHidden ? .4 : 1}}>
                                                <TableCell align={'right'}>
                                                    <span style={{fontWeight: row.rank > 0 ? 'bold' : 'normal'}}>{row.rank}.</span>
                                                </TableCell>
                                                <TableCell align={'right'}>
                                                    {row.school.id}
                                                </TableCell>
                                                <TableCell>
                                                    {row.school.college_name} {!!row.school.logo_url && <img src={row.school.logo_url} style={{height: '20px', verticalAlign: 'bottom'}}/>}
                                                </TableCell>
                                                <TableCell>
                                                    {row.school.state}
                                                </TableCell>
                                                <TableCell>
                                                    <Link href={row.school.website_url} target="_blank" rel="noreferrer">{row.school.website_url}</Link>
                                                </TableCell>
                                                {!!rankingFields.find(x=>x.name === 'net_price') && <TableCell align={"right"}>
                                                    ${row.data.net_price.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'ratio') && <TableCell align={"right"}>
                                                    {row.data.ratio.toLocaleString(undefined, {maximumFractionDigits: 0})}:1
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'in_state_tuition') && <TableCell align={"right"}>
                                                    ${row.data.in_state_tuition.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'out_of_state_tuition') && <TableCell align={"right"}>
                                                    ${row.data.out_of_state_tuition.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'graduate_in_state_tuition') && <TableCell align={"right"}>
                                                    ${row.data.graduate_in_state_tuition.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'graduate_out_of_state_tuition') && <TableCell align={"right"}>
                                                    ${row.data.graduate_out_of_state_tuition.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'acceptance_rate') && <TableCell align={"right"}>
                                                    {row.data.acceptance_rate.toLocaleString(undefined, {maximumFractionDigits: 0})}%
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'retention_rate') && <TableCell align={"right"}>
                                                    {row.data.retention_rate.toLocaleString(undefined, {maximumFractionDigits: 0})}%
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'grad_rate') && <TableCell align={"right"}>
                                                    {row.data.grad_rate.toLocaleString(undefined, {maximumFractionDigits: 0})}%
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'total_enrollment' || true) && <TableCell align={"right"}>
                                                    {row.data.total_enrollment.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'undergraduate_students' || true) && <TableCell align={"right"}>
                                                    {row.data.undergraduate_students.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'graduate_students' || true) && <TableCell align={"right"}>
                                                    {row.data.graduate_students.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'payscale_salary') && <TableCell align={"right"}>
                                                    ${row.data.payscale_salary.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                </TableCell>}
                                                {!!rankingFields.find(x=>x.name === 'other_salary') && <TableCell align={"right"}>
                                                    ${row.data.other_salary.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                                </TableCell>}
                                                <TableCell align={'right'}>
                                                    {row.score.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        aria-haspopup="true"
                                                        onClick={(event) => {
                                                            setAnchorEls({...anchorEls, [row.school.id]: event.currentTarget});
                                                        }}
                                                    >
                                                        <MoreVertIcon sx={{fontSize: '15px'}} />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEls[row.school.id]}
                                                        open={!!anchorEls[row.school.id]}
                                                        onClose={() => {
                                                            setAnchorEls({...anchorEls, [row.school.id]: null});
                                                        }}
                                                    >
                                                        {!row.isHidden && <MenuItem onClick={() => {
                                                            setSchoolHidden(row);
                                                            setAnchorEls({...anchorEls, [row.school.id]: null});
                                                        }} disableRipple><VisibilityOff sx={{marginRight: 1}} /> Hide School</MenuItem>}
                                                        {row.isHidden && <MenuItem onClick={() => {
                                                            setSchoolHidden(row);
                                                            setAnchorEls({...anchorEls, [row.school.id]: null});
                                                        }} disableRipple><Visibility sx={{marginRight: 1}} /> Un-Hide School</MenuItem>}
                                                        <MenuItem onClick={() => {
                                                            setSchoolDelete(row);
                                                            setAnchorEls({...anchorEls, [row.school.id]: null});
                                                        }} disableRipple><Delete sx={{marginRight: 1}} /> Remove School</MenuItem>
                                                    </Menu>
                                                </TableCell>
                                            </StripedTableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                }
        </>
            }
            <SeoRankingSchoolDelete school={schoolDelete} setSchool={setSchoolDelete} reload={reload}/>
            <SeoRankingSchoolHide school={schoolHidden} setSchool={setSchoolHidden} reload={reload}/>
            <SeoRankingSchoolsAdd id={parseInt(id || "0")} open={openAddSchools} setOpen={setOpenAddSchools} reload={reload}/>
            </>

    );
}
