import * as React from 'react';
import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import Loading from "../loading";
import {
    Alert,
    Chip,
    FormControl, IconButton,
    InputLabel,
    ListItem,
    MenuItem,
    Paper,
    Select, Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import DateRange, {RangeToTimes} from "../DateRange";
import moment from "moment";
import TableHeaderRow from "../TableHeaderRow";
import StripedTableRow from "../StripedTableRow";
import TableFooterRow from "../TableFooterRow";
import delay from "../../lib/delay";
import MultiSelect from "../multiSelect/multiSelect";
import {searchPubs} from "../../pages/blockedPublishers/blockedPublishersService";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import theme2 from "../../lib/theme";
import CachedIcon from "@mui/icons-material/Cached";
import {toast} from "react-toastify";
import success = toast.success;

export default function PubConversions(params: {pubId: number, conversions: any, setConversions: any}) {

    React.useEffect(() => {
        if (!params.conversions.dateRange.length) {
            updateDateRange(RangeToTimes("7 to 45 Days"));
        }
    }, []);

    const updateDateRange = async (value: any) => {
        const pubIds = params.conversions.selectedPublishers.map((x: any) => x.id);
        params.setConversions({...params.conversions, loading: true, dateRange: value});
        const qLO = gql`
            query ConversionStatsLinkout($searchCriteria: ConversionStatsLinkoutSearchCriteria!) {
                conversionStatsLinkout(searchCriteria: $searchCriteria) {
                    columns {
                        dataType
                        value
                    }
                    impressions
                    leads
                    apps
                    enrolls
                    starts
                }
            }
        `;

        const paramsLO = {
            searchCriteria: {
                dataTypes: ["school"],
                startDate: moment(value[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(value[1]).format("YYYY-MM-DD HH:mm:ss"),
                uniqueLeads: true,
                minResults: 1,
                brokerIds: [283, 147, 68, 172, 90],
                companyCodes: ["DN"],
                publisherIds: pubIds,
                publisherIdsExclude: null
            }
        }

        const paramsLOOthers = {
            searchCriteria: {
                dataTypes: ["school"],
                startDate: moment(value[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(value[1]).format("YYYY-MM-DD HH:mm:ss"),
                uniqueLeads: true,
                minResults: 1,
                brokerIds: [283, 147, 68, 172, 90],
                companyCodes: ["DN"],
                publisherIds: null,
                publisherIdsExclude: pubIds
            }
        }

        const qHP = gql`
            query ConversionStats($searchCriteria: ConversionStatsSearchCriteria!) {
                conversionStats(searchCriteria: $searchCriteria) {
                    columns {
                        dataType
                        value
                    }
                    leads
                    apps
                    enrolls
                    starts
                }
            }
        `;

        const paramsHP = {
            searchCriteria: {
                dataTypes: ["school"],
                startDate: moment(value[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(value[1]).format("YYYY-MM-DD HH:mm:ss"),
                uniqueLeads: true,
                minResults: 1,
                brokerIds: [199, 94, 172, 283, 147, 313, 285, 73, 329],
                companyCodes: ["DN"],
                publisherIds: pubIds,
                publisherIdsExclude: null
            }
        }


        const paramsHPOthers = {
            searchCriteria: {
                dataTypes: ["school"],
                startDate: moment(value[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(value[1]).format("YYYY-MM-DD HH:mm:ss"),
                uniqueLeads: true,
                minResults: 1,
                brokerIds: [199, 94, 172, 283, 147, 313, 285, 73, 329],
                companyCodes: ["DN"],
                publisherIds: null,
                publisherIdsExclude: pubIds
            }
        }

        const diffInDays = moment(value[1]).diff(moment(value[0]), 'days');

        let all;

        if (diffInDays > 95) {
            all = await getFromQueue(paramsLO, paramsLOOthers, paramsHP, paramsHPOthers);
        }
        else {
            all = await Promise.all([query(qLO, paramsLO), query(qLO, paramsLOOthers), query(qHP, paramsHP), query(qHP, paramsHPOthers)]);

        }

        let d = [] as any[];
        let r = {} as any;
        let totals = {impressions: 0, leads: 0, apps: 0, starts: 0, appsOthers: 0, startsOthers: 0, leadPercent: 0, appPercent: 0, startPercent: 0, appPercentOthers: 0, startPercentOthers: 0, leadsOthers: 0}
        all[0].conversionStatsLinkout.forEach((row: any) => {
            r = {};
            r.school = row.columns[0].value;
            r.impressions = row.impressions;
            r.leads = row.leads;
            r.apps = row.apps;
            r.starts = row.starts;
            r.leadPercent = row.leads / row.impressions * 100 || 0;
            r.appPercent = row.apps / row.leads * 100 || 0;
            r.startPercent = row.starts / row.leads * 100 || 0;
            r.appsOthers = 0;
            r.startsOthers = 0;
            r.appPercentOthers = 0;
            r.startPercentOthers = 0;
            d.push(r);

            totals.impressions += row.impressions;
            totals.leads += row.leads;
            totals.apps += row.apps;
            totals.starts += row.starts;
        });

        totals.leadPercent = totals.leads / totals.impressions * 100 || 0;
        totals.appPercent = totals.apps / totals.leads * 100 || 0;
        totals.startPercent = totals.starts / totals.leads * 100 || 0;

        all[1].conversionStatsLinkout.forEach((row: any) => {
            r = d.find((x: any) => x.school === row.columns[0].value);
            if (r) {
                r.appsOthers = row.apps;
                r.startsOthers = row.starts;
                r.appPercentOthers = row.apps / row.leads * 100 || 0;
                r.startPercentOthers = row.starts / row.leads * 100 || 0;
            }

            totals.appsOthers += row.apps;
            totals.startsOthers += row.starts;
            totals.leadsOthers += row.leads;
        });

        totals.appPercentOthers = totals.appsOthers / totals.leadsOthers * 100 || 0;
        totals.startPercentOthers = totals.startsOthers / totals.leadsOthers * 100 || 0;

        let d2 = [] as any[];
        let totals2 = {impressions: 0, leads: 0, apps: 0, starts: 0, appsOthers: 0, startsOthers: 0, appPercent: 0, startPercent: 0, appPercentOthers: 0, startPercentOthers: 0, leadsOthers: 0}
        all[2].conversionStats.forEach((row: any) => {
            r = {};
            r.school = row.columns[0].value;
            r.leads = row.leads;
            r.apps = row.apps;
            r.starts = row.starts;
            r.appPercent = row.apps / row.leads * 100 || 0;
            r.startPercent = row.starts / row.leads * 100 || 0;
            r.appsOthers = 0;
            r.startsOthers = 0;
            r.appPercentOthers = 0;
            r.startPercentOthers = 0;
            d2.push(r);

            totals2.leads += row.leads;
            totals2.apps += row.apps;
            totals2.starts += row.starts;
        });

        totals2.appPercent = totals2.apps / totals2.leads * 100 || 0;
        totals2.startPercent = totals2.starts / totals2.leads * 100 || 0;

        all[3].conversionStats.forEach((row: any) => {
            r = d2.find((x: any) => x.school === row.columns[0].value);
            if (r) {
                r.appsOthers = row.apps;
                r.startsOthers = row.starts;
                r.appPercentOthers = row.apps / row.leads * 100 || 0;
                r.startPercentOthers = row.starts / row.leads * 100 || 0;
            }

            totals2.appsOthers += row.apps;
            totals2.startsOthers += row.starts;
            totals2.leadsOthers += row.leads;
        });

        totals2.appPercentOthers = totals2.appsOthers / totals2.leadsOthers * 100 || 0;
        totals2.startPercentOthers = totals2.startsOthers / totals2.leadsOthers * 100 || 0;

        params.setConversions({...params.conversions, loading: false, dateRange: value, data: {linkout: d, hp: d2}, totals: {linkout: totals, hp: totals2}});
    }

    const reload = () => {
        updateDateRange(params.conversions.dateRange);
    }

    const setSelectedPublishers = (items: any[]) => {
        params.setConversions({...params.conversions, selectedPublishers: items});
    }

    return (
        <>
            {params.conversions.loading ? <Box sx={{padding: 3}}><Loading/></Box> :
                <Box sx={{padding: '0 10px 10px 10px'}}>
                    <Box sx={{display: 'flex', flexDirection: 'row', margin: 0, padding: 1, flexFlow: 'wrap'}}>
                        <DateRange showTime={false} dateRange={params.conversions.dateRange}
                                   setDateRange={updateDateRange} style={{marginBottom: '15px'}}/>
                        <Box sx={{width: "350px", marginLeft: 'auto', display: 'flex'}}>
                            <MultiSelect
                                searchFunction={searchPubs}
                                selectedItems={params.conversions.selectedPublishers}
                                setSelectedItems={setSelectedPublishers}
                                placeholder={"Select Publishers"}

                            />
                            <CachedIcon sx={{cursor: 'pointer', margin: '5px 0 0 5px'}} onClick={reload}
                                        color={"success"}/>
                        </Box>
                    </Box>
                    <h2 style={{margin: 0}}>HP Conversions</h2>
                    {(!params.conversions.data || !params.conversions.data.hp || !params.conversions.data.hp.length) && <Alert severity="warning">No HP Conversions Found</Alert>}

                    {params.conversions.data && !!params.conversions.data.hp && !!params.conversions.data.hp.length &&
                        <>
                            <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 20px)'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableHeaderRow>
                                            <TableCell>School</TableCell>
                                            <TableCell align="right">Leads</TableCell>
                                            <TableCell align="right">Apps</TableCell>
                                            <TableCell align="right">Apps Others</TableCell>
                                            <TableCell align="right">Starts</TableCell>
                                            <TableCell align="right">Starts Others</TableCell>
                                        </TableHeaderRow>
                                    </TableHead>
                                    <TableBody>
                                        {params.conversions.data.hp.map((row: any) => (
                                            <StripedTableRow key={row.school}>
                                                <TableCell sx={{whiteSpace: "nowrap"}}>{row.school}</TableCell>
                                                <TableCell align="right">{row.leads.toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                                <TableCell align="right">{row.apps.toLocaleString(undefined, {maximumFractionDigits:0})} ({row.appPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                                <TableCell align="right">{row.appsOthers.toLocaleString(undefined, {maximumFractionDigits:0})} ({row.appPercentOthers.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                                <TableCell align="right">{row.starts.toLocaleString(undefined, {maximumFractionDigits:0})} ({row.startPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                                <TableCell align="right">{row.startsOthers.toLocaleString(undefined, {maximumFractionDigits:0})} ({row.startPercentOthers.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>

                                            </StripedTableRow>
                                        ))}
                                        <TableFooterRow>
                                            <TableCell>Totals</TableCell>
                                            <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.hp.leads.toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                            <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.hp.apps.toLocaleString(undefined, {maximumFractionDigits:0})} ({params.conversions.totals.hp.appPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                            <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.hp.appsOthers.toLocaleString(undefined, {maximumFractionDigits:0})} ({params.conversions.totals.hp.appPercentOthers.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                            <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.hp.starts.toLocaleString(undefined, {maximumFractionDigits:0})} ({params.conversions.totals.hp.startPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                            <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.hp.startsOthers.toLocaleString(undefined, {maximumFractionDigits:0})} ({params.conversions.totals.hp.startPercentOthers.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                        </TableFooterRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }

                    <h2 style={{marginBottom:0}}>Linkout Conversions</h2>
                    {(!params.conversions.data || !params.conversions.data.linkout || !params.conversions.data.linkout.length) && <Alert severity="warning">No Linkout Conversions Found</Alert>}
                    {params.conversions.data && !!params.conversions.data.linkout && !!params.conversions.data.linkout.length &&
                        <>

                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 20px)'}}>
                            <Table size="small">
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell>School</TableCell>
                                        <TableCell align="right">Imp</TableCell>
                                        <TableCell align="right">Leads</TableCell>
                                        <TableCell align="right">Apps</TableCell>
                                        <TableCell align="right">Apps Others</TableCell>
                                        <TableCell align="right">Starts</TableCell>
                                        <TableCell align="right">Starts Others</TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {params.conversions.data.linkout.map((row: any) => (
                                        <StripedTableRow key={row.school}>
                                            <TableCell sx={{whiteSpace: "nowrap"}}>{row.school}</TableCell>
                                            <TableCell align="right">{row.impressions.toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                            <TableCell align="right">{row.leads.toLocaleString(undefined, {maximumFractionDigits:0})} ({row.leadPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                            <TableCell align="right">{row.apps.toLocaleString(undefined, {maximumFractionDigits:0})} ({row.appPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                            <TableCell align="right">{row.appsOthers.toLocaleString(undefined, {maximumFractionDigits:0})} ({row.appPercentOthers.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                            <TableCell align="right">{row.starts.toLocaleString(undefined, {maximumFractionDigits:0})} ({row.startPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                            <TableCell align="right">{row.startsOthers.toLocaleString(undefined, {maximumFractionDigits:0})} ({row.startPercentOthers.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>

                                        </StripedTableRow>
                                    ))}
                                <TableFooterRow>
                                    <TableCell>Totals</TableCell>
                                    <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.linkout.impressions.toLocaleString(undefined, {maximumFractionDigits:0})}</TableCell>
                                    <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.linkout.leads.toLocaleString(undefined, {maximumFractionDigits:0})} ({params.conversions.totals.linkout.leadPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                    <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.linkout.apps.toLocaleString(undefined, {maximumFractionDigits:0})} ({params.conversions.totals.linkout.appPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                    <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.linkout.appsOthers.toLocaleString(undefined, {maximumFractionDigits:0})} ({params.conversions.totals.linkout.appPercentOthers.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                    <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.linkout.starts.toLocaleString(undefined, {maximumFractionDigits:0})} ({params.conversions.totals.linkout.startPercent.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                    <TableCell align="right" sx={{whiteSpace: "nowrap"}}>{params.conversions.totals.linkout.startsOthers.toLocaleString(undefined, {maximumFractionDigits:0})} ({params.conversions.totals.linkout.startPercentOthers.toLocaleString(undefined, {maximumFractionDigits:1, minimumFractionDigits: 1})}%)</TableCell>
                                </TableFooterRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </>
                    }
                </Box>
            }
        </>
    )

}

async function getFromQueue(paramsLO: any, paramsLOOthers: any, paramsHP: any, paramsHPOthers: any) {

    const q = gql`
        query q ($s0: ConversionStatsLinkoutSearchCriteria!, $s1: ConversionStatsLinkoutSearchCriteria!, $s2: ConversionStatsSearchCriteria!, $s3: ConversionStatsSearchCriteria!) {
            a0: conversionStatsLinkoutAsyncRequest(searchCriteria: $s0)
            a1: conversionStatsLinkoutAsyncRequest(searchCriteria: $s1)
            a2: conversionStatsAsyncRequest(searchCriteria: $s2)
            a3: conversionStatsAsyncRequest(searchCriteria: $s3)
        }
    `;

    const results = await query(q, {s0: paramsLO.searchCriteria, s1: paramsLOOthers.searchCriteria, s2: paramsHP.searchCriteria, s3: paramsHPOthers.searchCriteria});

    const all = await Promise.all([checkQueueLinkout(results.a0), checkQueueLinkout(results.a1), checkQueue(results.a2), checkQueue(results.a3)]);
    return [{conversionStatsLinkout: all[0]}, {conversionStatsLinkout: all[1]}, {conversionStats: all[2]}, {conversionStats: all[3]}]
}

async function checkQueueLinkout(id: string) {
    const q = gql`
        query q ($id: String!) {
            conversionStatsLinkoutAsyncGet(id: $id) {
                columns {
                    dataType
                    value
                }
                impressions
                leads
                apps
                enrolls
                starts
            }
        }
    `;

    while (true) {
        const results = await query(q, {id});
        if (results.conversionStatsLinkoutAsyncGet) {
            return results.conversionStatsLinkoutAsyncGet;
        }
        await delay(2000);
    }
}

async function checkQueue(id: string) {
    const q = gql`
        query q ($id: String!) {
            conversionStatsAsyncGet(id: $id) {
                columns {
                    dataType
                    value
                }
                leads
                apps
                enrolls
                starts
            }
        }
    `;

    while (true) {
        const results = await query(q, {id});
        if (results.conversionStatsAsyncGet) {
            return results.conversionStatsAsyncGet;
        }
        await delay(2000);
    }
}
