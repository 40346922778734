import * as React from "react";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, FormGroup,
    InputLabel, MenuItem, Select
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import TextField from "@mui/material/TextField";
import {Code} from "@mui/icons-material";
export default function PublisherPixel (params: {pub: any, setPub: any, reload: any}) {
    const [loading, setLoading] = React.useState(false);
    const [pixelType, setPixelType] = React.useState('None' as 'None' | 'PIGGYBACK' | 'AFTER_EMAIL');
    const [pixel, setPixel] = React.useState('');
    const [percent, setPercent] = React.useState(0);

    React.useEffect(() => {
        if (params.pub) {
            setPixelType(params.pub.pixel ? params.pub.pixel.type : 'None');
            setPixel(params.pub.pixel ? params.pub.pixel.pixel : '');
            setPercent(params.pub.pixel ? params.pub.pixel.percentFire : 0);
        }
    }, [params.pub]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setPub(null);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        if (pixelType == 'None') {
            q = gql`
                mutation m($id: Int!) {
                    publisherPixelUpsertNone(id: $id) {
                        id
                    }
                }
            `;

            variables = {
                id: params.pub.id,
            }

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.publisherPixelUpsertNone && res.publisherPixelUpsertNone.id;
        } else  if (pixelType == 'PIGGYBACK') {
            q = gql`
                mutation m($id: Int!, $pixel: PublisherPiggyBackPixelWrite!) {
                    publisherPixelUpsertPiggyback(id: $id, pixel: $pixel) {
                        id
                    }
                }
            `;

            variables = {
                id: params.pub.id,
                pixel: {
                    pixel
                }
            }

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.publisherPixelUpsertPiggyback && res.publisherPixelUpsertPiggyback.id;
        } else  if (pixelType == 'AFTER_EMAIL') {
            q = gql`
                mutation m($id: Int!, $pixel: PublisherAfterEmailPixelWrite!) {
                    publisherPixelUpsertAfterEmail(id: $id, pixel: $pixel) {
                        id
                    }
                }
            `;

            variables = {
                id: params.pub.id,
                pixel: {
                    pixel,
                    percentFire: percent
                }
            }

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.publisherPixelUpsertAfterEmail && res.publisherPixelUpsertAfterEmail.id;
        }

        if (good) {
            toast.success('Pixel updated successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }

    }

    if (!params.pub) return <> </>;

    return (
        <>
                <Dialog
                    open={!!params.pub}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'lg'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Set up Pixel for ${params.pub.name}`}
                    </DialogTitle>
                    <DialogContent>
                        <FormGroup sx={{marginTop: '10px'}}>
                            <FormControl fullWidth size={"small"}>
                                <InputLabel id="demo-simple-select-label">Pixel Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={pixelType}
                                    label="Pixel Type"
                                    onChange={(event) => setPixelType(event.target.value as 'None' | 'PIGGYBACK' | 'AFTER_EMAIL')}
                                >
                                    <MenuItem value={'None'}>None</MenuItem>
                                    <MenuItem value={'PIGGYBACK'}>Piggyback</MenuItem>
                                    <MenuItem value={'AFTER_EMAIL'}>After Email</MenuItem>
                                </Select>
                            </FormControl>

                            {pixelType == 'PIGGYBACK' && <>
                                <Alert severity={"info"} sx={{marginTop: 2}}>
                                    Copy and paste any Facebook, GTag, TikTok or other piggyback pixels here. You can stack multiple pixels here as well.
                                </Alert>

                                <FormControl fullWidth size={"small"} sx={{marginTop: 2}}>
                                    <TextField
                                        label="Pixel Code"
                                        multiline
                                        rows={4}
                                        placeholder={"Paste pixel code here"}
                                        value={pixel}
                                        onChange={(e: any) => setPixel(e.target.value)}
                                        inputProps={{
                                            style: {
                                                resize: 'vertical'
                                            }
                                        }}
                                    />
                                </FormControl>


                                </>
                            }

                            {pixelType == 'AFTER_EMAIL' && <>
                                <Alert severity={"info"} sx={{marginTop: 2}}>
                                    This is currently reserved for Snapchat pixels. These get fired after the email submit page, before the contact page. Sample pixel: <br/>

<pre>{`
snaptr('init', '853bac91-5d89-40b0-b625-a6a942da14e4', {
'user_email': $('#txtemail').val()
});
snaptr('track', 'PURCHASE');
    `}
</pre>
                                        <b>Note: </b> Replace '__INSERT_USER_EMAIL__' with $('#txtemail').val()
                                </Alert>

                                <FormControl fullWidth size={"small"} sx={{marginTop: 2}}>
                                    <TextField
                                        label="Pixel Code"
                                        multiline
                                        rows={4}
                                        placeholder={"Paste pixel code here"}
                                        value={pixel}
                                        onChange={(e: any) => setPixel(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl fullWidth size={"small"} sx={{marginTop: 2}}>
                                    <InputLabel>Percent Fire</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={percent}
                                        label="Percent Fire"
                                        onChange={(event) => setPercent(event.target.value as number)}
                                    >
                                        {[0, 10, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100].map((p: number) =>
                                        <MenuItem key={p} value={p}>{p}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                            </>
                            }
                        </FormGroup>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
