import * as React from 'react';
import Box from "@mui/material/Box";
import {ListItemButton, ListItemText} from "@mui/material";
import theme from "../../lib/theme";

export default function MultiSelectPanel(props: { items: any[], isAvailable: boolean, selectedItem: any }) {
return (
    <Box>
        <Box sx={{margin: '10px 0 1px 0', fontSize: '13px'}}>
            {props.isAvailable ? 'Available' : 'Selected'}
        </Box>
        <Box sx={{height:200, width: 163, border: "1px solid #ccc", overflowY: 'scroll', overflowX: 'scroll'
            , "&::-webkit-scrollbar": {width: '5px', height: '5px'}
            , "&::-webkit-scrollbar-thumb": {backgroundColor: '#ccc', borderRadius: '10px'},
            '@media (min-width: 850px)': {
                width: '388px',
            }
        }}>
            {props.items.map((item, index) => {
                return (
                    <ListItemButton key={index}
                                    selected={item.selected}
                                    onClick={(event) => {
                                        props.selectedItem(event, item, props.isAvailable);

                                    }}
                                    sx={{padding: '2px 3px 2px 3px'
                                        , "&.Mui-selected": {backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}
                                        , "&.Mui-selected:hover": {backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}
                    }}

                    ><ListItemText
                        sx={{margin: 0}}
                        primary={item.value}
                        primaryTypographyProps={{fontSize: '13px',whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    /></ListItemButton>
                )
            })}
        </Box>
    </Box>
)
}
