import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, Grid, InputAdornment,
    InputLabel, MenuItem, Select, Switch
} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import {getTimeZoneOptions} from "./brokersService";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import TextField from "@mui/material/TextField";

export default function BrokerUpsert (params: {broker: any, setBroker: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [name, setName] = React.useState('' as string);
    const [rating, setRating] = React.useState('' as string);
    const [nonPaymentPaused, setNonPaymentPaused] = React.useState(true);
    const [creatives, setCreatives] = React.useState(false);
    const [timeZones, setTimeZones] = React.useState([] as any);
    const [timeZone, setTimeZone] = React.useState('' as string);

    React.useEffect(() => {
        getTimeZoneOptions().then((res: any) => {
            setTimeZones(res);
            setLoading(false);
        });
    }, []);

    React.useEffect(() => {
        if (params.broker) {
            setName(params.broker.name || '');
            setRating(params.broker.rating || 'C');
            setTimeZone(params.broker.timeZone || 'America/Los_Angeles');
            setNonPaymentPaused(params.broker.nonPaymentPaused || false);
            setCreatives(params.broker.creatives || false);
        } else {
            setName('');
            setRating('C');
            setTimeZone('America/Los_Angeles');
            setNonPaymentPaused(false);
            setCreatives(false);
        }
    }, [params.broker]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setBroker(null);
    };

    const handleNonPaymentPaused= async (event: React.ChangeEvent<HTMLInputElement>) => {
        setNonPaymentPaused(event.target.checked);
    };

    const handleCreatives= async (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreatives(event.target.checked);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $input: BrokerUpdateInput!) {
                    brokerUpdate(id: $id, input: $input)
                }
            `;

            variables = {
                id: params.broker.id,
                input: {
                    name,
                    rating,
                    timeZone,
                    nonPaymentPaused,
                    creatives
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.brokerUpdate;
        } else {
            q = gql`
                mutation m($input: BrokerCreateInput!) {
                    brokerCreate(input: $input) {
                        id
                    }
                }
            `;

            variables = {
                input: {
                    name,
                    rating,
                    timeZone
                }
            };            

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.brokerCreate && res.brokerCreate.id;
        }

        if (good) {
            toast.success(isEdit ? 'Broker updated successfully' : 'Broker created successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }

    }

    if (!params.broker) return <> </>;

    const isEdit = !!params.broker.id;

    return (
        <>
                <Dialog
                    open={!!params.broker}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'sm'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {isEdit ? `Update Broker ${params.broker.name}` : 'Create Broker'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    size={'small'}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>  
                            <Grid item xs={12}>
                                <FormControl fullWidth size={'small'}>
                                    <InputLabel id="iTimeZone">Time Zone</InputLabel>
                                    <Select
                                        labelId="timeZone"
                                        id="timeZone"
                                        value={timeZone}
                                        onChange={(e) => setTimeZone(e.target.value)}
                                        label="Time Zone"
                                    >                                                                           
                                        {timeZones.timeZones.map((tz: any) => <MenuItem key={tz.timeZone} value={tz.timeZone}>{tz.timeZone}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>                              
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth size={'small'}>
                                    <InputLabel id="iRating">Rating</InputLabel>
                                    <Select
                                        labelId="rating"
                                        id="rating"
                                        value={rating}
                                        onChange={(e) => setRating(e.target.value)}
                                        label="Rating"
                                    >
                                        <MenuItem key='A' value='A'>A</MenuItem>
                                        <MenuItem key='B' value='B'>B</MenuItem>
                                        <MenuItem key='C' value='C'>C</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>  
                            {isEdit && <Grid item xs={12} sm={6}>
                                <FormControlLabel control={<Switch checked={nonPaymentPaused} onChange={handleNonPaymentPaused} />} label="Non-Payment Paused" />
                            </Grid>}   
                            {isEdit && <Grid item xs={12} sm={6}>
                                <FormControlLabel control={<Switch checked={creatives} onChange={handleCreatives} />} label="Approved Creatives Only" />
                            </Grid>}                                                                             
                        </Grid>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
