import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, Grid, InputAdornment,
    InputLabel, MenuItem, Select, Switch
} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import TextField from "@mui/material/TextField";

export default function CompanyUpsert (params: {company: any, setCompany: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [name, setName] = React.useState('' as string);

    React.useEffect(() => {
        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (params.company) {
            setName(params.company.name || '');
        } else {
            setName('');
        }
    }, [params.company]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setCompany(null);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $input: CompanyUpdateInput!) {
                    companyUpdate(id: $id, input: $input)
                }
            `;

            variables = {
                id: params.company.id,
                input: {
                    name
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.companyUpdate;
        } else {
            q = gql`
                mutation m($input: CompanyCreateInput!) {
                    companyCreate(input: $input) {
                        id
                    }
                }
            `;

            variables = {
                input: {
                    name
                }
            };            

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.companyCreate && res.companyCreate.id;
        }

        if (good) {
            toast.success(isEdit ? 'Company updated successfully' : 'Company created successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }

    }

    if (!params.company) return <> </>;

    const isEdit = !!params.company.id;

    return (
        <>
                <Dialog
                    open={!!params.company}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'sm'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {isEdit ? `Update Company ${params.company.name}` : 'Create Company'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    size={'small'}
                                    value={name}
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>                                                                             
                        </Grid>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
