import * as React from 'react';
import {gql} from "@apollo/client";
import {mutate, query} from "../../lib/apollo";
import Loading from "../loading";
import {
    Alert,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup, FormHelperText,
    FormLabel, Input, InputAdornment,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {toast} from "react-toastify";


export default function PubSettings(params: {pub: any, setPub: any}) {
    const [loading, setLoading] = React.useState(false);
    const [allowedOnPathImmediately, setAllowedOnPathImmediately] = React.useState(false);
    const [allowedOnPathOne_to_Three, setAllowedOnPathOne_to_Three] = React.useState(false);
    const [allowedOnPathFour_to_Six, setAllowedOnPathFour_to_Six] = React.useState(false);
    const [allowOnPathSixMonths, setAllowOnPathSixMonths] = React.useState(false);
    const [sellToAImmediately, setSellToAImmediately] = React.useState(false);
    const [sellToAOne_to_Three, setSellToAOne_to_Three] = React.useState(false);
    const [sellToAFour_to_Six, setSellToAFour_to_Six] = React.useState(false);
    const [sellToASixMonths, setSellToASixMonths] = React.useState(false);
    const [epc, setEpc] = React.useState(0);


    React.useEffect(() => {
        if (params.pub) {
            setAllowedOnPathImmediately(params.pub.whenStarts.allowedOnPathImmediately);
            setAllowedOnPathOne_to_Three(params.pub.whenStarts.allowedOnPathOne_to_Three);
            setAllowedOnPathFour_to_Six(params.pub.whenStarts.allowedOnPathFour_to_Six);
            setAllowOnPathSixMonths(params.pub.whenStarts.allowOnPathSixMonths);
            setSellToAImmediately(params.pub.whenStarts.sellToAImmediately);
            setSellToAOne_to_Three(params.pub.whenStarts.sellToAOne_to_Three);
            setSellToAFour_to_Six(params.pub.whenStarts.sellToAFour_to_Six);
            setSellToASixMonths(params.pub.whenStarts.sellToASixMonths);
            setEpc(Math.round(params.pub.maxEpc / 2 * 100) / 100);
        }
    }, []);

    const updateTempMaxEpc = async (epc?: number) => {
        setLoading(true);

        const q = gql`
            mutation($id: Int!, $tempMaxEpc: Float) {
                publisherUpdateTempMaxEpc(id: $id, tempMaxEpc: $tempMaxEpc) {
                    savedMaxEpc
                    maxEpc
                }
            }
        `;

        const variables = {
            id: params.pub.id,
            tempMaxEpc: epc,
        }

        const response = await mutate(q, variables);

        if (response && response.publisherUpdateTempMaxEpc) {
            setLoading(false);

            params.setPub({
                ...params.pub,
                savedMaxEpc: response.publisherUpdateTempMaxEpc.savedMaxEpc,
                maxEpc: response.publisherUpdateTempMaxEpc.maxEpc,
            });

            if (!epc) {
                setEpc(Math.round(response.publisherUpdateTempMaxEpc.maxEpc / 2 * 100) / 100);
            }

            toast.success('Temp Max Epc Updated Successfully!');
        } else {
            setLoading(false);
        }
    }

    const updateWhenStarts = async () => {
        setLoading(true);

        const q = gql`
            mutation($id: Int!, $whenStarts: PublisherWhenStartsWrite!) {
                publisherUpdateWhenStarts(id: $id, whenStarts: $whenStarts) {
                    whenStarts {
                        allowedOnPathImmediately
                        allowedOnPathOne_to_Three
                        allowedOnPathFour_to_Six
                        allowOnPathSixMonths
                        sellToAImmediately
                        sellToAOne_to_Three
                        sellToAFour_to_Six
                        sellToASixMonths                        
                    }
                }
            }
        `;

        const variables = {
            id: params.pub.id,
            whenStarts: {
                allowedOnPathImmediately,
                allowedOnPathOne_to_Three,
                allowedOnPathFour_to_Six,
                allowOnPathSixMonths,
                sellToAImmediately,
                sellToAOne_to_Three,
                sellToAFour_to_Six,
                sellToASixMonths,
            },
        }

        const response = await mutate(q, variables);

        if (response && response.publisherUpdateWhenStarts) {
            setLoading(false);

            params.setPub({
                ...params.pub,
                whenStarts: response.publisherUpdateWhenStarts.whenStarts,
            });

            toast.success('When Start Settings Updated Successfully!');
        } else {
            setLoading(false);
        }
    }

    return (
        <>
            {loading ? <Box sx={{padding: 3}}><Loading/></Box> :
                <Box sx={{padding: '10px'}}>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Allowed on Path</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={allowedOnPathImmediately} onChange={(event) => setAllowedOnPathImmediately(event.target.checked)}/>
                                }
                                label="Immediately"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={allowedOnPathOne_to_Three} onChange={(event) => setAllowedOnPathOne_to_Three(event.target.checked)}/>
                                }
                                label="1-3 Months"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={allowedOnPathFour_to_Six} onChange={(event) => setAllowedOnPathFour_to_Six(event.target.checked)} />
                                }
                                label="4-6 Months"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={allowOnPathSixMonths} onChange={(event) => setAllowOnPathSixMonths(event.target.checked)} />
                                }
                                label="6 Months or More"
                                />
                        </FormGroup>
                        <FormHelperText>If unchecked, user will be redirected to ClickFeed as soon as they pick When Start</FormHelperText>
                    </FormControl>

                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Sell to A Campaigns</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={sellToAImmediately} onChange={(event) => setSellToAImmediately(event.target.checked)}/>
                                }
                                label="Immediately"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={sellToAOne_to_Three} onChange={(event) => setSellToAOne_to_Three(event.target.checked)}/>
                                }
                                label="1-3 Months"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={sellToAFour_to_Six} onChange={(event) => setSellToAFour_to_Six(event.target.checked)} />
                                }
                                label="4-6 Months"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={sellToASixMonths} onChange={(event) => setSellToASixMonths(event.target.checked)} />
                                }
                                label="6 Months or More"
                            />
                        </FormGroup>
                        <FormHelperText>If unchecked, leads will be sold to B campaigns only</FormHelperText>

                        <FormControl size="small" sx={{margin: '30px 10px 10px 10px'}}>
                            <Button onClick={updateWhenStarts} variant={'contained'}>
                                Save All When Starts
                            </Button>
                        </FormControl>
                    </FormControl>

                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Temp Max EPC</FormLabel>
                        <Box sx={{paddingTop: 1}}>Actual Max EPC: <span style={{fontWeight: 'bold'}}>${(params.pub.savedMaxEpc || params.pub.maxEpc).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span></Box>
                        <Box sx={{paddingTop: 1}}>Temp Max EPC: <span style={{fontWeight: 'bold'}}>{params.pub.savedMaxEpc ? `$${params.pub.maxEpc.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}` : 'N/A'}</span></Box>

                        {!params.pub.maxEpc && <Alert severity={"warning"}>This pub does not have a Max Epc</Alert>}

                        {!!params.pub.maxEpc && !!params.pub.savedMaxEpc &&
                            <FormControl size="small">
                                <Button onClick={() => {updateTempMaxEpc(undefined).then(() => {})}} variant={'contained'} color={'warning'}>
                                    <HighlightOffIcon />&nbsp;Remove Temp Max EPC
                                </Button>
                            </FormControl>
                        }

                        {!!params.pub.maxEpc && !params.pub.savedMaxEpc &&
                            <FormGroup row={true}>
                                <FormControl variant="outlined" size="small" sx={{maxWidth: 120}}>
                                    <TextField
                                        autoComplete={'off'}
                                        margin="dense"
                                        id="maxEpc"
                                        label="Temp Max Epc"
                                        type="number"
                                        size={'small'}
                                        value={epc}
                                        onChange={(e) => setEpc(parseFloat(e.target.value || "0"))}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        inputProps={{
                                            step: ".01"
                                        }}
                                    />
                                </FormControl>
                                <FormControl size="small" sx={{margin: '9px 0 0 2px'}}>
                                    <Button onClick={() => {updateTempMaxEpc(epc).then(() => {})}} variant={'contained'}>
                                        Set
                                    </Button>
                                </FormControl>
                            </FormGroup>
                        }
                        <FormHelperText>Set a lower value to handicap</FormHelperText>
                    </FormControl>
                </Box>}
        </>
    )

}
