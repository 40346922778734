import * as React from "react";
import DateRange, {RangeToTimes} from "../../components/DateRange";
import {gql} from "@apollo/client";
import moment from "moment/moment";
import {query} from "../../lib/apollo";
import Loading from "../../components/loading";
import {
    Alert, FormControl, Grid, IconButton,
    Input,
    InputAdornment,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer, TableFooter,
    TableHead,
    TableRow
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import useDebounce from "../../lib/UseDebounce";
import PublisherCreditDelete from "./publisherCreditDelete";
import theme from "../../lib/theme";
import PublisherCreditCreate from "./publisherCreditCreate";
import StripedTableRow from "../../components/StripedTableRow";
import TableFooterRow from "../../components/TableFooterRow";
import TableHeaderRow from "../../components/TableHeaderRow";

export default function PublisherCreditsPage() {

    const [dateRange, setDateRange] = React.useState([] as any);
    const [results, setResults] = React.useState([] as any);
    const [loading, setLoading] = React.useState(true);
    const [search, setSearch] = React.useState<string>('');
    const [total, setTotal] = React.useState(0);
    const [deleteCredit, setDeleteCredit] = React.useState(null as any);
    const [addCredit, setAddCredit] = React.useState(false);

    React.useEffect(() => {
        document.title = "Publisher Credits | DN";
    }, []);

    const updateDateRange = async (value: any, s: string) => {
        setDateRange(value);
        setLoading(true);

        const q = gql`
            query q($input: PurchaseCreditsInput!) {
                purchaseCredits(input: $input) {
                    publisher_id
                    publisher_lead_hit_id
                    publisher_name
                    amount
                    date
                    
                }
            }
        `;

        const variables = {
            input: {
                startDate: moment(value[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(value[1]).format("YYYY-MM-DD HH:mm:ss"),
                search: s
            }
        }

        const response = await query(q, variables);

        if (response && response.purchaseCredits) {
            setResults(response.purchaseCredits);

            let t = 0;
            response.purchaseCredits.forEach((x: any) => {
                t += x.amount;
            });

            setTotal(t);
        }

        setLoading(false);
    }

    useDebounce(() => {
        let d = dateRange;
        if (d.length === 0) {
            d = RangeToTimes("Month to Date");
        }

        updateDateRange(d, search).then(x=>{});
        }, [search], 400
    );

    const reload = () => {
        updateDateRange(dateRange, search).then(x=>{});
    }

    return (
        <>
            <h2>Publisher Credits</h2>

                    <Grid container>
                        <Grid item xs={6} md={4}>
                            <DateRange showTime={false} dateRange={dateRange} setDateRange={updateDateRange} style={{}}/>
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <FormControl sx={{minWidth: 120, marginLeft: '10px' }} size="small">
                                <Button aria-label="Add" onClick={() => {setAddCredit(true)}} variant={'contained'} color={'warning'}>
                                    <AddBoxOutlinedIcon />&nbsp;Add Credit
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                id="search"
                                type="text"
                                spellCheck="false"
                                placeholder="Search"
                                value={search || ''}
                                onChange={(e: any) => {setSearch(e.target.value);} }
                                startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                                sx={{maxWidth: '200px', marginLeft: 'auto'}}
                            />
                        </Grid>
                    </Grid>
            {loading ? <Loading/> :
                <>
                    <p></p>
                    {results.length === 0 ? <Alert severity="warning">No results found in date range</Alert> :
                        <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)'}}>
                            <Table size={"small"}>
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableCell align="right">Date</TableCell>
                                        <TableCell>Publisher</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    {results.map((row: any) => {
                                        return (
                                            <StripedTableRow key={row.publisher_id}>
                                                <TableCell
                                                    align="right">{moment(row.date).format("MM-DD-YYYY")}</TableCell>
                                                <TableCell><a href={`/publishers/${row.publisher_id}`} target={"_blank"}>{row.publisher_name} ({row.publisher_id})</a></TableCell>
                                                <TableCell align="right">${row.amount.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</TableCell>
                                                <TableCell align={"center"}><IconButton
                                                    onClick={() => {setDeleteCredit(row)}}
                                                ><HighlightOffIcon sx={{color: 'red', fontSize: '15px'}}/></IconButton></TableCell>
                                            </StripedTableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableFooterRow>
                                        <TableCell align="right">Total</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell align="right">${total.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableFooterRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    }
                </>}
            <PublisherCreditDelete credit={deleteCredit} setCredit={setDeleteCredit} reload={reload}/>
            <PublisherCreditCreate open={addCredit} setOpen={setAddCredit} reload={reload}/>
        </>
    )
}
