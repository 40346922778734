import * as React from "react";
import Loading from "../../components/loading";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {gql} from "@apollo/client";
import {mutate, query} from "../../lib/apollo";
import {Alert, Card, CardContent, Grid, InputAdornment} from "@mui/material";
import theme from "../../lib/theme";
import moment from "moment/moment";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import {toast} from "react-toastify";


export default function GclidRevenuePage() {
    const [loading, setLoading] = React.useState(false);
    const [gclid, setGclid] = React.useState("");
    const [data, setData] = React.useState<any>(null);
    const [submitted, setSubmitted] = React.useState(false);
    const [revenue, setRevenue] = React.useState(0);

    React.useEffect(() => {
        document.title = "Gclid Revenue | DN";
    }, []);

    const handleSubmit = async () => {
        setSubmitted(true);
        if (gclid) {
            const q = gql`
                query ($gclid: String!) {
                    googleGclidRV(gclid: $gclid) {
                        revenue
                        first_name
                        last_name
                        email
                        lead_date
                        publisher {
                            name
                            id                        
                        }
                    }
                }
            `;
            setLoading(true);
            const variables = {
                gclid
            }
            const response = await query(q, variables);
            if (response && response.googleGclidRV) {
                setData(response.googleGclidRV);
            } else {
                setData(null);
            }
            setLoading(false);
        } else {
            setData(null);
        }
    };

    const handleAdd = async () => {
        if (revenue) {
            const q = gql`
                mutation ($gclid: String!, $revenue: Float!) {
                    googleAddGclidRV(gclid: $gclid, revenue: $revenue) {
                        revenue
                        first_name
                        last_name
                        email
                        lead_date
                        publisher {
                            name
                            id                        
                        }
                    }
                }
            `;
            setLoading(true);
            const variables = {
                gclid,
                revenue
            }
            const response = await mutate(q, variables);
            if (response && response.googleAddGclidRV) {
                setData(response.googleAddGclidRV);
                toast.success("Revenue added successfully");
                setRevenue(0);
            }
            setLoading(false);
        }
    }

    return (
        <>
            <h2>Gclid Revenue</h2>

            {loading ? <Loading/> :
                <>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Gclid"
                            placeholder={"Enter Gclid"}
                            value={gclid}
                            onChange={(e: any) => setGclid(e.target.value)}
                        />
                        <Button onClick={handleSubmit} autoFocus variant="contained" sx={{marginLeft: 2}}>
                            Submit
                        </Button>
                    </Box>
                    <br/>
                    {gclid && !data && submitted && <Alert severity="warning">No results found for: {gclid}</Alert>}
                    {data && <>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <Card  sx={{fontSize: '13px'}}>
                                <CardContent>
                                    <table border={0} cellPadding={2} cellSpacing={1} width={'100%'} style={{borderCollapse: 'revert', borderSpacing: 'revert'}}>
                                        <tbody>
                                        <tr>
                                            <td style={{
                                                textAlign: 'center',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                width: 150
                                            }}><b>Gclid</b></td>
                                            <td><b>{gclid}</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{
                                                textAlign: 'center',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                width: 150
                                            }}><b>Publisher</b></td>
                                            <td><b>{data.publisher.name} ({data.publisher.id})</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{
                                                textAlign: 'center',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                width: 150
                                            }}><b>First Name</b></td>
                                            <td><b>{data.first_name}</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{
                                                textAlign: 'center',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                width: 150
                                            }}><b>Last Name</b></td>
                                            <td><b>{data.last_name}</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{
                                                textAlign: 'center',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                width: 150
                                            }}><b>Email</b></td>
                                            <td><b>{data.email}</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{
                                                textAlign: 'center',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                width: 150
                                            }}><b>Lead Date</b></td>
                                            <td><b>{moment(data.lead_date).format('MM/DD/YYYY HH:mm:ss')}</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{
                                                textAlign: 'center',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                width: 150
                                            }}><b>Revenue</b></td>
                                            <td><b>${data.revenue.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</b></td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    {true && <>
                                        <br/>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Revenue"
                                                placeholder={"Enter revenue"}
                                                value={revenue}
                                                type="number"
                                                onChange={(e: any) => setRevenue(parseFloat(e.target.value))}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                                inputProps={{
                                                    step: ".01"
                                                }}
                                                size={'small'}
                                            />
                                            <Button sx={{marginLeft: '10px'}} aria-label="Add" onClick={handleAdd} variant={'contained'} color={'warning'}>
                                                <AddBoxOutlinedIcon />&nbsp;Add Revenue
                                            </Button>
                                        </Box>
                                    </>}
                                </CardContent>
                            </Card>
                        </Grid>
                    </>}
                </>}
        </>);
}
