import * as React from "react";
import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import {RoleType} from "../../objects/Role";
import DateRange, {RangeToTimes} from "../../components/DateRange";
import moment from "moment/moment";
import Loading from "../../components/loading";
import {Alert, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead} from "@mui/material";
import Box from "@mui/material/Box";
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import TableFooterRow from "../../components/TableFooterRow";
import {useAuth} from "../../providers/auth";
import {PermissionType} from "../../objects/UserRead";
export default function ClickWallLeaderboard() {
    const [leaderboard, setLeaderboard] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [dateRange, setDateRange] = React.useState([] as any);
    const [totals, setTotals] = React.useState({revenue: 0, profit: 0, submissions: 0, payable: 0, leads: 0, apps: 0, enrolls: 0, starts: 0, revenue_per_lead: 0, revenue_per_submission: 0, payable_percent: 0, apps_percent: 0, enrolls_percent: 0, starts_percent: 0, calls : 0, talks: 0, session_seconds: 0, calls_per_hour: 0});
    let auth = useAuth();

    React.useEffect(() => {
        document.title = "Call Center Leaderboard | DN";
    }, []);

    const updateDateRange = async (value: any) => {
        setDateRange(value);
        const q = gql`
            query q($startDate: String!, $endDate: String!) {
                clickWallLeaderboard(startDate: $startDate, endDate: $endDate) {
                    agent
                    submissions
                    payable
                    revenue
                    profit
                    leads
                    apps
                    enrolls
                    starts
                    apps_percent
                    enrolls_percent
                    starts_percent
                    revenue_per_lead
                    revenue_per_submission
                    payable_percent
                    calls
                    talks
                    session_seconds
                }
            }
        `;

        const variables = {
            startDate: moment(value[0]).format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(value[1]).format("YYYY-MM-DD HH:mm:ss"),
        }

        setLoading(true);
        const response = await query(q, variables);

        if (response && response.clickWallLeaderboard) {
            setLeaderboard(response.clickWallLeaderboard);

            let totals = {revenue: 0, profit: 0, submissions: 0, payable: 0, leads: 0, apps: 0, enrolls: 0, starts: 0, revenue_per_lead: 0, revenue_per_submission: 0, payable_percent: 0, apps_percent: 0, enrolls_percent: 0, starts_percent: 0, calls : 0, talks: 0, session_seconds: 0, calls_per_hour: 0};
            response.clickWallLeaderboard.forEach((x: any) => {
                totals.revenue += x.revenue;
                totals.profit += x.profit;
                totals.submissions += x.submissions;
                totals.payable += x.payable;
                totals.leads += x.leads;
                totals.apps += x.apps;
                totals.enrolls += x.enrolls;
                totals.starts += x.starts;
                totals.calls += x.calls;
                totals.talks += x.talks;
                totals.session_seconds += x.session_seconds;
            });

            totals.revenue_per_lead = totals.leads ? totals.revenue / totals.leads : 0;
            totals.revenue_per_submission = totals.submissions ? totals.revenue / totals.submissions : 0;
            totals.payable_percent = totals.submissions ? totals.payable / totals.submissions * 100 : 0;
            totals.apps_percent = totals.leads ? totals.apps / totals.leads * 100 : 0;
            totals.enrolls_percent = totals.leads ? totals.enrolls / totals.leads * 100 : 0;
            totals.starts_percent = totals.leads ? totals.starts / totals.leads * 100 : 0;
            totals.calls_per_hour = totals.session_seconds ? totals.calls / totals.session_seconds * 3600 : 0;
            setTotals(totals);
        }

        setLoading(false);
    }

    React.useEffect(() => {
        updateDateRange(RangeToTimes("Today")).then(() => {
        });
    }, []);

    const isAdmin = !!auth.user?.permissions.includes(PermissionType.Admin);
    return (
        <>
            <h2>Call Center Leaderboard</h2>
            {loading ? <Loading/> :
                <>
                    <Box>
                        <DateRange showTime={false} dateRange={dateRange} setDateRange={updateDateRange}
                                   style={{marginRight: '10px'}}/>
                    </Box>
                    <p></p>
                    {!leaderboard || leaderboard.length === 0 ? <Alert severity="warning">No submissions found</Alert> :
                        <>
                            <TableContainer component={Paper}
                                            sx={{maxWidth: 'calc(100vw - 10px)', maxHeight: 'calc(100vh - 300px)'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableHeaderRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell>
                                                Agent
                                            </TableCell>
                                            {isAdmin && <><TableCell  align="right">
                                                Revenue
                                            </TableCell>
                                            <TableCell  align="right">
                                                Profit
                                            </TableCell>
                                                </>}
                                            <TableCell  align="right">
                                                Submissions
                                            </TableCell>
                                            <TableCell  align="right">
                                                Calls
                                            </TableCell>
                                            <TableCell  align="right">
                                                Talks
                                            </TableCell>
                                            <TableCell  align="right">
                                                Session Duration
                                            </TableCell>
                                            <TableCell  align="right">
                                                Payable
                                            </TableCell>
                                            <TableCell  align="right">
                                                Leads
                                            </TableCell>
                                            <TableCell  align="right">
                                                Apps
                                            </TableCell>
                                            <TableCell  align="right">
                                                Enrolls
                                            </TableCell>
                                            <TableCell  align="right">
                                                Starts
                                            </TableCell>
                                        </TableHeaderRow>
                                    </TableHead>
                                    <TableBody>
                                        {leaderboard.map((row: any, index: number) => (
                                            <React.Fragment key={row.agent}>
                                                <StripedTableRow>
                                                    <TableCell>
                                                        <b>{index + 1}.</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.agent}
                                                    </TableCell>
                                                    {isAdmin && <>
                                                    <TableCell align="right">
                                                        ${row.revenue.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        ${row.profit.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                                                    </TableCell>
                                                    </>}
                                                    <TableCell align="right">
                                                        {row.submissions.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                        {isAdmin && <>&nbsp;(${row.revenue_per_submission.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})})</>}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.calls.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                        &nbsp;({(row.session_seconds ? row.calls / row.session_seconds * 3600 : 0).toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}/hr)
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.talks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {secondsToTime(row.session_seconds)}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.payable.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                        &nbsp;({row.payable_percent.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%)
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.leads.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                        {isAdmin && <>&nbsp;(${row.revenue_per_lead.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})})</>}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.apps.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                        &nbsp;({row.apps_percent.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%)
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.enrolls.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                        &nbsp;({row.enrolls_percent.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%)
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.starts.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                        &nbsp;({row.starts_percent.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%)
                                                    </TableCell>
                                                </StripedTableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableFooterRow>
                                            <TableCell colSpan={2}>Totals</TableCell>
                                            {isAdmin && <>
                                            <TableCell align="right">${totals.revenue.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                            <TableCell align="right">${totals.profit.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                            </>}
                                            <TableCell align="right">{totals.submissions.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                {isAdmin && <> &nbsp;(${totals.revenue_per_submission.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})})</>}</TableCell>
                                            <TableCell align="right">
                                                {totals.calls.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                &nbsp;({totals.calls_per_hour.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}/hr)
                                            </TableCell>
                                            <TableCell align="right">
                                                {totals.talks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                            </TableCell>
                                            <TableCell align="right">
                                                {secondsToTime(totals.session_seconds)}
                                            </TableCell>
                                            <TableCell align="right">{totals.payable.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                            &nbsp;({totals.payable_percent.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%)</TableCell>
                                            <TableCell align="right">{totals.leads.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                                {isAdmin && <>&nbsp;(${totals.revenue_per_lead.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})})</>}</TableCell>
                                            <TableCell align="right">{totals.apps.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                            &nbsp;({totals.apps_percent.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%)</TableCell>
                                            <TableCell align="right">{totals.enrolls.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                            &nbsp;({totals.enrolls_percent.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%)</TableCell>
                                            <TableCell align="right">{totals.starts.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                            &nbsp;({totals.starts_percent.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%)</TableCell>
                                        </TableFooterRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </>
                    }
            </>}
        </>
    );
}

function secondsToTime(seconds: number) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let seconds2 = seconds % 60;

    // get time with 2 digits in each

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds2.toString().padStart(2, '0')}`;


}
