import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import SingleSelect from "../../components/SingleSelect";
import {getBillingGroup, getDupeGroup} from "../schools/schoolsService";
import LoadingDialog from "../../components/loadingDialog";
import Button from "@mui/material/Button";
import {gql} from "@apollo/client";
import {mutate} from "../../lib/apollo";
import {toast} from "react-toastify";

export default function SeoRankingSchoolsAdd (params: {id: number, open: boolean, setOpen: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [schools, setSchools] = React.useState('');

    React.useEffect(() => {
        setSchools('');
        setLoading(false);
    }, [params.open]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setOpen(false);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;
        const school_names = schools.split('\n').map((s: string) => s.trim()).filter((s: string) => s.length > 0);
        if (!school_names.length) {
            toast.error('No schools to add!');
            return;
        }
        q = gql`
            mutation m($id: Int!, $school_names: [String!]!) {
                seoRankingSchoolsCreate(id: $id, school_names: $school_names)
            }
        `;

        variables = {
            id: params.id,
            school_names
        };

        setLoading(true);

        const res = await mutate(q, variables);
        good = res && res.seoRankingSchoolsCreate;
        if (good) {
            toast.success('Schools Added!');
            if (res.seoRankingSchoolsCreate.length) {
                toast.warning('Missing schools left over!')
                setSchools(res.seoRankingSchoolsCreate.join('\n'));
            } else {
                setSchools('');
                params.setOpen(false);
            }
            params.reload();
        }

        setLoading(false);
    }

    if (!params.open) return <> </>;

    return (
        <>
            <Dialog
                open={params.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                   Add Schools to Ranking
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoComplete={'off'}
                                margin="dense"
                                id="schools"
                                label="School Names (Paste Here)"
                                type="text"
                                fullWidth
                                size={'small'}
                                value={schools}
                                onChange={(e) => setSchools(e.target.value)}
                                multiline
                                rows={4}
                                inputProps={{
                                    style: {
                                        resize: 'vertical'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>Add</Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}
