import * as React from 'react';
import {RangeType} from "rsuite/DateRangePicker";
import moment from "moment/moment";
import {DateRangePicker} from "rsuite";

export type DateRanges = 'Today' | 'Yesterday' | '2 Days Ago' | 'Last 7 Days' | 'Month to Date' | 'Month to Yesterday' | 'Last 30 Days' | 'Last Month' | '7 to 45 Days' | 'Last 60 Days' | 'Last 12 Month' | 'Last 24 Month' | 'Year to Date'

const predefinedRanges: RangeType[] = [
    {
        label: 'Today',
        value: [moment().startOf("day").toDate(), moment().endOf("day").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: 'Yesterday',
        value: [moment().subtract(1, "day").startOf("day").toDate(), moment().subtract(1, "day").endOf("day").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },

    {
        label: '2 Days Ago',
        value: [moment().subtract(2, "day").startOf("day").toDate(), moment().subtract(2, "day").endOf("day").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: 'Last 7 Days',
        value: [moment().subtract(7, "days").startOf("day").toDate(), moment().endOf("day").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: 'Month to Date',
        value: [moment().startOf("month").toDate(), moment().endOf("day").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: 'Month to Yesterday',
        value: [moment().startOf("month").toDate(), moment().subtract(1,"day").endOf("day").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: 'Last 30 Days',
        value: [moment().subtract(30, "days").startOf("day").toDate(), moment().endOf("day").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: 'Last Month',
        value: [moment().subtract(1, "month").startOf("month").toDate(), moment().subtract(1, "month").endOf("month").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: '7 to 45 Days',
        value: [moment().subtract(45, "days").startOf("day").toDate(), moment().endOf("day").subtract(8, "days").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: 'Last 60 Days',
        value: [moment().subtract(60, "days").startOf("day").toDate(), moment().endOf("day").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: 'Last 12 Month',
        value: [moment().subtract(12, "month").startOf("month").toDate(), moment().subtract(1, "month").endOf("month").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: 'Last 24 Month',
        value: [moment().subtract(24, "month").startOf("month").toDate(), moment().subtract(1, "month").endOf("month").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    },
    {
        label: 'Year to Date',
        value: [moment().startOf("year").toDate(), moment().endOf("day").toDate()],
        placement: window.innerWidth < 768 ? 'bottom': 'left'
    }

];
export default function DateRange({showTime, dateRange, setDateRange, style, placement}:{showTime: boolean, dateRange: any, setDateRange: any, style: any, placement?: 'bottomStart' | 'bottomEnd'}) {
    let j= 0;
    return (
            <DateRangePicker
                format={showTime ? "MM-dd-yyyy HH:mm:ss" : "MM-dd-yyyy"}
                value={dateRange}
                showOneCalendar={window.innerWidth < 768}
                ranges={predefinedRanges}
                onChange={(value: any) => { if (value[1].getHours() === 0) {value[1].setHours(23, 59, 59)} setDateRange(value)}}
                cleanable={false}
                preventOverflow={false}
                style={style}
                placement={window.innerWidth < 768 ? 'bottomStart' : (placement || 'bottomStart')}
                onFocus={(e: any) => {j++; if (j === 1) { e.target.blur(); }}}
                onExit={(e: any) => {j = 0;}}
            />
    )
}

export const RangeToTimes = (defaultRange: DateRanges) => {
    return predefinedRanges.filter((range: RangeType) => range.label === defaultRange)[0].value
}
