import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import downloadCsv from "../../lib/downloadCsv";

export async function searchSchools(limit: number, search: string, sortColumn: string, sortAsc: boolean) {
    const q = gql`
      query q ($input: SchoolsInput!) {
        schools(input: $input) {
            count
            items {
                id
                name
                dupeGroup {
                    id
                    name
                }
                billingGroup {
                    id
                    name
                }
            }
        }
      } 
    `;

    const variables = {
        input: {
            limit,
            skip: 0,
            search,
            sort: [{column: sortColumn, isAsc: sortAsc}]
        }
    };

    const response = await query(q, variables);
    if (!response || !response.schools) {
        return {count: 0, schools: []};
    }

    return {count: response.schools.count, schools: response.schools.items};
}

export async function getDupeGroup(search: string, selectedDupeGroups: any[]) {
    const variables = {
        input: {
            limit: 100,
            skip: 0,
            sort: [{isAsc: true, column: 'name'}],
            search
        },
    };

    const q = gql`
      query q ($input: SchoolsInput!) {
        schoolDupeGroups (input: $input) {
            id
            name
        }
      } 
    `;

    const response = await query(q, variables);
    if (response && response.schoolDupeGroups) {
        let group = response.schoolDupeGroups.map((dg: any) => ({
            id: dg.id,
            value: `${dg.name} (${dg.id})`,
        }));

        //add new group option
        group.unshift({id: 0, value: "New Group"});
        return group;
    }
    return [];
}

export async function getBillingGroup(search: string, selectedBillingGroups: any[]) {
    const variables = {
        input: {
            limit: 100,
            skip: 0,
            sort: [{isAsc: true, column: 'name'}],
            search
        },
    };

    const q = gql`
      query q ($input: SchoolsInput!) {
        schoolBillingGroups (input: $input){
            id
            name
        }
      } 
    `;

    const response = await query(q, variables);
    if (response && response.schoolBillingGroups) {
        let group = response.schoolBillingGroups.map((bg: any) => ({
            id: bg.id,
            value: `${bg.name} (${bg.id})`,
        }));

        //add new group option
        group.unshift({id: 0, value: "New Group"});
        return group;
    }
    return [];
}

export function downloadSchools(search: string, sortColumn: string, sortAsc: boolean) {

    searchSchools(10000, search, sortColumn, sortAsc).then((response) => {
        const content = [];
        const header = [];

        header.push('Id');
        header.push('Name');
        header.push('Dupe Group');
        header.push('Billing Group');

        content.push(header);

        let row = [];
        response.schools.forEach((r: any) => {
            row = [];
            row.push(r.id);
            row.push(r.name);
            row.push(r.dupeGroup.name + " (" + r.dupeGroup.id + ")");
            row.push(r.billingGroup.name + " (" + r.billingGroup.id + ")");
            content.push(row);
        });

        downloadCsv('schools.csv', content).then(() => {});
    });
}
