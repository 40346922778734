import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Loading from "../components/loading";
import {gql} from "@apollo/client";
import {query} from "../lib/apollo";
import {Card, CardContent, Grid} from "@mui/material";
import theme from "../lib/theme";
export default function IpLookupPage() {
    const [ips, setIps] = React.useState("");
    const [ipLocations, setIpLocations] = React.useState({} as any);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        document.title = "IP Lookup | DN";
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        const array = ips.trim().split("\n").filter((ip: string) => ip.trim() !== "");

        if (array.length === 0) {
            setIpLocations({});
            setLoading(false);
            return;
        }

        const ipData: any = {};
        for (const ip of array) {
            const q = gql`
                query LocationIp($ip: String!) {
                    locationIp(ip: $ip) {
                        country_code
                        country_name
                        region_name
                        city_name
                        latitude
                        longitude
                        isp
                        domain
                        mobile_brand
                        usage_type
                    }
                }
            `;

            const variables = {
                ip
            }
            const response = await query(q, variables);

            if (response.locationIp) {
                ipData[ip] = response.locationIp;
            } else {
                ipData[ip] = {
                    country_code: "N/A",
                    country_name: "N/A",
                    region_name: "N/A",
                    city_name: "N/A",
                    latitude: "N/A",
                    longitude: "N/A",
                    isp: "N/A",
                    domain: "N/A",
                    mobile_brand: "N/A",
                    usage_type: "N/A"

                };
            }
        }
        setIpLocations(ipData);
        setLoading(false);
    }

    let data = [];
    for (const ip in ipLocations) {
        data.push(
            <Grid item key={ip} xs={12} sm={6} md={6} lg={4} xl={3}>
                <Card  sx={{fontSize: '13px'}}>
                <CardContent>
                    <table border={0} cellPadding={2} cellSpacing={1} width={'100%'} style={{borderCollapse: 'revert', borderSpacing: 'revert'}}>
                        <tbody>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, width: 150}}><b>IP</b></td>
                            <td><b>{ip}</b></td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}><b>Country Code</b></td>
                            <td>{ipLocations[ip].country_code}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}><b>Country Name</b></td>
                            <td>{ipLocations[ip].country_name}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}><b>Region Name</b></td>
                            <td>{ipLocations[ip].region_name}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}><b>City Name</b></td>
                            <td>{ipLocations[ip].city_name}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}><b>Latitude</b></td>
                            <td>{ipLocations[ip].latitude}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}><b>Longitude</b></td>
                            <td>{ipLocations[ip].longitude}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}><b>ISP</b></td>
                            <td>{ipLocations[ip].isp}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}><b>Domain</b></td>
                            <td>{ipLocations[ip].domain}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}><b>Mobile Brand</b></td>
                            <td>{ipLocations[ip].mobile_brand}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'center', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}}><b>Usage Type</b></td>
                            <td>{ipLocations[ip].usage_type}</td>
                        </tr>
                        </tbody>
                    </table>
                </CardContent>
            </Card>
            </Grid>
        );
    }

    return (
        <>
            <h2>IP Lookup</h2>

            {loading ? <Loading/> :
            <>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <TextField
                        id="outlined-multiline-static"
                        label="IPs"
                        multiline
                        rows={3}
                        placeholder={"Enter or paste IP addresses one per line (no commas)"}
                        value={ips}
                        onChange={(e: any) => setIps(e.target.value)}
                    />
                    <Button onClick={handleSubmit} autoFocus variant="contained" sx={{marginLeft: 2}}>
                        Submit
                    </Button>
                </Box>

                {(data && data.length) ?
                    <>
                        <hr/>
                        <Grid container spacing={2} sx={{marginBottom: 2}}>
                            {data}
                        </Grid>
                    </> : <></>
                }
            </>}
        </>
    )
}
