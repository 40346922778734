export function getCookie(key: string) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}

export function setCookie(key: string ,value: string, days: number) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }

    let domain = "";

    if (window.location.hostname.includes("gitpod")) {
        domain = ".gitpod.io";
    } else if (window.location.hostname.includes("localhost")) {
        domain = "localhost";
    } else {
        domain = ".degreenexus.com"
    }
    document.cookie = key + "=" + (value || "")  + expires + `; path=/; domain=${domain}`;
}

export function deleteCookie(key: string) {
    document.cookie = key +'=; Max-Age=-99999999;';
}
