import * as React from "react";
import {useNavigate, useParams} from "react-router";
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormGroup,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Link,
    Menu,
    MenuItem,
    Pagination,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead
} from "@mui/material";
import useDebounce from "../../lib/UseDebounce";
import SearchIcon from "@mui/icons-material/Search";
import Loading from "../../components/loading";
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import {gql} from "@apollo/client";
import {query} from "../../lib/apollo";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SeoRankingUpsert from "./seoRankingUpsert";
import SeoRankingSeal from "./seoRankingSeal";
import {MilitaryTech} from "@mui/icons-material";
import downloadCsv from "../../lib/downloadCsv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {useAuth} from "../../providers/auth";
import {PermissionType} from "../../objects/UserRead";

export default function SeoRankingsPage() {
    const [rankings, setRankings] = React.useState([] as any[]);
    const [loading, setLoading] = React.useState(true);
    const [limit, setLimit] = React.useState(100);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [maxPage, setMaxPage] = React.useState(1);
    const [anchorEls, setAnchorEls] = React.useState<any>({});
    const [rankingUpsert, setRankingUpsert] = React.useState<any>(null as any);
    const [rankingSeal, setRankingSeal] = React.useState<any>(null as any);
    let auth = useAuth();
    let navigate = useNavigate();
    let { year } = useParams();


    React.useEffect(() => {
        document.title = `Seo Rankings ${year} | DN`;
    }, [year]);

    const getData = async (nLimit: number, sSearch: string, nPage: number) => {
        if (!auth.user?.permissions.includes(PermissionType.SEO)) {
            navigate('/seoYear/'+year);
            return;
        }
        const q = gql`
            query q ($input: SeoRankingsInput!) {
                seoRankings(input: $input) {
                    count
                    items {
                        id
                        slug
                        name
                        url
                        headline
                        number_schools
                        rankingFields {
                            name
                        }
                        seal {
                            header
                            line1
                            line2
                            line3
                        }
                        seal_url
                        ad_category
                        ad_degree
                        ad_subject
                    }
                }
            }`;

        const variables = {
            input: {
                year: parseInt(year || "0", 10),
                limit: nLimit,
                skip: nPage * limit,
                search: sSearch
            }
        };

        return await query(q, variables);
    }
    const download = async () => {
        const response = await getData(10000, search, 0);
        if (!response || !response.seoRankings) {
            return;
        }

        const content = [];
        const header = [];

        header.push('Id');
        header.push('Slug');
        header.push('Name');
        header.push('Url');

        content.push(header);

        let row = [];
        response.seoRankings.items.forEach((r: any) => {
            row = [];
            row.push(r.id);
            row.push(r.slug);
            row.push(r.name);
            row.push(r.url);
            content.push(row);
        });

        downloadCsv('rankings.csv', content).then(() => {});

    }
    const reload = async (nLimit: number, sSearch: string, nPage: number) => {
        setLoading(true);

        const response = await getData(nLimit, sSearch, nPage);

        if (!response || !response.seoRankings) {
            setRankings([]);
            setCount(0);
            setMaxPage(0)
        } else {
            setRankings(response.seoRankings.items);
            setCount(response.seoRankings.count);
            setMaxPage(Math.ceil(response.seoRankings.count / nLimit));
        }

        setLoading(false);
    }

    const handleLimit = async (event: SelectChangeEvent) => {
        const l = parseInt(event.target.value as string, 10);
        setLimit(l);
        setPage(0)
        await reload(l, search, 0);
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
        await reload(limit, search, value - 1);
    };

    useDebounce(() => {
            setPage(0);
            reload(limit, search, 0).then(x=>{});
        }, [search], 400
    );

    return (
        <>
            <h2>SEO Rankings {year}
                <Button component={Link} variant="outlined" size="small" sx={{margin: '0 5px'}} href={`/seoSchools`}>&larr; Schools</Button>
                <Button component={Link} variant="outlined" size="small" sx={{margin: '0 5px'}} href={`/seoYear/${year}`} color={'success'}>Data</Button>
            </h2>
            <FormGroup row={true}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="items-label">Items</InputLabel>
                    <Select
                        labelId="items-label"
                        value={limit.toString()}
                        label="Items"
                        onChange={handleLimit}
                        size={'small'}
                    >
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                        <MenuItem value={1000}>1000</MenuItem>
                        <MenuItem value={2000}>2000</MenuItem>
                        <MenuItem value={10000}>10000</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} size="small">
                    <Input
                        id="search"
                        type="text"
                        spellCheck="false"
                        placeholder="Search"
                        value={search || ''}
                        onChange={(e: any) => setSearch(e.target.value)}
                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="Add" onClick={() => { setRankingUpsert({}) }} variant={'contained'} color={'warning'}>
                        <AddBoxOutlinedIcon />&nbsp;Create Ranking
                    </Button>
                </FormControl>

                {!!rankings && !!rankings.length && !loading && <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Button aria-label="download" onClick={() => { download() }} variant={'contained'} color={'success'}>
                        <FileDownloadIcon />&nbsp;Export
                    </Button>
                </FormControl>}
            </FormGroup>
            {loading ? <Loading/> :
                <>
                    {!rankings || rankings.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                            <Box sx={{margin: '10px 0', fontWeight: 'bold', fontSize: '13px', display:"flex",}}>
                                Showing {1 + page * limit} to {Math.min(rankings.length + page * limit, count).toLocaleString(undefined, {maximumFractionDigits: 0})} of {count.toLocaleString(undefined, {maximumFractionDigits: 0})}.
                            </Box>
                            <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 10px)', maxHeight: 'calc(100vh - 320px)'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableHeaderRow>
                                            <TableCell>
                                                #
                                            </TableCell>
                                            <TableCell>
                                                Id
                                            </TableCell>
                                            <TableCell>
                                                Slug
                                            </TableCell>
                                            <TableCell>
                                                Seal
                                            </TableCell>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Url
                                            </TableCell>
                                            <TableCell align={"right"}>
                                                Number
                                            </TableCell>
                                            <TableCell>
                                                Custom
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableHeaderRow>
                                    </TableHead>
                                    <TableBody>
                                        {rankings.map((row: any, index: number) => (
                                            <React.Fragment key={row.id}>
                                                <StripedTableRow>
                                                    <TableCell>
                                                        <b>{index + 1 + page*limit}.</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.slug}
                                                    </TableCell>
                                                    <TableCell>
                                                        {!!row.seal_url ? <img src={row.seal_url} style={{maxWidth: '100px', maxHeight: '100px'}}/> : <></>}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link href={`/seoRanking/${row.id}`}>{row.name}</Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link href={row.url} target={'_blank'}>{row.url}</Link>
                                                    </TableCell>
                                                    <TableCell align={"right"}>
                                                        {row.number_schools}
                                                    </TableCell>
                                                    <TableCell>
                                                        {!!row.rankingFields ? <CheckCircleOutlineIcon sx={{color: 'green', fontSize: '20px', marginLeft: '5px', verticalAlign: 'text-top'}}/> : <></>}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            aria-haspopup="true"
                                                            onClick={(event) => {
                                                                setAnchorEls({...anchorEls, [row.id]: event.currentTarget});
                                                            }}
                                                        >
                                                            <MoreVertIcon sx={{fontSize: '15px'}} />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={anchorEls[row.id]}
                                                            open={!!anchorEls[row.id]}
                                                            onClose={() => {
                                                                setAnchorEls({...anchorEls, [row.id]: null});
                                                            }}
                                                        >
                                                            <MenuItem onClick={() => {
                                                                setRankingUpsert(row);
                                                                setAnchorEls({...anchorEls, [row.id]: null});
                                                            }} disableRipple><EditIcon sx={{marginRight: 1}} /> Edit Ranking</MenuItem>
                                                            <MenuItem onClick={() => {
                                                                setRankingSeal(row);
                                                                setAnchorEls({...anchorEls, [row.id]: null});
                                                            }} disableRipple><MilitaryTech sx={{marginRight: 1}} /> Edit Seal</MenuItem>
                                                        </Menu>
                                                    </TableCell>
                                                </StripedTableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination count={maxPage} variant="outlined" shape="rounded" style={{paddingTop: 15}} defaultPage={page + 1} onChange={handlePageChange} />
                        </>
                    }
                </>
            }
            <SeoRankingUpsert year={parseInt(year || "0", 10)} ranking={rankingUpsert} setRanking={setRankingUpsert} reload={() => {reload(limit, search, page).then(() => {})}}/>
            <SeoRankingSeal year={parseInt(year || "0", 10)} ranking={rankingSeal} setRanking={setRankingSeal} reload={() => {reload(limit, search, page).then(() => {})}}/>

        </>
    )
}
